import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class VendorCodesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(page, pageSize, filters) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getListExtended, {
            page: page,
            page_size: pageSize,
            filters_values: JSON.stringify(filters)
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getParentList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getFirstLevelList),
         ])
            .then(Axios.spread((marketplacesList, parentCodesList, categoriesList) => {
               const response = {
                  marketplacesList: marketplacesList,
                  parentCodesList: parentCodesList,
                  categoriesList: categoriesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static toggleActive(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.toggleActive, {
            id: itemId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default VendorCodesApi;