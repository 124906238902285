import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Collapse, Select, Button, Space, Divider, notification, Descriptions, Row, Col, Typography } from 'antd'
import { InfoCircleOutlined, SearchOutlined, UploadOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/unique_files_seasonal'

import DropdownMenu from '../../common/components/DropdownMenu';
import TableFilters from '../../common/components/TableFilters';

import Loader from '../../common/PageElements/Loader'

class UniqueFileSeasonalDetails extends Component {

   state = {
      pageLoader: true,
      detailsLoader: false,
      tableLoader: false,
      itemData: {},
      itemList: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      // itemsPaginationParams: {
      //    current_page: 1,
      //    per_page: 100,
      //    total: 0,
      // },

      expandedRowKeys: [],
      filters: [],
      filtersValues: {},
      appliedFilters: [],
   };

   componentDidMount() {
      const uniquefileId = this.props.match.params.unique_file_id
      const brandId = this.props.match.params.brand_id
      Promise.all([
         // this.getItem(itemId),
         this.getLines(uniquefileId, brandId, this.state.itemsPaginationParams, this.state.filtersValues),
      ]).then(() => {
         this.setState({
            pageLoader: false,
            uniquefileId: uniquefileId,
            brandId: brandId,
         });
      });
   }

   getLines = (uniquefileId, brandId, paginationParams, filters) => {
      this.setState({ tableLoader: true })
      return storeActions.getSeasonalLines(uniquefileId, brandId, paginationParams, filters)
         .then(response => {
            console.log(response)
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD')
                     }
                  }
                  updatedFilters.push(filter)
               }

               this.setState({
                  itemData: response.unique_file_data,
                  itemList: response.data,
                  fullListIds: response.full_list_ids,
                  // itemPaginationParams: {
                  //    current_page: response.data.current_page,
                  //    per_page: response.data.per_page,
                  //    total: response.data.total
                  // },
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false,
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               this.setState({ tableLoader: false })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ tableLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getLines(this.state.uniquefileId, this.state.brandId, this.state.itemsPaginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {} })
      this.getLines(this.state.uniquefileId, this.state.brandId, this.state.itemsPaginationParams, {});
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      // console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getLines(this.state.uniquefileId, this.state.brandId, paginationParams, this.state.filtersValues);
   }

   onExpandChange = (expanded, record) => {
      console.log('EXPAND', expanded, record)
      var expandedRows = _.clone(this.state.expandedRowKeys)
      if (expanded) {
         expandedRows.push(record.key)
      } else {
         _.pull(expandedRows, record.key)
      }
      // console.log(expandedRows)
      this.setState({
         // expandedAll: _.isEqual(_.sortBy(expandedRows), _.sortBy(this.state.fullParentsIds)),
         expandedRowKeys: expandedRows,
      });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography;
      const { Option } = Select;
      const { Panel } = Collapse;
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: false,
         // pagination: {
         //    // pageSize: this.state.itemsPaginationParams.per_page,
         //    // total: this.state.itemsPaginationParams.total,
         //    // showSizeChanger: true,
         //    // defaultPageSize: 50,
         //    // size: "default",
         //    disabled: true,
         //    showTotal: total => total + (total > 1 ? ' linee' : ' linea')
         // },
         onChange: this.handleTableChange
      }
      const tableConfirmationsLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         // rowClassName: (record) => { return 'background-' + record.country.code.toLowerCase() }
      }
      const tableColumns = [
         {
            title: 'Ordine',
            key: 'order_ids',
            width: 80,
            fixed: 'left',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  {
                     record.purchase_order_ids.map((orderId, index) => {
                        return <Text key={index}>{orderId}</Text>
                     })
                  }
               </Space>
            )
         },
         {
            title: 'Numero PO',
            key: 'purchase_order_numbers',
            width: 100,
            fixed: 'left',
            render: (record) => (
               <Space direction='vertical' size={0}>
                  {
                     record.purchase_order_numbers.map((orderNumber, index) => {
                        return <Text key={index}>{orderNumber}</Text>
                     })
                  }
               </Space>
            )
         },
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 110,
            fixed: 'left',
            render: (record) => (
               record.ean_upc
            ),
            sorter: true
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 110,
            fixed: 'left',
            render: (record) => (
               record.asin
            ),
            sorter: true
         },
         {
            title: 'MPN',
            key: 'mpn',
            width: 150,
            ellipsis: true,
            render: (record) => (
               record.mpn
            ),
            sorter: true
         },
         {
            title: 'Prodotto',
            key: 'title',
            ellipsis: true,
            render: (record) => (
               record.title
            ),
            sorter: true
         },
         {
            title: 'Misura',
            key: 'size',
            width: 60,
            render: (record) => (
               record.size
            ),
         },
         {
            title: 'WHLS',
            key: 'wsp',
            width: 80,
            render: (record) => (
               record.wsp_human
            ),
         },
         {
            title: 'Retail',
            key: 'retail',
            width: 80,
            render: (record) => (
               record.retail_human
            ),
         },
         {
            title: 'Costo',
            key: 'cost',
            width: 80,
            render: (record) => (
               record.cost_human
            ),
         },
         {
            title: 'SEASONAL EU',
            key: 'net_amz_seasonal_eu',
            width: 80,
            render: (record) => (
               record.net_amz_seasonal_eu_human
            ),
         },
         {
            title: 'SEASONAL UK',
            key: 'net_amz_seasonal_uk',
            width: 80,
            render: (record) => (
               record.net_amz_seasonal_uk_human
            ),
         },
         {
            title: 'Prezzo PO',
            key: 'amz_price_unit',
            width: 80,
            render: (record) => (
               record.amz_price_unit_human
            ),
         },
         {
            title: 'Ordinati AMZ',
            key: 'ordered_quantity',
            width: 100,
            render: (record) => (
               record.ordered_quantity
            ),
         },
         {
            title: 'Ordinati attuali AMZ',
            key: 'actual_ordered_quantity',
            width: 100,
            render: (record) => (
               record.actual_ordered_quantity
            ),
         },
         {
            title: 'Confermati',
            key: 'confirmed_quantity',
            width: 80,
            render: (record) => (
               record.confirmed_quantity
            ),
            sorter: true
         },
         {
            title: 'Rimanenti',
            key: 'remaining_quantity',
            width: 80,
            render: (record) => (
               record.remaining_quantity
            ),
            sorter: true
         },
      ]
      const tableConfirmationsColumn = [
         {
            title: 'Progressivo',
            key: 'progressive',
            width: 100,
            align: 'left',
            render: (record) => (
               record.progressive
            ),
            sorter: (a, b) => a.progressive.localCompare(b.progressive),
         },
         {
            title: 'Data',
            key: 'progressive_date',
            width: 100,
            render: (record) => (
               record.progressive_date_human
            ),
            // sorter: (a, b) => a.count_products - b.count_products,
         },
         {
            title: 'Fornitore/Magazzino',
            key: 'source_name',
            render: (record) => (
               record.source_name
            ),
            sorter: (a, b) => a.source_name.localCompare(b.source_name),
         },
         {
            title: 'Confermati',
            key: 'quantity_confirmed',
            width: 80,
            render: (record) => (
               record.quantity_confirmed
            ),
            sorter: (a, b) => a.quantity_confirmed - b.quantity_confirmed,
         },
         {
            title: 'WHLS',
            key: 'price_unit',
            width: 80,
            render: (record) => (
               record.price_unit_human
            ),
         },
         {
            title: 'Sconto %',
            key: 'discount_percentage',
            width: 80,
            render: (record) => (
               record.discount_percentage_human
            ),
         },
         {
            title: 'Costo',
            key: 'price_unit_final',
            width: 80,
            render: (record) => (
               record.price_unit_final_human
            ),
         },
      ]

      const dataTable = (
         <Table {...tableLayout}
            columns={tableColumns}
            dataSource={this.state.itemList}
            expandable={{
               indentSize: 20,
               expandedRowKeys: this.state.expandedRowKeys,
               onExpand: this.onExpandChange,
               expandedRowRender: record => (
                  <Space direction='vertical' size='small'>
                     <Table {...tableConfirmationsLayout}
                        columns={tableConfirmationsColumn}
                        dataSource={record.confirmations}
                     />
                     <Divider />
                  </Space>
               )
            }}
         />
      )

      const confirmationData = (
         <Row>
            <Col span={24}>
               <Descriptions size='small' column={1} bordered labelStyle={{ 'width': '250px' }} contentStyle={{ 'fontWeight': 'bold' }}>
                  <Descriptions.Item label="Settimana">{this.state.itemData.week} / {this.state.itemData.year}</Descriptions.Item>
                  <Descriptions.Item label="Marketplace">{this.state.itemData.marketplace.name}</Descriptions.Item>
                  <Descriptions.Item label="Brand">{this.state.itemData.brand.name}</Descriptions.Item>
               </Descriptions>
            </Col>
         </Row>
      );

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const mainContent = (
         <Row gutter={[4, 4]}>
            <Col></Col>
            <Col flex='auto'></Col>
            <Col align='right'>
               <Space>
                  {/* <OrdersStatusLabel
                           value={this.state.orderDetails.po_status_id}
                           title={this.state.orderDetails.order_status_name}
                        /> */}
                  {/* <DropdownMenu
                           type='primary'
                           title={languageManager.getMessage(this.props.commonState, 'common.components.order_status_button.title')}
                           action={this.orderStatusHandler}
                           values={this.state.orderStatusList}
                           loading={this.state.orderStatusLoader}
                        /> */}
               </Space>
            </Col>
            <Col span={24}>
               {dataTable}
            </Col>
         </Row>
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.unique_files.details.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col align='right'></Col>
               </Row>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  {confirmationData}
                  {pageFilters}
                  {mainContent}
               </Space>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/unique_files/seasonal/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(UniqueFileSeasonalDetails);