import React from 'react'
import { connect } from 'react-redux'

import languageManager from '../../../../services/languageManager'
import { VendorCodeStatusPriceEnum } from '../../../../shared/enums'

import { Tooltip } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CloseCircleFilled } from '@ant-design/icons'

const VendorCodePriceStatus = (props) => {
    switch (props.status) {
        case VendorCodeStatusPriceEnum.VENDOR_PRICE_OK:
            return (
                <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.price.' + VendorCodeStatusPriceEnum.VENDOR_PRICE_OK)}>
                    <CheckCircleOutlined style={{ color: '#389e0d' }} />
                </Tooltip>
            )
            break
        case VendorCodeStatusPriceEnum.DIFFERENT_VENDOR_PRICE:
            return (
                <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.price.' + VendorCodeStatusPriceEnum.DIFFERENT_VENDOR_PRICE)}>
                    <CloseCircleFilled style={{ color: '#d9363e' }} />
                </Tooltip>
            )
            break
        case VendorCodeStatusPriceEnum.MISSING_VENDOR_PRICE:
        default:
            return (
                <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.price.' + VendorCodeStatusPriceEnum.MISSING_VENDOR_PRICE)}>
                    <CloseCircleOutlined style={{ color: '#d48806' }} />
                </Tooltip>
            )
            break
    }
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(VendorCodePriceStatus)