import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

// import API_BASE from '../../../../api/apiBase'
// import API_CONFIG from '../../../../api/apiConfig'

import * as lineActions from '../store/actions/manual_update_lines'
import { importExcel } from '../../../../store/actions/commonAction'
import ImportFeedback from '../../../common/components/ImportFeedback'

import { Drawer, Button, Space, Form, Upload, message, Select, Input, notification, List } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'
import Loader from '../../../common/PageElements/Loader'

class DrawerImportUpdates extends Component {
   state = {
      ...this.props,
      componentLoader: false,
      formLoader: false,
      formValues: {},
      model: {},
      worksheets: [],
      headers: [],
      currentHeaders: [],
      componentLanguage: this.props.authState.defaultLanguage,
      showImportResult: false,
      importResult: null,
      importStatus: null,
   }
   formRef = React.createRef();

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage,
      }
      Promise.all([
         this.getModel()
      ]).then(() => {
         this.setState({
            formValues: formValues,
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible) && _.isEqual(nextState.componentLoader, this.state.componentLoader)) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.visible, this.props.visible)) {
         this.setState({
            worksheets: [],
            headers: [],
            currentHeaders: []
         })
         if (this.formRef.current) {
            this.formRef.current.resetFields()
         }
      }
   }

   getModel = () => {
      return lineActions.getModel()
         .then(response => {
            try {
               console.log('MANUAL UPDATE LINE MODEL', response.data)
               this.setState({ model: response.data })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               console.log(e)
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         })
   }

   setWorksheets = (worksheets, headers) => {
      this.formRef.current.setFieldsValue({ worksheet: 0 })
      this.setState({ worksheets: worksheets, headers: headers, currentHeaders: headers[0] })
   }
   unsetWorksheets = () => {
      this.formRef.current.setFieldsValue({ worksheet: null })
      this.setState({ worksheets: [], headers: [], currentHeaders: [] })
   }
   onChangeWorksheet = value => {
      console.log('CHANGE WORKSHEET', value)
      let currentHeaders = []
      if (!_.isUndefined(value)) {
         currentHeaders = this.state.headers[value]
         console.log('CURRENT HEADERS', currentHeaders)
      }
      this.setState({ currentHeaders: currentHeaders })
   }

   onClose = () => {
      this.state.onClose()
   }
   onFinish = values => {
      this.setState({ formLoader: true })
      let data = {};
      data.language = values.language;
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      data.file_name_original = values.fileImport[0].response.data.file_name_original;
      data.worksheet_index = values.worksheet
      delete values.fileImport
      delete values.worksheet
      data.model_map = values
      // data.file_type = values.file_type;
      if (!data.file_path || !data.file_name) {
         this.setState({ formLoader: false, draggerDisable: false });
         notification.error({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            duration: 0
         })
      }
      else {
         lineActions.importFromFile(data)
            .then(response => {
               try {
                  console.log(response)
                  // this.setState({showImportResult: true, importStatus: response.status, importResult: response.human})
                  this.state.onSubmit(response.human, response.status)
                  notification.success({
                     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                     description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  })
               } catch (e) {
                  notification.error({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  })
                  console.log(e)
               } finally {
                  this.setState({ formLoader: false })
               }
            }).catch((e) => {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={e.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  duration: 0
               })
               this.setState({ formLoader: false })
            });
      }
   };

   render() {
      if (this.state.componentLoader) return <Loader />
      const { Option } = Select
      const scope = this
      const model = this.state.model

      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      const normFile = e => {
         console.log('Upload event:', e);
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      }
      const draggerProps = {
         name: 'file',
         multiple: false,
         customRequest: importExcel,
         onChange(info) {
            if (_.isEmpty(info.fileList)) {
               scope.unsetWorksheets()
            } else if (info.fileList.length > 1) {
               info.fileList.shift()
            }
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status === 'done') {
               console.log('CHANGE DONE', info)
               const responseData = info.file.response.data
               if (info.file.response.success === true) {
                  notification.success({
                     message: languageManager.getMessage(scope.props.commonState, 'notification.confirm.title'),
                     description: info.file.name + ': ' + languageManager.getMessage(scope.props.commonState, 'notification.confirm.uploadOk'),
                  })
                  scope.setWorksheets(responseData.worksheets, responseData.headers)
               }
            } else if (status === 'error') {
               notification.error({
                  message: languageManager.getMessage(scope.props.commonState, 'notification.confirm.title'),
                  description: info.file.name + ': ' + languageManager.getMessage(scope.props.commonState, 'notification.error.uploadKo'),
               })
            }
         },
      }

      const form = (
         <Form
            {...layout}
            ref={this.formRef}
            id="FormImportConfirmations"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName="fileList"
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Upload.Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                     Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                     band files
                  </p>
               </Upload.Dragger>
            </Form.Item>
            <Form.Item
               label={languageManager.getMessage(this.props.commonState, 'component.label.worksheet')}
               name="worksheet"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  allowClear={true}
                  style={{ width: 200 }}
                  placeholder="Seleziona il foglio di lavoro"
                  optionFilterProp="children"
                  onChange={this.onChangeWorksheet}
                  disabled={_.isEmpty(this.state.worksheets)}
               >
                  {
                     !_.isEmpty(this.state.worksheets) ? (
                        this.state.worksheets.map((worksheetName, index) => {
                           return <Option key={index} value={index}>{worksheetName}</Option>
                        })
                     ) : null
                  }
               </Select>
            </Form.Item>
            {
               Object.keys(model).map((key, index) => {
                  // console.log('MODEL', model[key], key)
                  return !_.isNil(model[key].importable) && model[key].importable ? (
                     <Form.Item
                        key={key}
                        name={key}
                        label={languageManager.getMessage(this.props.commonState, 'component.label.' + (!_.isNil(model[key].label) ? model[key].label : key))}
                        rules={[{ required: !_.isNil(model[key].required) ? model[key].required : false }]}
                     >
                        <Select
                           showSearch
                           allowClear={true}
                           style={{ width: 200 }}
                           placeholder="Seleziona la colonna"
                           optionFilterProp="children"
                           disabled={_.isEmpty(this.state.currentHeaders)}
                        >
                           {
                              this.state.currentHeaders.map((header, index) => {
                                 if (!_.isNil(header)) {
                                    return <Option key={index} value={header}>{header}</Option>
                                 }
                              })
                           }
                        </Select>
                     </Form.Item>
                  ) : null
               })
            }
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      )

      const importFeedback = (
         <ImportFeedback
            key='importFeedback'
            feedback={this.state.importResult}
            status={this.state.importStatus}
            visible={this.state.showImportResult} />
      )

      return (
         <Drawer
            title={languageManager.getMessage(this.props.commonState, 'common.import') + ' ' + languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.name_plural')}
            width={700}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="FormImportConfirmations" type="primary" htmlType="submit" loading={this.state.formLoader}>Importa</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <Space direction='vertical' size='small'>
                     {importFeedback}
                     {form}
                  </Space>
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(DrawerImportUpdates)