import PurchaseOrdersApi from '../../../../../api/PurchaseOrdersApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(year = null) {
   return PurchaseOrdersApi.getList(year)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(params = [], filterValues = {}) {
   return PurchaseOrdersApi.getListExtended(params, filterValues)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListForProgressives(progressiveType, fullList = false, selectedIds = null) {
   return PurchaseOrdersApi.getListForProgressives(progressiveType, fullList, selectedIds)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id
            })
            console.log(response)
            return response.data
         }
         else
            throw new Error('Server error')
      })
      .catch(error => {
         throw error
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getPurchaseOrdersComplements() {
   return PurchaseOrdersApi.getPurchaseOrdersComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getOrderComplements() {
   return PurchaseOrdersApi.getOrderComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId, params = [], filterValues = {}) {
   return PurchaseOrdersApi.getItem(itemId, params, filterValues)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return PurchaseOrdersApi.createOrUpdate(itemData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleValue(itemId, value, language = 'it') {
   return PurchaseOrdersApi.toggleValue({ id: itemId, value: value, language: language })
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return PurchaseOrdersApi.deleteItem(itemId, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importPOList(formData) {
   console.log(formData);
   return PurchaseOrdersApi.importPOList(formData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importPOLines(formData) {
   console.log(formData);
   return PurchaseOrdersApi.importPOLines(formData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importEdiFromAmazon() {
   console.log("IMPORT EDI START");
   return PurchaseOrdersApi.importEdiFromAmazon()
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}
export function importNewFromApi() {
   console.log("IMPORT API START");
   return PurchaseOrdersApi.importNewFromApi()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportOrders(filename, purchaseOrderIds) {
   return PurchaseOrdersApi.exportOrders(filename, purchaseOrderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportForRIA(filename, orderIds) {
   return PurchaseOrdersApi.exportForRIA(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}
export function exportForPOPivot(filename, orderIds) {
   return PurchaseOrdersApi.exportForPOPivot(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}
export function exportForRIAWeeklyPivot(filename, orderIds) {
   return PurchaseOrdersApi.exportForRIAWeeklyPivot(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}

export function createProgressives(progressiveType, progressiveCountry, progressiveGroup, vendorcodeId, fcCodeId, selectedIds, filename) {
   return PurchaseOrdersApi.createProgressives(progressiveType, progressiveCountry, progressiveGroup, vendorcodeId, fcCodeId, selectedIds, filename)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      })
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function checkWaitingStatus(orderIds) {
   return PurchaseOrdersApi.checkWaitingStatus(orderIds)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function updateOrderStatus(orderIds, newStatus) {
   return PurchaseOrdersApi.updateOrderStatus(orderIds, newStatus)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function enqueueDownloadOrders(orderIds) {
   return PurchaseOrdersApi.enqueueDownloadOrders(orderIds)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         throw error;
      });
}