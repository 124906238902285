import StockSuppliersApi from '../../../../../api/StockSuppliersApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return StockSuppliersApi.getList()
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getListExtended(params = [], filterValues = {}) {
   return StockSuppliersApi.getListExtended(params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getComplements() {
   return StockSuppliersApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function getModel() {
   return StockSuppliersApi.getModel()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function getItem(itemId, params = [], filterValues = {}) {
   return StockSuppliersApi.getItem(itemId, params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return StockSuppliersApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelItems(itemIds, return_list = false) {
   return StockSuppliersApi.cancelItems(itemIds, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function deleteItems(itemIds, return_list = false) {
   return StockSuppliersApi.deleteItems(itemIds, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function emptyWarehouses() {
   return StockSuppliersApi.emptyWarehouses()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function importFromFile(formData) {
   return StockSuppliersApi.importFromFile(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function fileImport(data) {
   const { onSuccess, onError, file, onProgress } = data;
   return StockSuppliersApi.fileImport(data)
      .then(response => {
         if (response.data.success === true) {
            // return {
            //    file: data.file,
            //    result: response.data
            // }
            onSuccess(response.data)
         }
         else {
            // return {
            //    err: response.data.error ? response.data.error : 'Server error',
            //    response: response.data,
            //    file: data.file
            // }
            const error = new Error(response.data.error ? response.data.error : 'Server error')
            onError({ event: error })
         }
      })
      .catch(error => {
         console.log(error)
         onError({ event: error })
      });
}

export function exportExcel(filename, itemIds) {
   return StockSuppliersApi.exportExcel(filename, itemIds)
      .then(response => {
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}