import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Form, Input, Select, Button, Space, notification, List, Switch, Row, Col, Typography } from 'antd'
import { InfoCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/vendor_codes'

import { PurchaseOrderTypeEnum } from '../../../shared/enums.ts'

import Loader from '../../common/PageElements/Loader'
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplacesEdit extends Component {

   state = {
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      language: 'it',
      componentLanguage: this.props.authState.defaultLanguage,
      marketplacesList: [],
      parentCodesList: [],
      categoriesList: [],
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         parent_id: response.parent_id,
         marketplace_id: response.marketplace_id,
         code: response.code,
         type: response.type,
         description: response.description,
         category_ids: response.category_ids,
         active: response.active,
         id: response.id,
      }
      return values;
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            try {
               console.log(response)
               this.setState({
                  marketplacesList: response.marketplacesList,
                  parentCodesList: response.parentCodesList,
                  categoriesList: response.categoriesList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/vendor_codes/list');
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  duration: 0
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   render() {
      // console.log(this.props.authState.userData.languages);
      // console.log(PurchaseOrderTypeEnum, Object.values(PurchaseOrderTypeEnum))
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const { Title } = Typography;
      const { TextArea } = Input;
      const { Option } = Select;

      let form = (
         <Form
            {...layout}
            id="formVendorCode"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Marketplace"
               name="marketplace_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il Marketplace"
                  optionFilterProp="children"
               >
                  {
                     this.state.marketplacesList.map((marketplace) => {
                        return <Option key={marketplace.id} value={marketplace.id}>{marketplace.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Codice principale"
               name="parent_id"
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il codice principale"
                  optionFilterProp="children"
               >
                  {
                     this.state.parentCodesList.map((code) => {
                        return <Option key={code.id} value={code.id}>{code.code}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Codice"
               name="code"
               rules={[{ required: true, message: 'Inserisci il codice' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Tipo di codice"
               name="type"
               rules={[{ required: true, message: 'Seleziona il tipo di codice' }]}
            >
               <Select
                  style={{ width: 200 }}
                  placeholder="Seleziona il tipo di codice"
                  optionFilterProp="children"
               >
                  {
                     Object.values(PurchaseOrderTypeEnum).map((type) => {
                        if (typeof type === 'string' && type !== 'NULL') {
                           return <Option key={PurchaseOrderTypeEnum[type]} value={PurchaseOrderTypeEnum[type]}>{type}</Option>
                        }
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Descrizione"
               name="description"
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Categorie associate"
               name="category_ids"
            >
               <Select
                  showSearch
                  mode="multiple"
                  style={{ width: 500 }}
                  placeholder="Seleziona le categoria associate"
                  optionFilterProp="children"
               >
                  {
                     this.state.categoriesList.map((category) => {
                        return <Option key={category.id} value={category.id}>{!_.isNil(category.name[this.state.componentLanguage]) ? category.name[this.state.componentLanguage] : category.name['it']}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Attivo"
               name="active"
               valuePropName="checked"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.active}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.vendor_codes.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formVendorCode" htmlType="button" onClick={this.onReset} disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="formVendorCode" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="formVendorCode" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

export default connect(mapStateToProps)(MarketplacesEdit);