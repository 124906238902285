import * as constants from '../../constants';
import { updateObject } from '../../shared/utility';
import initialState from './initialState';

const authLogout = (state) => {
   return updateObject(state, { isLoggedIn: false, userData: null });
};

const authLogin = (state, action) => {
   return updateObject(state, { isLoggedIn: true, userData: action.payload, defaultLanguage: 'it' });
};

const loadMarketplacesLanguagesSuccess = (state, action) => {
   const newUserData = state.userData;
   newUserData.languages = action.languages;
   return updateObject(state, { userData: newUserData });
};

/**
 *
 * @param state
 * @param action
 * @returns {default.authenticationState|{access_token}|authenticationReducer|default.authenticationState|{access_token}}
 */
export default function authenticationReducer(state = initialState.authenticationState, action) {
   switch (action.type) {
      case constants.ACTIONS.INIT_APP:
         if (action.authenticationState)
            return action.authenticationState;
         return state;
      case constants.ACTIONS.AUTH_SUCCESS: return authLogin(state, action);
      case constants.ACTIONS.AUTH_LOGOUT: return authLogout(state);
      
      case constants.ACTIONS.LOAD_MARKETPLACES_LANGUAGES_SUCCESS: return loadMarketplacesLanguagesSuccess(state, action);
      default:
         return state;
   }
}
