import _ from 'lodash';
import * as consts from '../../constants';
import commonApi from '../../api/commonApi';

/** lock screen */
export function lockScreen() {
   return { type: consts.ACTIONS.SCREEN_LOCK_REQUEST };
}

/** Un lock screen */
export function unlockScreen() {
   return { type: consts.ACTIONS.SCREEN_UNLOCK_REQUEST };
}

export function loadingConfiguration() {
   return { type: consts.ACTIONS.CFG_LOADING };
}

/** Un lok screen */
export function loadedConfiguration() {
   return { type: consts.ACTIONS.CFG_LOADED };
}

export function systemFatalError(errorCode, error) {

   if (_.toNumber(error) > 0) {
      return { type: consts.ACTIONS.SYSTEM_FATAL_ERROR, errorCode: _.toNumber(error) };
   }
   else {
      return { type: consts.ACTIONS.SYSTEM_FATAL_ERROR, errorCode: errorCode };
   }
}


/**
 * Retrive contries from db and create country options
 */
export function getCountries() {
   return function (dispatch) {

      return commonApi.getCountries()
         .then(countries => {

            const countryOption = countries.map(
               (item) => {
                  return { value: item.iso, label: item.name };
               });

            dispatch(getCountriesSuccess(countries, countryOption));
         })
         .catch(error => {
            dispatch(systemFatalError(consts.ERROR_CODES.CONFIGURATION_LOADING));
         });
   };
}

/** 
 * Load all Store
*/
export function loadAllStore() {
   return function (dispatch) {
      dispatch(lockScreen());
      dispatch(loadingConfiguration());
      dispatch(loadLanguage('it-IT'));
      // dispatch(getCountries());
      dispatch(loadedConfiguration());
      dispatch(unlockScreen());
   };
}

//  ===================== LANGUAGES =====================

/**
 * Load language JSON
 * @param {*} language 
 */
export function loadLanguage(language) {
   return function (dispatch, getState) {
      dispatch(loadingConfiguration());
      return commonApi.loadLanguage(language).then(messages => {
         dispatch(loadLanguageSuccess(messages));
         dispatch(loadedConfiguration());
      }).catch(error => {
         console.log(error);
         // debugger;
         dispatch(loadedConfiguration());
         dispatch(systemFatalError(consts.ERROR_CODES.CONFIGURATION_LOADING));
      });
   };
}

/**
 * Load product language
 * @param {*} pcode 
 * @param {*} language 
 */
export function loadProductLanguage(pcode, language) {
   return function (dispatch, getState) {
      dispatch(loadingConfiguration());
      return commonApi.loadProductLanguage(pcode, language).then(messages => {
         dispatch(loadProductLanguageSuccess(messages));
         dispatch(loadedConfiguration());
      }).catch(error => {
         dispatch(loadedConfiguration());
         dispatch(systemFatalError(consts.ERROR_CODES.PRODUCT_CONFIGURATION_LOADING));
      });
   };
}

export function updateLanguageMessage(messageKey, value) {
   return function (dispatch, getState) {

      const messages = getState().commonState.messages;

      if (!_.isNil(messages[messageKey])) {
         messages[messageKey] = value;
         dispatch(updateLanguageMessageSuccess(messages));
      }
      else
         console.warn('Message key ' + messageKey + ' not found');
   };
}

function loadLanguageSuccess(messages) {
   return { type: consts.ACTIONS.LOAD_LANGUAGES_SUCCESS, messages };
}

function loadProductLanguageSuccess(messages) {
   return { type: consts.ACTIONS.LOAD_PRODUCT_LANGUAGES_SUCCESS, messages };
}

function updateLanguageMessageSuccess(messages) {
   return { type: consts.ACTIONS.UPDATE_LANGUAGES_SUCCESS, messages };
}


function getCountriesSuccess(countries, options) {
   return { type: consts.ACTIONS.GET_COUNTRIES_SUCCESS, countries, options };
}



export function importExcel(data) {
   const { onSuccess, onError, file, onProgress } = data;
   return commonApi.importExcel(data)
      .then(response => {
         if (response.data.success === true) {
            onSuccess(response.data)
         }
         else {
            const error = new Error(response.data.error ? response.data.error : 'Server error')
            onError({ event: error })
         }
      })
      .catch(error => {
         console.log(error)
         onError({ event: error })
      });
}

export function downloadFromS3(filePath, fileName) {
   return commonApi.downloadFromS3(filePath, fileName)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = fileName;
         link.click();
         return true;
      })
      .catch(error => {
         throw error
      })
}