import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Button, Space, notification, List, Descriptions } from 'antd'

import languageManager from '../../../../services/languageManager'
import Loader from '../../../common/PageElements/Loader'

class DrawerViewConfirmation extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getSuppliersList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible)) {
         return false
      }
      return true
   }

   onClose = () => {
      this.state.onClose()
   }

   render() {
      console.log('Render VIEW progressive drawer', this.props)

      return (
         <Drawer
            title={languageManager.getMessage(this.props.commonState, 'common.view') + ' ' + languageManager.getMessage(this.props.commonState, 'component.progressives.name')}
            width={700}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <Descriptions size='small' column={1} bordered>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.progressive')}>{this.props.item.progressive}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.type')}>{languageManager.getMessage(this.props.commonState, 'component.progressives.type.' + this.props.item.type)}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.category')}>{this.props.item.category}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.supplier')}>{this.props.item.supplier.name}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.total_requested')}>{this.props.item.total_requested}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.total_wsp')}>{this.props.item.total_wsp}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.customer')}>{this.props.item.customer.name}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.conditions_buy')}>{this.props.item.conditions_buy}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.conditions_sell')}>{this.props.item.conditions_sell}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.creation_date')}>{this.props.item.created_at_human}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.creation_user')}>{this.props.item.user.firstname + ' ' + this.props.item.user.lastname}</Descriptions.Item>
                     <Descriptions.Item label={languageManager.getMessage(this.props.commonState, 'component.label.status')}>{!_.isNil(this.props.item.status) ? this.props.item.status.name : 'Sconosciuto'}</Descriptions.Item>
                  </Descriptions>
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(DrawerViewConfirmation)