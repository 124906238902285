import SearchProductsApi from '../../../../../api/SearchProductsApi'
// import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function searchProductInCatalogue(formData) {
   return SearchProductsApi.searchProductInCatalogue(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function searchProductInCatalogueByMpn(formData) {
   return SearchProductsApi.searchProductInCatalogueByMpn(formData)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function searchProductInCatalogueByTitle(title) {
   return SearchProductsApi.searchProductInCatalogueByTitle(title)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function lookupAmazonDataByMpnOrEanOrTitle(mpn, ean_upc, title) {
   return SearchProductsApi.lookupAmazonDataByMpnOrEanOrTitle(mpn, ean_upc, title)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function lookupAmazonDataByMpnOrAsinOrEanOrTitle(mpn, asin, ean_upc, title) {
   return SearchProductsApi.lookupAmazonDataByMpnOrAsinOrEanOrTitle(mpn, asin, ean_upc, title)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getLookupResults(type, product_ids) {
   return SearchProductsApi.getLookupResults(type, product_ids)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function searchFromFile(formData) {
   return SearchProductsApi.searchFromFile(formData)
      .then(response => {
         let blob = new Blob(["\uFEFF" + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = formData.export_filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}