import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CloseOutlined, ImportOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/asin_availability'

import DrawerImport from './components/DrawerImport'

import Loader from '../../common/PageElements/Loader'

class AsinAvailability extends Component {
   state = {
      pageLoader: true,
      importLoader: false,
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      feedback: {},
   };

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  vendorCodesList: response.vendorCodesList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select

      const drawerImport = (
         <DrawerImport
            key='importDrawer'
            visible={this.state.showImportDrawer}
            complements={{ ...this.state.complements }}
            onClose={() => this.showImportDrawer(false)} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button type='primary'
                           onClick={() => this.showImportDrawer(true)}
                        >Importa file</Button>
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                  </div>
               </div>
            </div>
            {drawerImport}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.analysis.asin_availability.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(AsinAvailability);