import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class StockSuppliersApi {

   static getModel() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.getModel)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.getList)
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.getListExtended, { ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static cancelItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.cancelItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static emptyWarehouses() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.emptyWarehouses)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
         ])
            .then(Axios.spread((suppliersList, brandsList) => {
               const response = {
                  suppliersList: suppliersList,
                  brandsList: brandsList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static importFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.importFromFile, { ...formData })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static fileImport(data) {
      console.log(data.file)
      return new Promise((resolve, reject) => {
         let formData = new FormData;
         formData.append('file', data.file)
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.fileImport, formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static exportExcel(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.stockSuppliers.exportExcel, { filename: filename, item_ids: itemIds },
            {
                responseType: 'blob'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default StockSuppliersApi