import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class ClosingPurchaseOrdersApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(params, filterValues) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.getListExtended, {...params, filter_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getClosingOrdersComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_order_statuses.getList),
         ])
            .then(Axios.spread((statusesList) => {
               const response = {
                  statusesList: statusesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static sendClosingPOUpdates(orderId, lineIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.test.sendClosingPOUpdates, {
            closing_order_id: orderId,
            line_ids: lineIds
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportOrders(filename, orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.exportOrdersToCsv, {
            filename: filename,
            order_ids: orderIds,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportToCSV(filename, orderId, lineIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.exportLinesToCsv, {
            filename: filename,
            closing_order_id: orderId,
            line_ids: lineIds
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static updateOrderStatus(orderId, newStatus) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.closing_orders.updateOrderStatus, {
            order_id: orderId,
            new_status: newStatus,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default ClosingPurchaseOrdersApi;