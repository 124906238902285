import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import Moment from 'moment';
import _ from 'lodash';

import { Table, Card, Input, Select, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, SearchOutlined, UploadOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/manual_updates';

import ClosingOrdersStatusLabel from '../../common/components/ClosingOrdersStatusLabel';
import ClosingOrdersStatusButton from '../../common/components/ClosingOrdersStatusButton';
// import DropdownButton from '../../common/components/DropdownButton';
import DropdownMenu from '../../common/components/DropdownMenu';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class ClosingOrderDetails extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      orderDetails: {},
      poDetails: {},
      orderLines: [],
      fullListIds: [],
      componentLanguage: this.props.authState.defaultLanguage,

      selectedRowKeys: [],
      multipleActionList: [
         {
            action: 'send_to_amazon_vendor',
            name: 'Invia selezionati ad Amazon Vendor'
         },
         {
            action: 'export',
            name: 'Esporta selezionati in CSV'
         }
      ],
      multipleActionLoader: false,
      closingOrderStatusList: [],
      closingOrderStatusLoader: false,
   };

   getItem = () => {
      const itemId = this.props.match.params.id;
      // asyncronous calls
      return storeActions.getItem(itemId)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  orderLines: response.data.order_lines,
                  fullListIds: response.data.full_list_ids,
                  orderDetails: response.data,
                  poDetails: response.data.purchase_order
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getClosingOrdersComplements()
         .then(response => {
            console.log(response);
            try {
               let list = []
               _.forEach(response.statusesList, function (value) {
                  list.push({ id: value.id, name: value.t_name })
               })
               this.setState({
                  complementsResponse: response,
                  closingOrderStatusList: list,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getItem(),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   closingOrderStatusHandler = (value) => {
      const props = value.item.props
      this.setState({ closingOrderStatusLoader: true });
      storeActions.updateOrderStatus(this.props.match.params.id, props.id)
         .then(response => {
            console.log(response);
            this.setState({ closingOrderStatusLoader: false });
            try {
               this.getItem();
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ closingOrderStatusLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   sendUpdatesHandler = () => {
      this.setState({ multipleActionLoader: true });
      storeActions.sendClosingPOUpdates(this.props.match.params.id, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleActionLoader: false });
            try {
               this.getItem();
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportToCSVHandler = () => {
      this.setState({ multipleActionLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_' + this.state.poDetails.purchase_order_number + '_STATUS.csv';
      storeActions.exportToCSV(filename, this.props.match.params.id, this.state.selectedRowKeys)
         .then(response => {
            console.log(response);
            this.setState({ multipleActionLoader: false });
            try {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleActionLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'send_to_amazon_vendor':
            this.sendUpdatesHandler();
            break;
         case 'export':
            this.exportToCSVHandler();
            break;
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break;

      }
   }

   getColumnSearchProps = (dataIndex, title) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Cerca ${title}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
               >
                  Search
               </Button>
               <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: (text, record) =>
         this.state.searchedColumn === dataIndex ? (
            <Button className="link" onClick={() => this.props.history.push('/purchase_orders/details/' + record.id)}>
               <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={record[dataIndex] ? record[dataIndex] : ''}
               />
            </Button>
         ) : (
            <Button className="link" onClick={() => this.props.history.push('/purchase_orders/details/' + record.id)}>
               {record[dataIndex] ? record[dataIndex].toString() : ''}
            </Button>
         ),
   });
   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };
   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys })
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const tableLayout = {
         bordered: true,
         size: "small",
         showSorterTooltip: false,
         pagination: { pageSize: 50 }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
         selections: [
            // Table.SELECTION_ALL,
            // {
            //    key: 'all',
            //    text: 'Seleziona tutto nella pagina',
            //    onSelect: changableRowKeys => {
            //       let newSelectedRowKeys = [];
            //       newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            //          return true;
            //       });
            //       this.setState({ selectedRowKeys: newSelectedRowKeys });
            //    },
            // },
            Table.SELECTION_INVERT,
         ],
      };

      const order_lines_table_columns = [
         {
            title: 'ASIN',
            width: 120,
            key: 'asin',
            fixed: 'left',
            render: (record) => (
               record.asin
            ),
            sorter: (a, b) => a.asin.localeCompare(b.asin),
            ...this.getColumnSearchProps('asin', 'ASIN'),
         },
         // {
         //    title: 'ID esterno',
         //    width: 120,
         //    key: 'vendor_id',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.vendor_id
         //    ),
         //    sorter: (a, b) => a.vendor_id.localeCompare(b.vendor_id),
         // },
         // {
         //    title: 'Modello',
         //    width:150,
         //    key: 'item_code',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.item_code
         //    ),
         //    sorter: (a, b) => a.item_code.localeCompare(b.item_code),
         //    ellipsis: true,
         // },
         {
            title: 'Titolo',
            key: 'title',
            fixed: 'left',
            render: (record) => (
               record.title
            ),
            sorter: (a, b) => a.title.localeCompare(b.title),
            ellipsis: true,
         },
         {
            title: 'Quantità richiesta',
            width: 100,
            key: 'quantity_requested',
            fixed: 'left',
            render: (record) => (
               record.amazon_data.quantity_requested
            ),
            sorter: (a, b) => a.amazon_data.quantity_requested - b.amazon_data.quantity_requested,
         },
         {
            title: 'Quantità accettata',
            width: 100,
            key: 'quantity_accepted',
            fixed: 'left',
            render: (record) => {
               return {
                  props: {
                     className: record.hasDifferences ? "bg-warning" : '',
                  },
                  children: record.amazon_data.quantity_accepted,
               };
            },
            sorter: (a, b) => a.amazon_data.quantity_accepted - b.amazon_data.quantity_accepted,
         },
         {
            title: 'Quantità ricevuta',
            width: 100,
            key: 'quantity_received',
            fixed: 'left',
            render: (record) => {
               return {
                  props: {
                     className: record.hasDifferences ? "bg-warning" : '',
                  },
                  children: record.amazon_data.quantity_received,
               };
            },
            sorter: (a, b) => a.amazon_data.quantity_received - b.amazon_data.quantity_received,
         },
         {
            title: 'Quantità rimanente',
            width: 100,
            key: 'quantity_remaining',
            fixed: 'left',
            render: (record) => (
               record.amazon_data.quantity_remaining
            ),
            sorter: (a, b) => a.amazon_data.quantity_remaining - b.amazon_data.quantity_remaining,
         },
         {
            title: 'Quantità confermata',
            width: 100,
            key: 'quantity_confirmed',
            fixed: 'left',
            render: (record) => {
               return {
                  props: {
                     className: record.hasDifferences ? "bg-warning" : '',
                  },
                  children: record.quantity_confirmed,
               };
            },
            sorter: (a, b) => a.quantity_confirmed - b.quantity_confirmed,
         },
         {
            title: 'Quantità fatturata',
            width: 100,
            key: 'quantity_actual',
            fixed: 'left',
            render: (record) => {
               return {
                  props: {
                     className: record.hasDifferences ? "bg-warning" : '',
                  },
                  children: record.quantity_actual,
               };
            },
            sorter: (a, b) => a.quantity_actual - b.quantity_actual,
         },
         {
            title: 'Costo unitario',
            width: 100,
            key: 'price_unit',
            fixed: 'left',
            render: (record) => (
               record.price_unit + ' €'
            ),
            sorter: (a, b) => a.price_unit - b.price_unit,
         },
         {
            title: 'Differenze',
            width: 100,
            key: 'has_differences',
            fixed: 'left',
            render: (record) => (
               record.hasDifferences ? 'Sì' : 'No'
            ),
            sorter: (a, b) => a.hasDifferences - b.hasDifferences,
            filters: [
               { text: 'Mostra solo differenze', value: true },
            ],
            onFilter: (value, record) => record.hasDifferences === value,
         },
         // {
         //    title: 'Azioni',
         //    key: 'actions',
         //    fixed: 'right',
         //    align: 'right',
         //    width: 100,
         //    render: (text, record) => (
         //       <Space>
         //          {/* <Popconfirm
         //             placement="topRight"
         //             title="Cancelli?"
         //             onConfirm={() => this.confirmHandler('deleteItem', record.id)}
         //             okText="Yes"
         //             cancelText="No"
         //          >
         //             <Button className="link"><i className="far fa-trash-alt"></i></Button>
         //          </Popconfirm> */}
         //       </Space>),
         // },
      ];
      const order_lines_table =
         <Table
            {...tableLayout}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            columns={order_lines_table_columns}
            dataSource={this.state.orderLines}
         />;

      const order_data = (
         <Card title={"Ordine d'acquisto: " + this.state.poDetails.purchase_order_number}>
            <table>
               <tbody>
                  <tr>
                     <td>Fornitore</td>
                     <td>{this.state.poDetails.vendor}</td>
                  </tr>
                  <tr>
                     <td>Spedire a</td>
                     <td>{this.state.poDetails.shipment_address}</td>
                  </tr>
                  <tr>
                     <td>Tipo di spedizione</td>
                     <td>{this.state.poDetails.shipment_type}</td>
                  </tr>
                  <tr>
                     <td>Finestra di consegna</td>
                     <td>{Moment(this.state.poDetails.shipment_window_start).format('L') + " - " + Moment(this.state.poDetails.shipment_window_end).format('L')}</td>
                  </tr>
               </tbody>
            </table>
         </Card>
      );

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const order_wrapper = (
         <div>
            {order_data}

            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        {/* <Button type="primary" onClick={() => this.sendUpdatesHandler(this.props.match.params.id)} disabled={!hasSelected} loading={this.state.loader}>
                        Invia selezionati ad Amazon Vendor
                     </Button> */}

                        {/* <DropdownButton
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        /> */}

                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        {/* <Button type="primary" loading={this.state.loader}>
                        Mostra solo righe con differenze
                     </Button> */}

                        <ClosingOrdersStatusLabel
                           value={this.state.orderDetails.closing_po_status_id}
                           title={this.state.orderDetails.order_status_name}
                        />
                        {/* <Button type="primary" className="cursor-default-I">
                           {this.state.orderDetails.order_status_name}
                        </Button> */}
                        <DropdownMenu
                           type='primary'
                           title={languageManager.getMessage(this.props.commonState, 'common.components.closing_order_status_button.title')}
                           action={this.closingOrderStatusHandler}
                           values={this.state.closingOrderStatusList}
                           loading={this.state.closingOrderStatusLoader}
                        />

                        {/* <ClosingOrdersStatusButton
                        action={this.closingOrderStatusHandler}
                        loading={this.state.closingOrderStatusLoader}
                     /> */}
                        {/* <ClosingOrdersStatusLabel
                        value={this.state.orderDetails.status}
                     /> */}
                     </Space>
                  </div>
               </div>
            </div>
            {order_lines_table}
         </div >
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-12">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {/* <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.sendUpdatesHandler(this.props.match.params.id)}
                           >
                              Invia aggiornamento completo ad Amazon Vendor
                           </Button> */}
                           <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/purchase_order_lines/import/' + this.state.poDetails.id)}
                           >
                              Aggiorna PO
                           </Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : order_wrapper}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.closing_purchase_orders.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ClosingOrderDetails);