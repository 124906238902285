import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../services/languageManager'

import { Row, Col, Alert, List } from 'antd'

const ImportFeedback = (props) => {
   console.log(props)
   const [importResult, setImportResult] = useState(null)
   const [importStatus, setImportStatus] = useState(null)
   const [visible, setVisible] = useState(false)
   const [alertMessage, setAlertMessage] = useState('Risultato importazione')

   useEffect(() => {
      // console.log('IMPORT FEEDBACK USE EFFECT', props)
      setImportResult(props.importResult)
      setImportStatus(props.importStatus)
      setVisible(props.visible)
      switch (importStatus) {
         case 'success':
            setAlertMessage('Importazione completata con successo')
            break;
         case 'warning':
            setAlertMessage('Importazione completata con errori')
            break;
         case 'danger':
            setAlertMessage('Errore nell\'importazione')
            break;
      }
      // console.log('IMPORT FEEDBACK VALUES', importResult, importStatus, visible)
   }, [props])

   return (visible && !_.isNil(importResult)) ? (
      <Alert
         closable
         onClose={() => setVisible(false)}
         message={alertMessage}
         description={<List
            size="small"
            dataSource={importResult}
            renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
         />}
         type={importStatus}
         showIcon
      />
   ) : null
}

const mapStateToProps = state => {
   return {
      commonState: state.commonState,
   }
}

export default connect(mapStateToProps)(ImportFeedback)