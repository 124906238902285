import React from 'react';
import { Card, Spin } from 'antd';
import { Row, Col, Progress, Image } from 'antd';

/**
 * Renders the preloader
 */
const Loader = () => {
  return (
    // <Card style={{ width: '100%' }} className="text-center">
    //    <Spin size="large" />
    // </Card>
    <Row justify='center' align='middle' className='splash-screen'>
      <Col span={2}>
        <Image preview={false} src='/nzt_logo_splash.png' />
        <Progress
          strokeColor={{
            from: '#000000',
            to: '#F3E600',
          }}
          percent={100}
          status="active"
          showInfo={false}
        />
      </Col>
    </Row>
  );
};

export default Loader;