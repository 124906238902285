import React from 'react'
import { connect } from 'react-redux'

import languageManager from '../../../../services/languageManager'
import { VendorCodeStatusCatalogEnum } from '../../../../shared/enums'

import { Tooltip } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CloseCircleFilled } from '@ant-design/icons'

const VendorCodeCatalogStatus = (props) => {
    switch (props.status) {
        case VendorCodeStatusCatalogEnum.API_OK:
            return (
                <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.catalog.' + VendorCodeStatusCatalogEnum.API_OK)}>
                    <CheckCircleOutlined style={{color: '#389e0d'}} />
                </Tooltip>
            )
            break
        case VendorCodeStatusCatalogEnum.API_NOT_FOUND:
            return (
                <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.catalog.' + VendorCodeStatusCatalogEnum.API_NOT_FOUND)}>
                    <CloseCircleFilled style={{color: '#d9363e'}} />
                </Tooltip>
            )
            break
        case VendorCodeStatusCatalogEnum.API_NOT_SEARCHED:
        default:
            return null
            // return (
            //     <Tooltip title={languageManager.getMessage(props.commonState, 'component.vendor_codes.status.catalog.' + VendorCodeStatusCatalogEnum.API_NOT_SEARCHED)}>
            //         <CloseCircleOutlined style={{color: '#d48806'}} />
            //     </Tooltip>
            // )
            break
    }
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(VendorCodeCatalogStatus)