import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class AsinAvailabilityApi {

   static getModel() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.analysisAsinAvailability.getModel)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getList),
         ])
            .then(Axios.spread((vendorCodesList) => {
               const response = {
                  vendorCodesList: vendorCodesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static analyzeFile(formData, filename) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.analysisAsinAvailability.analyzeFile, { ...formData, filename: filename },
            {
               responseType: 'blob'
            })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

}

export default AsinAvailabilityApi