import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, InputNumber, Select, Button, Row, Col } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

const FormEditConfirmation = (props) => {
    const [layout, setLayout] = useState(props.layout)
    // const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)
    const [complements, setComplements] = useState(props.complements)

    const [newCategoryButton, setNewCategoryButton] = useState(true)
    const [newCategoryInput, setNewCategoryInput] = useState(false)
    const [newSupplierButton, setNewSupplierButton] = useState(true)
    const [newSupplierInput, setNewSupplierInput] = useState(false)
    const [newCustomerButton, setNewCustomerButton] = useState(true)
    const [newCustomerInput, setNewCustomerInput] = useState(false)

    const [form] = Form.useForm()
    const { Option } = Select

    useEffect(() => {
        console.log(props)
        // setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
            setNewCategoryButton(true)
            setNewSupplierButton(true)
            setNewCustomerButton(true)
        } else {
            form.setFieldsValue(props.values)
            if(!_.isNil(props.values.category)) setNewCategoryButton(false)
            if(!_.isNil(props.values.supplier_id)) setNewSupplierButton(false)
            if(!_.isNil(props.values.customer_id)) setNewCustomerButton(false)
        }
    }, [props.values])

    const submitForm = (values) => {
        // values.customer_id = customerId
        props.submitForm(values)
    }

    const onCategorySelect = (value, option) => {
        setNewCategoryButton(false)
    }
    const onCategoryClear = () => {
        setNewCategoryButton(true)
    }
    const newCategoryButtonHandler = (value) => {
        setNewCategoryInput(value)
        if (value === false) {
            form.setFieldsValue({ category: null })
        } else {
            form.setFieldsValue({ category_new: null })
        }
    }

    const onSupplierSelect = (value, option) => {
        setNewSupplierButton(false)
    }
    const onSupplierClear = () => {
        setNewSupplierButton(true)
    }
    const newSupplierButtonHandler = (value) => {
        setNewSupplierInput(value)
        if (value === false) {
            form.setFieldsValue({ supplier_id: null })
        } else {
            form.setFieldsValue({ supplier_new: null })
        }
    }

    const onCustomerSelect = (value, option) => {
        setNewCustomerButton(false)
    }
    const onCustomerClear = () => {
        setNewCustomerButton(true)
    }
    const newCustomerButtonHandler = (value) => {
        setNewCustomerInput(value)
        if (value === false) {
            form.setFieldsValue({ customer_id: null })
        } else {
            form.setFieldsValue({ customer_new: null })
        }
    }

    return (
        <Form
            form={form}
            {...layout}
            id="FormEditProgressive"
            onFinish={submitForm}
        >
            {
                !_.isNil(props.values) && !_.isNil(props.values.id) ? (
                    <Form.Item
                        label={languageManager.getMessage(props.commonState, 'component.label.progressive')}
                        name="progressive"
                    >
                        <Input disabled />
                    </Form.Item>
                ) : null
            }
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.type')}
                name="type"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il tipo"
                    optionFilterProp="children"
                >
                    {
                        complements.typesList.map((type) => {
                            return <Option key={type.id} value={type.id}>{type.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.category')}
            >
                {
                    newCategoryInput ? (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="category_new"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Input style={{ width: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='danger' onClick={() => newCategoryButtonHandler(false)}><CloseOutlined /></Button>
                            </Col>
                        </Row >
                    ) : (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="category"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona la categoria"
                                        optionFilterProp="children"
                                        onSelect={onCategorySelect}
                                        onClear={onCategoryClear}
                                    >
                                        {
                                            complements.categoriesList.map((category) => {
                                                return <Option key={category.key} value={category.id}>{category.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    newCategoryButton ? (
                                        <Button type='success' onClick={() => newCategoryButtonHandler(true)}><PlusOutlined /></Button>
                                    ) : null
                                }
                            </Col>
                        </Row >
                    )
                }
            </Form.Item >
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.filter.brand')}
                name="brand_id"
                rules={[{ required: true }]}
            >
                <Select
                    showSearch
                    allowClear={true}
                    style={{ width: 200 }}
                    placeholder="Seleziona il Brand"
                    optionFilterProp="children"
                >
                    {
                        complements.brandsList.map((brand) => {
                            return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.filter.supplier')}
            >
                {
                    newSupplierInput ? (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="supplier_new"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Input style={{ width: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='danger' onClick={() => newSupplierButtonHandler(false)}><CloseOutlined /></Button>
                            </Col>
                        </Row >
                    ) : (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="supplier_id"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona il fornitore"
                                        optionFilterProp="children"
                                        onSelect={onSupplierSelect}
                                        onClear={onSupplierClear}
                                    >
                                        {
                                            complements.suppliersList.map((supplier) => {
                                                return <Option key={supplier.id} value={supplier.id}>{supplier.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    newSupplierButton ? (
                                        <Button type='success' onClick={() => newSupplierButtonHandler(true)}><PlusOutlined /></Button>
                                    ) : null
                                }
                            </Col>
                        </Row >
                    )
                }
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.filter.customer')}
            >
                {
                    newCustomerInput ? (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="customer_new"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Input style={{ width: 200 }} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Button type='danger' onClick={() => newCustomerButtonHandler(false)}><CloseOutlined /></Button>
                            </Col>
                        </Row >
                    ) : (
                        <Row gutter={4}>
                            <Col>
                                <Form.Item
                                    name="customer_id"
                                    noStyle
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        showSearch
                                        allowClear={true}
                                        style={{ width: 200 }}
                                        placeholder="Seleziona il cliente"
                                        optionFilterProp="children"
                                        onSelect={onCustomerSelect}
                                        onClear={onCustomerClear}
                                    >
                                        {
                                            complements.customersList.map((customer) => {
                                                return <Option key={customer.id} value={customer.id}>{customer.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                {
                                    newCustomerButton ? (
                                        <Button type='success' onClick={() => newCustomerButtonHandler(true)}><PlusOutlined /></Button>
                                    ) : null
                                }
                            </Col>
                        </Row >
                    )
                }
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.total_pieces_requested')}
                name="total_requested"
                rules={[{ required: true }]}
            >
                <InputNumber min={0} step={1}
                    style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.total_wsp')}
                name="total_wsp"
                rules={[{ required: true }]}
            >
                <InputNumber min={0} step={1} addonAfter="€"
                    style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.conditions_buy')}
                name="conditions_buy"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.conditions_sell')}
                name="conditions_sell"
            >
                <Input />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form >
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(FormEditConfirmation)