import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { PurchaseOrderTypeEnum } from '../../../shared/enums.ts'

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CloseOutlined, EyeOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined, ClockCircleOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/analysis_ean_asin'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import DrawerViewProgressive from './components/DrawerViewProgressive'
import DrawerImportEanAsin from './components/DrawerImportEanAsin'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

class AnalysisEanAsin extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      importLoader: false,
      loadersQueue: [],
      loadersCancel: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [],
      multipleExportLoader: false,
      multipleExportList: [
         {
            action: 'exportCodeAnalysisResults',
            name: 'Esporta analisi per EAN/UPC'
         },
         {
            action: 'exportAsinAnalysisResults',
            name: 'Esporta analisi per ASIN'
         },
      ],
      feedback: {},
      searchOrderInput: null,

      filters: [],
      // filtersValues: {},
      appliedFilters: [],
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filters)
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  // typesList: response.typesList,
                  // categoriesList: response.categoriesList,
                  // quartersList: response.quartersList,
                  // brandsList: response.brandsList,
                  // suppliersList: response.suppliersList,
                  // customersList: response.customersList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   }
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'exportCodeAnalysisResults':
            this.exportCodeAnalysisResults()
            break
         case 'exportAsinAnalysisResults':
            this.exportAsinAnalysisResults()
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }
   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   }
   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, {});
   }

   showViewDrawer = item => {
      this.setState({ viewDrawerItem: item, showViewDrawer: true })
   }
   hideViewDrawer = () => {
      this.setState({ showViewDrawer: false })
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }
   
   addToAnalysisQueue = (itemId) => {
      this.setState({ loadersQueue: startLoading(this.state.loadersQueue, itemId) })
      storeActions.addToAnalysisQueue(itemId)
         .then(response => {
            console.log(response);
            this.setState({ loadersQueue: endLoading(this.state.loadersQueue, itemId) })
            try {
               this.getList(this.state.paginationParams, this.state.filters);
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersQueue: endLoading(this.state.loadersQueue, itemId) })
            if (this.props.commonState.debug) console.log(e)
         });
   }
   
   exportCodeAnalysisResults = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_export_ean_upc_analysis.xlsx';
      console.log("exportCodeAnalysisResults", filename, this.state.selectedRowKeys);
      storeActions.exportCodeAnalysisResults(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
               })
            } catch (e) {
               this.setState({ multipleExportLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0,
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }
   exportAsinAnalysisResults = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_export_asin_analysis.xlsx';
      console.log("exportAsinAnalysisResults", filename, this.state.selectedRowKeys);
      storeActions.exportAsinAnalysisResults(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
               })
            } catch (e) {
               this.setState({ multipleExportLoader: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0,
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 100,
            fixed: 'left',
            sorter: true,
            render: (record) => (
               record.ean_upc
            )
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 100,
            fixed: 'left',
            sorter: true,
            render: (record) => (
               record.asin
            )
         },
         {
            title: 'Brand',
            key: 'brand',
            width: 100,
            // fixed: 'left',
            sorter: true,
            render: (record) => (
               record.brand
            ),
         },
         {
            title: 'MPN',
            key: 'mpn',
            width: 150,
            // fixed: 'left',
            sorter: true,
            render: (record) => (
               <Space direction='vertical' size={0}>
                  <Text>MPN: {record.mpn}</Text>
                  <Text>UNIONE: {record.mpn_size}</Text>
               </Space>
            ),
         },
         {
            title: 'EU size',
            key: 'eu_size',
            width: 50,
            render: (record) => (
               record.eu_size
            ),
         },
         {
            title: 'UK size',
            key: 'uk_size',
            width: 50,
            render: (record) => (
               record.uk_size
            ),
         },
         {
            title: 'US size',
            key: 'us_size',
            width: 50,
            render: (record) => (
               record.us_size
            ),
         },
         {
            title: 'Listino',
            key: 'list_price',
            width: 75,
            render: (record) => (
               record.list_price_human
            ),
         },
         {
            title: 'Ricerca per EAN/UPC',
            key: 'last_results_ean',
            width: 150,
            render: (record) => (
               <Space direction='vertical' size={0}>
               {!_.isNil(record.status_ean) ? <Text>{languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.result_status.' + record.status_ean)}</Text> : null}
                  <Text>{record.last_results_ean_human}</Text>
               </Space>
            ),
            sorter: true,
         },
         {
            title: 'Ricerca per ASIN',
            key: 'last_results_asin',
            width: 150,
            render: (record) => (
               <Space direction='vertical' size={0}>
                  {!_.isNil(record.status_asin) ? <Text>{languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.result_status.' + record.status_asin)}</Text> : null}
                  <Text>{record.last_results_asin_human}</Text>
               </Space>
            ),
            sorter: true,
         },
         {
            title: 'Anomalie',
            key: 'anomalies',
            // width: 100,
            render: (record) => (
               <Space direction='vertical' size={0}>
                  {!_.isNil(record.result_ean_anomalies) ? <Text>EAN/UPC: {record.result_ean_anomalies}</Text> : null}
                  {!_.isNil(record.result_asin_anomalies) ? <Text>ASIN: {record.result_asin_anomalies}</Text> : null}
               </Space>
            ),
         },
         {
            title: 'Stato',
            key: 'status',
            width: 150,
            render: (record) => (
               !_.isNil(record.status) ? languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.status.' + record.status) : null
            ),
            sorter: true,
         },
         {
            title: 'Note',
            key: 'note',
            width: 150,
            ellipsis: true,
            render: (record) => (
               record.note
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Button type="success" size='small'
                     icon={<EyeOutlined />}
                     onClick={() => this.showViewDrawer(record)}
                  />
                  <Button type="primary" size='small'
                     icon={<ClockCircleOutlined />}
                     onClick={() => this.addToAnalysisQueue(record.id)}
                     loading={this.state.loadersQueue[record.id]}
                  />
                  <Button type="danger" size='small'
                     icon={<CloseOutlined />}
                     loading={this.state.loadersCancel[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.cancel_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askCancelConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.cancelItemHandler(record.id)
                        })
                     }
                  />
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' progressivi' : ' progressivo')
         },
         onChange: this.handleTableChange,
         rowClassName: (record) => { return !_.isNil(record.status) && record.status.code === 'CANCELLED' ? 'record-cancelled' : null }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const analysisEanAsinTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         // rowClassName={(record, index) => {
         //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
         // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      // const drawerEditProgressive = (
      //    <DrawerEditProgressive
      //       key='createDrawer'
      //       visible={this.state.showCreateDrawer}
      //       item={this.state.createDrawerItem}
      //       complements={{ ...this.state.complements }}
      //       onClose={this.hideCreateDrawer}
      //       onSubmit={(data) => this.handleCreateOrUpdateProgressive(data)} />
      // )
      // const drawerViewProgressive = (
      //    <DrawerViewProgressive
      //       key='viewDrawer'
      //       visible={this.state.showViewDrawer}
      //       item={this.state.viewDrawerItem}
      //       onClose={this.hideViewDrawer} />
      // )
      const drawerImportEanAsin = (
         <DrawerImportEanAsin
            key='importDrawer'
            visible={this.state.showImportDrawer}
            onSubmit={this.updateList}
            onClose={() => this.showImportDrawer(false)} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                        <DropdownMenu
                           type='primary'
                           title='Esporta'
                           action={this.multipleActionHandler}
                           values={this.state.multipleExportList}
                           disabled={!hasSelected}
                           loading={this.state.multipleExportLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={() => this.getList(this.state.paginationParams, this.state.filters)}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.showImportDrawer(true)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.import')}
                        </Button>
                        {/* <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.new')}
                        </Button> */}
                     </Space>
                  </div>
               </div>
            </div>
            {analysisEanAsinTable}
            {/* {drawerEditProgressive}
            {drawerViewProgressive} */}
            {drawerImportEanAsin}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.analysis.ean_asin.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(AnalysisEanAsin);