import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'

import languageManager from '../../../services/languageManager'
import * as storeActions from '../store/actions/dashboard'
import { PurchaseOrderTypeEnum, PurchaseOrderGroupEnum } from '../../../shared/enums'
import { OrdersStatsRangeEnum } from '../../../shared/enums'
import { formatNumber } from '../../../shared/utility'
import { flagPath } from '../../../shared/vars'
import { getOrdersStats } from '../../../shared/faker/getOrdersStats'
import { getOrdersStatsBrands } from '../../../shared/faker/getOrdersStatsBrands'

import { Button, Space, notification, Row, Col, Divider, Typography, Card, Table, Avatar } from 'antd'
import { InfoCircleOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons'

class OrdersStatsCard extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        loaderBrandStats: false,
        loaderExportData: false,
        stats: [],
        statsFilters: {},
        brandStats: [],
        compareStats: [],
        showCharts: false,
        compareDates: [],
        ordersChartsData: {},
        expandedRowKeys: [],
    }

    componentDidMount() {
        const startDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
        const endDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
        Promise.all([
            this.getOrdersStatsData(startDate, endDate, OrdersStatsRangeEnum.WEEKLY, this.props.vendorCodeType, this.props.vendorCodeGroup),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                statsFilters: {
                    startDate: startDate,
                    endDate: endDate,
                    range: OrdersStatsRangeEnum.WEEKLY,
                    vendorCodeGroup: this.props.vendorCodeGroup,
                    vendorCodeType: this.props.vendorCodeType,
                }
            });
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!_.isEqual(prevProps.startDate, this.props.startDate) || !_.isEqual(prevProps.endDate, this.props.endDate) || !_.isEqual(prevProps.vendorCodeGroup, this.props.vendorCodeGroup)) {
            this.setState({ componentLoader: true, expandedRowKeys: [], brandStats: [] });
            Promise.all([
                this.getOrdersStatsData(this.props.startDate, this.props.endDate, this.props.range, this.props.vendorCodeType, this.props.vendorCodeGroup),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                });
            });
        }
    }

    getOrdersStatsData = (startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup) => {
        this.setState({ componentLoader: true })
        // asyncronous calls
        return storeActions.getOrdersStatsData(startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup)
            .then(response => {
                console.log(response);
                try {
                    this.setState({
                        componentLoader: false,
                        stats: response.data,
                        statsFilters: {
                            startDate: startDate,
                            endDate: endDate,
                            range: selectedRange,
                            vendorCodeGroup: vendorCodeGroup,
                            vendorCodeType: vendorCodeType,
                        },
                        compareDates: response.compare_dates,
                        showCharts: !_.isEmpty(response.compare_dates),
                    })
                } catch (e) {
                    this.setState({ componentLoader: false })
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }
            }).catch((e) => {
                this.setState({ componentLoader: false })
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    getOrdersBrandsStatsData = (startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup, countryId) => {
        this.setState({ loaderBrandStats: true })
        // asyncronous calls
        return storeActions.getOrdersBrandsStatsData(startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup, countryId)
            .then(response => {
                console.log(response);
                try {
                    const newStats = response.data
                    let brandStats = _.clone(this.state.brandStats)
                    brandStats.push(newStats)
                    console.log(brandStats)
                    this.setState({
                        brandStats: brandStats,
                    })
                } catch (e) {
                    this.setState({ loaderBrandStats: false })
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }
            }).catch((e) => {
                this.setState({ loaderBrandStats: false })
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    exportData = () => {
        this.setState({ loaderExportData: true })
        let now = moment().format('YYYY.MM.DD-HH.mm.ss')
        let filename = now + '_export_stats.csv'
        console.log("Export stats", filename, this.state.statsFilters, this.state.stats, this.state.brandStats)
        storeActions.exportStats(filename, this.state.statsFilters, this.state.stats, this.state.brandStats)
            .then(response => {
                try {
                    console.log(response)
                    this.setState({ loaderExportData: false })
                    notification.success({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                    })
                } catch (e) {
                    this.setState({ loaderExportData: false });
                    notification.error({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                    })
                }

            }).catch((e) => {
                notification.error({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                })
                this.setState({ loaderExportData: false });
                if (this.props.commonState.debug) console.log(e);
            });
    }

    onExpandChange = (expanded, record) => {
        // this.setState({ loaderBrandStats: true })
        // console.log(record)
        // console.log(this.state.brandStats, record.country.id)
        // console.log(this.state.brandStats.find(x => x.country.id === record.country.id))

        let expandedRows = _.clone(this.state.expandedRowKeys)
        if (expanded) {
            expandedRows.push(record.key)
            const countryId = record.country.id
            if (_.isUndefined(this.state.brandStats.find(x => x.country.id === countryId))) {
                Promise.all([
                    this.getOrdersBrandsStatsData(this.props.startDate, this.props.endDate, this.props.range, this.props.vendorCodeType, this.props.vendorCodeGroup, record.country.id),
                ]).then([
                    this.setState({ loaderBrandStats: false }),
                    // console.log(this.state.brandStats, countryId),
                    // !_.isUndefined(this.state.brandStats.find(x => x.country.id === record.country.id)) ? console.log(this.state.brandStats.find(x => x.country.id === record.country.id).stats) : null
                ])
            }
        } else {
            _.pull(expandedRows, record.key)
        }
        this.setState({ expandedRowKeys: expandedRows })
    }

    //NOT USED
    drawOrdersGraphs = (data, compareData) => {
        if (!_.isEmpty(compareData)) {
            console.log(compareData)
            let ordersChartData = {}
            const ordersAccepted = [{
                name: 'Last period',
                data: [compareData.orders_accepted]
            }, {
                name: 'Current period',
                data: [data.orders_accepted]
            }]
            ordersChartData.ordersAccepted = ordersAccepted

            this.setState({ showCharts: true, ordersChartData: ordersChartData })
        } else {
            this.setState({ showCharts: false })
        }
    }

    render() {
        const { Title, Text } = Typography
        const tableLayout = {
            bordered: true,
            size: "small",
            pagination: false,
            loading: this.state.componentLoader,
            rowClassName: (record) => { return 'background-' + record.country.code.toLowerCase() }
        }
        const tableBrandStatsLayout = {
            bordered: true,
            size: "small",
            pagination: false,
            loading: this.state.loaderBrandStats,
            // rowClassName: (record) => { return 'background-' + record.country.code.toLowerCase() }
        }
        const tableChartsLayout = {
            bordered: false,
            showHeader: false,
            size: "small",
            pagination: false,
            loading: this.state.componentLoader,
            rowClassName: () => { return 'va-top' },
            // expandable: {
            //     indentSize: 30,
            //     expandedRowKeys: this.state.expandedRowKeys,
            //     onExpand: this.onExpandChange
            // },
        }
        const barChartOptions = {
            // chart: {
            //     type: 'bar',
            //     height: 250,
            // },
            plotOptions: {
                bar: {
                    horizontal: true,
                    columnWidth: '100%',
                    barHeight: '80%',
                    dataLabels: {
                        position: 'center', // top, center, bottom
                        // orientation: 'vertical'
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: 0,
                offsetX: 0,
                textAnchor: 'middle',
                style: {
                    fontSize: '12px',
                    colors: ["#304758"],
                },
                formatter: function (val) {
                    return formatNumber(val)
                }
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                }
            },
            // fill: {
            //     opacity: 1
            // },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toLocaleString()
                    }
                }
            },
            theme: {
                mode: 'light',
                palette: 'palette4',
            }
        }
        const radialBarChartOptions = {
            theme: {
                mode: 'light',
                palette: 'palette4',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '20%'
                    },
                    dataLabels: {
                        name: {
                            fontSize: '12px',
                            fontWeight: 400,
                            color: '#304758'
                        },
                        value: {
                            fontWeight: 600,
                            formatter: function (val) {
                                return (parseFloat(val).toFixed(2)) + '%'
                            }
                        },
                        total: {
                            show: true,
                            label: 'Periodo corrente',
                            fontSize: '12px',
                            fontWeight: 400,
                            color: '#304758',
                            formatter: function (w) {
                                return (parseFloat(w.globals.series[0]).toFixed(2)) + '%'
                            }
                        }
                    }
                }
            }
        }

        const orderStatsByCountry = [
            {
                title: 'Nazione',
                key: 'country',
                width: 40,
                align: 'center',
                render: (record) => {
                    return (
                        <Avatar size='small' shape='square' src={flagPath + record.country.code + '_32.png'} />
                    )
                },
            },
            {
                title: 'Ordini confermati',
                key: 'orders_accepted',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.orders_accepted}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.orders_accepted}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.orders_accepted - b.orders_accepted,
            },
            {
                title: 'Prodotti ordinati',
                key: 'products_ordered',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.products_ordered}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.products_ordered}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.products_ordered - b.products_ordered,
            },
            {
                title: 'Prodotti sconosciuti',
                key: 'products_unknown',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.products_unknown}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.products_unknown}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.products_unknown - b.products_unknown,
            },
            {
                title: 'Prodotti confermati',
                key: 'products_accepted',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.products_accepted}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.products_accepted}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.products_accepted - b.products_accepted,
            },
            {
                title: 'Valore ordinato',
                key: 'value_ordered',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_ordered_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_ordered_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_ordered - b.value_ordered,
            },
            {
                title: 'Valore sconosciuto',
                key: 'value_unknown',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_unknown_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_unknown_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_unknown - b.value_unknown,
            },
            {
                title: 'Valore ordinabile',
                key: 'value_procurable',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_procurable_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_procurable_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_procurable - b.value_procurable,
            },
            {
                title: 'Valore confermato',
                key: 'value_accepted',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_accepted_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_accepted_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_accepted - b.value_accepted,
            },
            {
                title: 'Prodotti confermati (%)',
                key: 'products_accepted_percentage',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.products_accepted_percentage_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.products_accepted_percentage_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.products_accepted_percentage - b.products_accepted_percentage,
            },
            {
                title: 'Valore confermati (%)',
                key: 'value_accepted_percentage',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_accepted_percentage_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_accepted_percentage_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_accepted_percentage - b.value_accepted_percentage,
            },
            {
                title: 'Prodotti sconosciuti (%)',
                key: 'products_unknown_percentage',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.products_unknown_percentage_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.products_unknown_percentage_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.products_unknown_percentage - b.products_unknown_percentage,
            },
            {
                title: 'Valore sconosciuto (%)',
                key: 'value_unknown_percentage',
                render: (record) => (
                    <Space direction='vertical' size='small'>
                        <Text strong>{record.stats.value_unknown_percentage_format}</Text>
                        {
                            !_.isNil(record.compare_stats) ? <Text>{record.compare_stats.value_unknown_percentage_format}</Text> : null
                        }
                    </Space>
                ),
                sorter: (a, b) => a.value_unknown_percentage - b.value_unknown_percentage,
            },
        ]
        const brandStats = [
            {
                title: 'Brand',
                key: 'brand',
                width: 100,
                align: 'left',
                render: (record) => {
                    return (
                        record.brand.name
                    )
                },
            },
            {
                title: 'Prodotti ordinati',
                key: 'products_ordered',
                render: (record) => (
                    record.stats.products_ordered
                ),
                sorter: (a, b) => a.stats.products_ordered - b.stats.products_ordered,
            },
            {
                title: 'Prodotti confermati',
                key: 'products_accepted',
                render: (record) => (
                    record.stats.products_accepted
                ),
                sorter: (a, b) => a.stats.products_accepted - b.stats.products_accepted,
            },
            {
                title: 'Valore ordinato',
                key: 'value_ordered',
                render: (record) => (
                    record.stats.value_ordered_format
                ),
                sorter: (a, b) => a.stats.value_ordered - b.stats.value_ordered,
            },
            {
                title: 'Valore confermato',
                key: 'value_accepted',
                render: (record) => (
                    record.stats.value_accepted_format
                ),
                sorter: (a, b) => a.stats.value_accepted - b.stats.value_accepted,
            },
            {
                title: 'Prodotti confermati (%)',
                key: 'products_accepted_percentage',
                render: (record) => (
                    record.stats.products_accepted_percentage_format
                ),
                sorter: (a, b) => a.stats.products_accepted_percentage - b.stats.products_accepted_percentage,
            },
            {
                title: 'Valore confermato (%)',
                key: 'value_accepted_percentage',
                render: (record) => (
                    record.stats.value_accepted_percentage_format
                ),
                sorter: (a, b) => a.stats.value_accepted_percentage - b.stats.value_accepted_percentage,
            },
        ]
        const orderStatsChartByCountry = [
            {
                title: 'Nazione',
                key: 'chart_country',
                width: 40,
                align: 'center',
                onCell: (record) => {
                    return {
                        className: 'background-' + record.country.code.toLowerCase()
                    }
                },
                render: (record) => {
                    return (
                        <Avatar size='small' shape='square' src={flagPath + record.country.code + '_32.png'} />
                    )
                },
            },
            {
                title: 'Ordini confermati',
                key: 'chart_orders_accepted',
                width: 350,
                render: (record) => (
                    <ReactApexChart
                        options={
                            {
                                ...barChartOptions, title: {
                                    text: 'Ordini',
                                }, xaxis: {
                                    ...barChartOptions.xaxis,
                                    categories: ['Confermati']
                                }
                            }}
                        series={[
                            {
                                name: moment(this.props.startDate).format('DD/MM/YYYY') + '<br />' + moment(this.props.endDate).format('DD/MM/YYYY'),
                                data: [record.stats.orders_accepted]
                                // data: [123456000]
                            }, {
                                name: this.state.compareDates[0] + '<br />' + this.state.compareDates[1],
                                data: [record.compare_stats.orders_accepted]
                                // data: [123456789]
                            }
                        ]} type='bar' height={150} width={300}
                    />
                ),
            },
            {
                title: 'Prodotti ordinati',
                key: 'chart_products_ordered',
                width: 350,
                render: (record) => (
                    <ReactApexChart
                        options={
                            {
                                ...barChartOptions, title: {
                                    text: 'Prodotti ordinati',
                                }, xaxis: {
                                    ...barChartOptions.xaxis,
                                    categories: ['Ordinati', 'Sconosciuti', 'Accettati'],
                                }
                            }}
                        series={[
                            {
                                name: moment(this.props.startDate).format('DD/MM/YYYY') + '<br />' + moment(this.props.endDate).format('DD/MM/YYYY'),
                                data: [record.stats.products_ordered, record.stats.products_unknown, record.stats.products_accepted]
                            }, {
                                name: this.state.compareDates[0] + '<br />' + this.state.compareDates[1],
                                data: [record.compare_stats.products_ordered, record.compare_stats.products_unknown, record.compare_stats.products_accepted]
                            }
                        ]} type='bar' height={250} width={300}
                    />
                ),
            },
            {
                title: 'Valore prodotti',
                key: 'chart_products_value',
                width: 350,
                render: (record) => (
                    <ReactApexChart
                        options={
                            {
                                ...barChartOptions, title: {
                                    text: 'Valore prodotti',
                                }, xaxis: {
                                    ...barChartOptions.xaxis,
                                    categories: ['Ordinati', 'Sconosciuti', 'Ordinabili', 'Accettati'],
                                }
                            }}
                        series={[
                            {
                                name: moment(this.props.startDate).format('DD/MM/YYYY') + '<br />' + moment(this.props.endDate).format('DD/MM/YYYY'),
                                data: [record.stats.value_ordered, record.stats.value_unknown, record.stats.value_procurable, record.stats.value_accepted]
                            }, {
                                name: this.state.compareDates[0] + '<br />' + this.state.compareDates[1],
                                data: [record.compare_stats.value_ordered, record.compare_stats.value_unknown, record.compare_stats.value_procurable, record.compare_stats.value_accepted]
                            }
                        ]} type='bar' height={280} width={300}
                    />
                ),
            },
            {
                title: 'Percentuali',
                key: 'chart_products_percentage',
                width: 500,
                render: (record) => ([
                    <Space key='perc_space' direction='horizontal' size='small'>
                        <ReactApexChart
                            key='confirmed_products_perc'
                            options={
                                {
                                    ...radialBarChartOptions,
                                    title: {
                                        text: 'Prodotti confermati (%)',
                                    },
                                    labels: [moment(this.props.startDate).format('DD/MM/YYYY') + ' - ' + moment(this.props.endDate).format('DD/MM/YYYY'), this.state.compareDates[0] + ' - ' + this.state.compareDates[1]],
                                }
                            }
                            series={[record.stats.products_accepted_percentage, record.compare_stats.products_accepted_percentage]}
                            // series={[25.654648979, 70.1326498]}
                            type='radialBar' height={250} width={250}
                        />
                        <ReactApexChart
                            key='confirmed_value_perc'
                            options={
                                {
                                    ...radialBarChartOptions,
                                    title: {
                                        text: 'Valore confermato (%)',
                                    },
                                    labels: [moment(this.props.startDate).format('DD/MM/YYYY') + ' - ' + moment(this.props.endDate).format('DD/MM/YYYY'), this.state.compareDates[0] + ' - ' + this.state.compareDates[1]],
                                }
                            }
                            series={[record.stats.value_accepted_percentage, record.compare_stats.value_accepted_percentage]}
                            type='radialBar' height={250} width={250}
                        />
                    </Space>
                ]),
            },
        ]

        return (
            <Card size='small'
                // title={
                //     <Space direction='vertical' size='small' >
                //         <Title level={5}>
                //             {
                //                 !_.isNil(this.props.vendorCodeGroup) ? (
                //                     'Account ' + languageManager.getMessage(this.props.commonState, 'component.vendor_codes.group.' + this.props.vendorCodeGroup)
                //                 ) : 'Totale'
                //                 + ' ' + languageManager.getMessage(this.props.commonState, 'component.vendor_codes.type.' + this.props.vendorCodeType)
                //             }
                //         </Title>
                //     </Space >
                // }
                bordered={false}
            >
                <Space direction='vertical' size='small'>
                    {
                        this.state.showCharts ? (
                            <Table {...tableChartsLayout} columns={orderStatsChartByCountry} dataSource={this.state.stats} />
                        ) : null
                    }
                    <Row justify='end'>
                        <Col>
                            <Button
                                type='primary'
                                onClick={() => this.exportData()}
                                loading={this.state.loaderExportData}
                                disabled={this.state.componentLoader}
                            >
                                Esporta CSV
                            </Button>
                        </Col>
                    </Row>
                    <Table {...tableLayout}
                        columns={orderStatsByCountry}
                        dataSource={this.state.stats}
                        expandable={{
                            indentSize: 30,
                            expandedRowKeys: this.state.expandedRowKeys,
                            onExpand: this.onExpandChange,
                            expandedRowRender: record => (
                                <Table {...tableBrandStatsLayout}
                                    columns={brandStats}
                                    dataSource={!_.isEmpty(this.state.brandStats) && !_.isUndefined(this.state.brandStats.find(x => x.country.id === record.country.id)) ? this.state.brandStats.find(x => x.country.id === record.country.id).stats : []}
                                />
                            )
                        }}
                    />
                </Space>
            </Card >
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(OrdersStatsCard)