import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import _ from 'lodash'

import languageManager from '../../services/languageManager'

import { Menu } from 'antd'

// OLD SIDEBAR
// const SidebarContent = props => {
//    return (
//       <div id="sidebar-menu">
//          <ul className="metismenu list-unstyled" id="side-menu">
//             <li className="menu-title">Main</li>

//             <li>
//                <Link to="/dashboard" className="waves-effect">
//                   <i className="ti-home"></i>
//                   {/* <span className="badge badge-pill badge-primary float-right">
//                      2
//                   </span> */}
//                   <span>Dashboard</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.purchase_orders')}</li>
//             <li>
//                <Link to="/purchase_orders/list" className="waves-effect">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/purchase_orders/list/pending" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders.pending')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/purchase_orders/list/accepted" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders.accepted')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/purchase_orders/list/accepted" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders.updates')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/purchase_orders/list/accepted" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders.anomalies')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.catalogue')}</li>
//             <li>
//                <Link to="/brands/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.brands')}</span>
//                </Link>
//             </li>
//             {/* <li>
//                <Link to="/catalogues/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.catalogues')}</span>
//                </Link>
//             </li> */}
//             <li>
//                <Link to="/categories/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.categories')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect menu-indent">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.in_catalog')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect menu-indent">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.create_new')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect menu-indent">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.differences_ec_amz')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list_missing_parity" className="waves-effect menu-indent-level2">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.missing_parity')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list_no_vc" className="waves-effect menu-indent-level2">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.not_existing_vc')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect menu-indent-level2">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.data_differences')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list" className="waves-effect menu-indent-level2">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.products.anomalies')}</span>
//                </Link>
//             </li>
//             {/* <li>
//                <Link to="/products/list/pending" className="waves-effect menu-indent">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.pending_products')}</span>
//                </Link>
//             </li> */}
//             <li>
//                <Link to="/products/list_found_asins" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.found_asins')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/products/list_no_asin" className="waves-effect menu-indent">
//                   {/* <i className="ti-home"></i> */}
//                   <span>{languageManager.getMessage(props.commonState, 'nav.missing_asin')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/search_products" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.search_products')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/model_maps/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.model_maps')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/specifics/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.specifics')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/variations/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.variations')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.closing_po')}</li>
//             <li>
//                <Link to="/test/import_file" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.import_file')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/closing_orders/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.closing_po')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.chargebacks')}</li>
//             <li>
//                <Link to="/chargebacks/link" className="waves-effect">
//                   <i className="ti-money"></i>
//                   <span>{languageManager.getMessage(props.commonState, 'nav.chargebacks')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.data_processing')}</li>
//             <li>
//                <Link to="/dataprocessing/group_sizes" className="waves-effect">
//                   <i className="ti-list-ol"></i>
//                   <span>{languageManager.getMessage(props.commonState, 'nav.group_sizes')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/dataprocessing/search_asins" className="waves-effect">
//                   <i className="ti-search"></i>
//                   <span>{languageManager.getMessage(props.commonState, 'nav.search_asins')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.configuration')}</li>
//             <li>
//                <Link to="/marketplaces/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.marketplaces')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/vendor_codes/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.vendor_codes')}</span>
//                </Link>
//             </li>
//             <li>
//                <Link to="/taxes/taxrates/list" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.taxes')}</span>
//                </Link>
//             </li>

//             <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.analysis')}</li>
//             <li>
//                <Link to="/analysis/categories" className="waves-effect">
//                   <span>{languageManager.getMessage(props.commonState, 'nav.categories')}</span>
//                </Link>
//             </li>

//             {/* <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.test')}</li>
//             <li>
//                <Link to="/marketplaces/list" className="waves-effect">
//                   <i className="ti-home"></i>
//                   <span>{languageManager.getMessage(props.commonState, 'nav.marketplaces')}</span>
//                </Link>
//             </li> */}
//          </ul>
//       </div>
//    );
// };

class Sidebar extends Component {
   state = {
      ...this.props,
      menuItems: [],
      openKeys: undefined,
      selectedKeys: undefined,
   }

   componentDidMount() {
      Promise.all([
         this.prepareMenuItems()
      ]).then(() => {
         this.initMenu()
      })
   }

   prepareMenuItems = () => {
      let menu = []

      // let main = []
      menu.push({
         key: 'dashboard_orders',
         label: <Link to="/dashboard">{languageManager.getMessage(this.props.commonState, 'nav.dashboard.purchase_orders')}</Link>,
         // label: languageManager.getMessage(this.props.commonState, 'nav.dashboard.purchase_orders'),
         path: '/dashboard',
      })
      menu.push({
         key: 'dashboard_products',
         label: <Link to="/dashboard_products">{languageManager.getMessage(this.props.commonState, 'nav.dashboard.products')}</Link>,
         path: '/dashboard_products',
         disabled: true
      })
      // menu.push({ key: 'main', label: 'Main', children: main })
      
      let catalog = []
      catalog.push({
         key: 'categories',
         label: <Link to="/categories/list">{languageManager.getMessage(this.props.commonState, 'nav.categories')}</Link>,
         path: '/categories',
         parent: 'catalog',
      })
      catalog.push({
         key: 'products',
         label: <Link to="/products/list">{languageManager.getMessage(this.props.commonState, 'nav.products.in_catalog')}</Link>,
         path: '/products',
         parent: 'catalog',
      })
      catalog.push({
         key: 'search_products',
         label: <Link to="/search_products">{languageManager.getMessage(this.props.commonState, 'nav.search_products')}</Link>,
         path: '/search_products',
         parent: 'catalog',
      })
      menu.push({ key: 'catalog', label: 'Gestione Catalogo', children: catalog })
      
      let warehouses = []
      warehouses.push({
         key: 'stock_warehouses',
         label: <Link to="/warehouses/list">{languageManager.getMessage(this.props.commonState, 'nav.stock_warehouses')}</Link>,
         path: '/warehouses',
         parent: 'warehouses',
      })
      warehouses.push({
         key: 'stock_suppliers',
         label: <Link to="/suppliers/list">{languageManager.getMessage(this.props.commonState, 'nav.stock_suppliers')}</Link>,
         path: '/suppliers',
         parent: 'warehouses',
      })
      menu.push({ key: 'warehouses', label: 'Magazzini/Fornitori', children: warehouses })
      
      let purchaseOrders = []
      purchaseOrders.push({
         key: 'all_orders',
         label: <Link to="/purchase_orders/list">{languageManager.getMessage(this.props.commonState, 'nav.purchase_orders.all')}</Link>,
         path: '/purchase_orders/list',
         parent: 'purchase_orders',
      })
      purchaseOrders.push({
         key: 'pending_orders',
         label: <Link to="/purchase_orders/pending_list/pending">{languageManager.getMessage(this.props.commonState, 'nav.purchase_orders.pending')}</Link>,
         path: '/purchase_orders/pending_list/pending',
         parent: 'purchase_orders',
      })
      purchaseOrders.push({
         key: 'closing_orders',
         label: <Link to="/purchase_orders/manual_updates/list">{languageManager.getMessage(this.props.commonState, 'nav.closing_po')}</Link>,
         path: '/purchase_orders/manual_updates/list',
         parent: 'purchase_orders',
      })
      // purchaseOrders.push({
      //    key: 'import_file',
      //    label: <Link to="/test/import_file">{languageManager.getMessage(this.props.commonState, 'nav.import_file')}</Link>,
      //    path: '/test/import_file',
      //    parent: 'purchase_orders',
      // })
      menu.push({ key: 'purchase_orders', label: 'Gestione PO', children: purchaseOrders })

      let operations = []
      operations.push({
         key: 'progressives',
         label: <Link to='/operations/progressives/list'>{languageManager.getMessage(this.props.commonState, 'nav.operations.progressives')}</Link>,
         path: '/operations/progressives',
         parent: 'operations',
      })
      operations.push({
         key: 'supplier_confirmations',
         label: <Link to='/operations/supplier_confirmations/list'>{languageManager.getMessage(this.props.commonState, 'nav.operations.supplier_confirmations')}</Link>,
         path: '/operations/supplier_confirmations',
         parent: 'operations',
      })
      // operations.push({
      //    key: 'unique_files',
      //    label: <Link to='/operations/unique_files/list'>{languageManager.getMessage(this.props.commonState, 'nav.operations.unique_files')}</Link>,
      //    path: '/operations/unique_files',
      //    parent: 'operations',
      // })
      // operations.push({
      //    key: 'products',
      //    label: <Link to='/operations/marketplaces/list'>{languageManager.getMessage(this.props.commonState, 'nav.marketplaces')}</Link>,
      //    path: '/operations/list',
      //    parent: 'operations',
      // })
      menu.push({ key: 'operations', label: languageManager.getMessage(this.props.commonState, 'nav.operations'), children: operations })

      let uniqueFiles = []
      uniqueFiles.push({
         key: 'unique_files_ria',
         label: <Link to='/unique_files/ria/list'>{languageManager.getMessage(this.props.commonState, 'nav.unique_files.ria')}</Link>,
         path: '/unique_files/ria',
         parent: 'unique_files',
      })
      uniqueFiles.push({
         key: 'unique_files_ria_products',
         label: <Link to='/unique_files/ria_products/list'>{languageManager.getMessage(this.props.commonState, 'nav.unique_files.ria_products')}</Link>,
         path: '/unique_files/ria_products',
         parent: 'unique_files',
         disabled: true
      })
      uniqueFiles.push({
         key: 'unique_files_seasonal',
         label: <Link to='/unique_files/seasonal/list'>{languageManager.getMessage(this.props.commonState, 'nav.unique_files.seasonal')}</Link>,
         path: '/unique_files/seasonal',
         parent: 'unique_files',
         // disabled: true
      })
      uniqueFiles.push({
         key: 'unique_files_seasonal_products',
         label: <Link to='/unique_files/seasonal_products/list'>{languageManager.getMessage(this.props.commonState, 'nav.unique_files.seasonal_products')}</Link>,
         path: '/unique_files/seasonal_products',
         parent: 'unique_files',
         disabled: true
      })
      // operations.push({
      //    key: 'products',
      //    label: <Link to='/operations/marketplaces/list'>{languageManager.getMessage(this.props.commonState, 'nav.marketplaces')}</Link>,
      //    path: '/operations/list',
      //    parent: 'operations',
      // })
      menu.push({ key: 'unique_files', label: languageManager.getMessage(this.props.commonState, 'nav.unique_files'), children: uniqueFiles })

      let analysis = []
      analysis.push({
         key: 'group_sized',
         label: <Link to='/dataprocessing/group_sizes'>{languageManager.getMessage(this.props.commonState, 'nav.group_sizes')}</Link>,
         path: '/dataprocessing/group_sizes',
         parent: 'analysis',
      })
      analysis.push({
         key: 'ean_asin',
         label: <Link to='/analysis/ean_asin'>{languageManager.getMessage(this.props.commonState, 'nav.analysis.ean_asin')}</Link>,
         path: '/analysis/ean_asin',
         parent: 'analysis',
      })
      analysis.push({
         key: 'asin_availability',
         label: <Link to='/analysis/asin_availability'>{languageManager.getMessage(this.props.commonState, 'nav.analysis.asin_availability')}</Link>,
         path: '/analysis/asin_availability',
         parent: 'analysis',
      })
      menu.push({ key: 'analysis', label: 'Analisi', children: analysis })

      let configuration = []
      configuration.push({
         key: 'marketplaces',
         label: <Link to='/configuration/marketplaces/list'>{languageManager.getMessage(this.props.commonState, 'nav.marketplaces')}</Link>,
         path: '/configuration/marketplaces',
         parent: 'configuration',
      })
      configuration.push({
         key: 'vendor_codes',
         label: <Link to='/configuration/vendor_codes/list'>{languageManager.getMessage(this.props.commonState, 'nav.vendor_codes')}</Link>,
         path: '/configuration/vendor_codes',
         parent: 'configuration',
      })
      menu.push({ key: 'configuration', label: 'Configurazione', children: configuration })

      return this.setState({ menuItems: menu })
   }

   initMenu() {
      // console.log(this.props.location.pathname, this.props.location.pathname.split('/'))
      // const parentDropdown = this.props.location.pathname.split('/')[1]
      // const selectedItem = this.state.menuItems.find(x => x.path == this.props.location.pathname)
      const selectedItem = searchKeyFromPath(this.props.location.pathname, this.state.menuItems)
      // console.log('INIT MENU', this.props.location.pathname, selectedItem)
      let selectedKeys = []
      let parentDropdown = []
      if(selectedItem) {
         selectedKeys.push(selectedItem.key)
         parentDropdown.push(selectedItem.parent)
      }
      // console.log('SELECTED', selectedKeys)
      this.setState({ openKeys: parentDropdown, selectedKeys: selectedKeys })
   }

   onOpenChange = (openKeys) => {
      this.setState({ openKeys: openKeys })
   }
   // onMenuClick = (item) => {
   //    console.log(item, item.key)
   //    let menuItem = searchPathFromKey(item.key, this.state.menuItems)
   //    console.log(menuItem)
   //    if (!_.isNil(menuItem)) {
   //       this.props.history.push(menuItem.path)
   //    }
   // }

   render() {
      if (_.isUndefined(this.state.openKeys) || _.isUndefined(this.state.selectedKeys)) return null
      // console.log('RENDER', this.state.selectedKeys, this.state.openKeys)
      return (
         <React.Fragment>
            <Menu
               // defaultSelectedKeys={['dashboard_ordini']}
               selectedKeys={this.state.selectedKeys}
               openKeys={this.state.openKeys}
               onOpenChange={this.onOpenChange}
               // onClick={this.onMenuClick} 
               mode="inline"
               theme="dark"
               // inlineCollapsed={collapsed}
               items={this.state.menuItems}
               style={{ height: '100%' }}
            />
         </React.Fragment>
      );
   }
}

function searchPathFromKey(key, array) {
   var result
   array.some(o => (o.key === key && (result = o)) || (result = searchPathFromKey(key, o.children || [])))
   return result
}

function searchKeyFromPath(path, array) {
   var result
   array.some(o => (!_.isNil(o.path) && path.includes(o.path) && (result = o)) || (result = searchKeyFromPath(path, o.children || [])))
   return result
}

export default withRouter(Sidebar);
