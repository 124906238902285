import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import languageManager from '../../../services/languageManager';

import { Select, DatePicker, Row, Col, Form, Button, Input } from 'antd';


const TableFilters = (props) => {
   console.log(props)
   const [filters, setFilters] = useState(props.filters)
   const [form] = Form.useForm()
   const { Option } = Select;
   const { RangePicker } = DatePicker;

   useEffect(() => {
      setFilters(props.filters)
   }, [props.filters]);

   const submitFilters = (values) => {
      // console.log(values)
      props.submitFilters(values)
   }
   const resetFilters = () => {
      var nullValues = {}
      _.forEach(filters, (el) => {
         if (el.type === 'select' && el.mode === 'multiple') {
            nullValues[el.key] = []
         } else {
            nullValues[el.key] = null
         }
      })
      form.setFieldsValue(nullValues)
      props.resetFilters()
   }

   return (
      <Form
         form={form}
         name="filters"
         layout="horizontal"
         onFinish={submitFilters}
         onReset={resetFilters}
      >
         <Row gutter={24}>
            {
               filters.map((filter) => {
                  let ret
                  switch (filter.type) {
                     case 'string':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <Input allowClear={true} />
                              </Form.Item>
                           </Col>
                        break;
                     case 'range':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <RangePicker
                                    format="DD/MM/YYYY"
                                    allowClear={true}
                                    allowEmpty={[true, true]}
                                 />
                              </Form.Item>
                           </Col>
                        break;
                     case 'date':
                     case 'date_utc':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <DatePicker
                                    format="DD/MM/YYYY"
                                    allowClear={true}
                                 />
                              </Form.Item>
                           </Col>
                        break;
                     case 'datetime':
                     case 'datetime_utc':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <DatePicker
                                    format="DD/MM/YYYY HH:mm"
                                    showTime={true}
                                    allowClear={true}
                                 />
                              </Form.Item>
                           </Col>
                        break;
                     case 'date_year':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <DatePicker
                                    picker='year'
                                    format='YYYY'
                                    allowClear={true}
                                 />
                              </Form.Item>
                           </Col>
                        break;
                     case 'date_week':
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={filter.value}>
                                 <DatePicker
                                    picker='week'
                                    format='ww'
                                    allowClear={true}
                                 />
                              </Form.Item>
                           </Col>
                        break;
                     case 'select':
                        var initialValue
                        if (filter.mode !== 'multiple') {
                           initialValue = filter.value
                        } else {
                           initialValue = _.isNil(filter.value) ? [] : filter.value
                        }
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={initialValue}>
                                 <Select
                                    allowClear={true}
                                    mode={filter.mode}
                                    filterOption={(input, option) =>
                                       option.props.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                 >
                                    {
                                       filter.options.map((option) => {
                                          return <Option key={option.t_name} value={option.id} searchValue={option.t_name}>{option.t_name}</Option>
                                       })
                                    }
                                 </Select>
                              </Form.Item>
                           </Col>
                        break;
                     case 'select_key':
                        var initialValue
                        if (filter.mode !== 'multiple') {
                           initialValue = filter.value
                        } else {
                           initialValue = _.isNil(filter.value) ? [] : filter.value
                        }
                        ret =
                           <Col span={6} key={filter.key}>
                              <Form.Item name={filter.key} label={languageManager.getMessage(props.commonState, 'component.filter.' + filter.label)} initialValue={initialValue}>
                                 <Select
                                    allowClear={true}
                                    mode={filter.mode}
                                    filterOption={(input, option) =>
                                       option.props.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                 >
                                    {
                                       filter.options.map((option) => {
                                          return <Option key={option.t_name} value={option.key} searchValue={option.t_name}>{option.t_name}</Option>
                                       })
                                    }
                                 </Select>
                              </Form.Item>
                           </Col>
                        break;

                  }
                  return ret
               })
            }
         </Row>
         <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
               <Button type="primary" htmlType="submit">
                  {languageManager.getMessage(props.commonState, 'common.search')}
               </Button>
               <Button
                  style={{ margin: '0 8px' }}
                  htmlType="reset"
               >
                  {languageManager.getMessage(props.commonState, 'common.reset_filters')}
               </Button>
            </Col>
         </Row>
      </Form>

   );
};

const mapStateToProps = state => {
   return {
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(TableFilters);