import AsinAvailabilityApi from '../../../../../api/AsinAvailabilityApi';
import _ from 'lodash';

export function getComplements() {
   return AsinAvailabilityApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function getModel() {
   return AsinAvailabilityApi.getModel()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function analyzeFile(formData, filename) {
   return AsinAvailabilityApi.analyzeFile(formData, filename)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}