import AnalysisEanAsinApi from '../../../../../api/AnalysisEanAsinApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList() {
   return AnalysisEanAsinApi.getList()
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getListExtended(params = [], filterValues = {}) {
   return AnalysisEanAsinApi.getListExtended(params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getComplements() {
   return AnalysisEanAsinApi.getComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function getModel() {
   return AnalysisEanAsinApi.getModel()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function getItem(itemId, params = [], filterValues = {}) {
   return AnalysisEanAsinApi.getItem(itemId, params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return AnalysisEanAsinApi.createOrUpdate(itemData)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function cancelItems(itemIds, return_list = false) {
   return AnalysisEanAsinApi.cancelItems(itemIds, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function deleteItems(itemIds, return_list = false) {
   return AnalysisEanAsinApi.deleteItems(itemIds, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function addToAnalysisQueue(itemId) {
   return AnalysisEanAsinApi.addToAnalysisQueue(itemId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function importFromFile(formData) {
   return AnalysisEanAsinApi.importFromFile(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function exportCodeAnalysisResults(filename, itemIds) {
   return AnalysisEanAsinApi.exportCodeAnalysisResults(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
export function exportAsinAnalysisResults(filename, itemIds) {
   return AnalysisEanAsinApi.exportAsinAnalysisResults(filename, itemIds)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}
