import _ from 'lodash'

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const startLoading = (oldObject, itemIds) => {
    let newObject = { ...oldObject }
    if (Array.isArray(itemIds)) {
        itemIds.map((itemId) => {
            newObject[itemId] = true
        })
    } else {
        newObject[itemIds] = true
    }
    return newObject
};

export const endLoading = (oldObject, itemIds) => {
    let newObject = { ...oldObject }
    if (Array.isArray(itemIds)) {
        itemIds.map((itemId) => {
            newObject[itemId] = false
        })
    } else {
        newObject[itemIds] = false
    }
    return newObject
}

export const download = (filePath, fileName) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
}

export const containsObject = (obj, array, key) => {
    var i;
    for (i = 0; i < array.length; i++) {
        if (array[i][key] === obj[key]) {
            return true;
        }
    }
    return false;
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const formatNumber = (val, precision = 2) => {
    if (_.isNil(val)) return 0.00
    let newValue
    let suffix
    if (val < 900) {
        // 0 - 900
        newValue = val.toFixed(precision);
        suffix = ''
    } else if (val < 900000) {
        // 0.9k-850k
        newValue = (val / 1000).toFixed(precision)
        suffix = 'K'
    } else if (val < 900000000) {
        // 0.9m-850m
        newValue = (val / 1000000).toFixed(precision)
        suffix = 'M'
    } else if (val < 900000000000) {
        // 0.9b-850b
        newValue = (val / 1000000000).toFixed(precision)
        suffix = 'B'
    } else {
        // 0.9t+
        newValue = (val / 1000000000000).toFixed(precision)
        suffix = 'T'
    }

    return newValue + suffix;
}
