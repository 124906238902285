import React, {Component} from 'react';
// css
import './styles/shared.css';
import 'antd/dist/antd.css';
import './styles/override/antd.css';
import './styles/site.css';
import './App.css';
// Import scss
import './assets/scss/theme.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import SplashScreen from './components/hoc/SplashScreen';
import ErrorBoundary from './components/error/ErrorBoundary';

import moment from 'moment';
import 'moment/locale/it';
import it_IT from 'antd/lib/locale-provider/it_IT';
import { ConfigProvider } from 'antd';

import * as commonAction from './store/actions/commonAction';
import * as authenticationAction from './store/actions/authenticationAction';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

// layouts
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

moment.locale('it-IT');

class App extends Component {
   constructor(props, context) {
      super(props, context);

      this.state = {
         isLoading: true
      };
   }

   componentDidMount() {
      this.props.loadAllStore();
      this.props.authCheckState();
   }

   render() {

      // const adminPage = <AdminPage history={null} commonState={this.props.commonState} authenticationState={this.props.authenticationState} />;
      const history = createBrowserHistory();

      return (

         this.props.commonState.blocking ? ' ' :

            <ErrorBoundary commonState={this.props.commonState}>
               <ConfigProvider locale={it_IT}>
                  <SplashScreen commonState={this.props.commonState}>
                     {/* <div className="App">
                                <Header
                                    commonState={this.props.commonState}
                                    authenticationState={this.props.authenticationState}
                                /> */}
                     <Router history={history}>

                        <Switch>
                           {publicRoutes.map((route, index) => (
                              <AppRoute
                                 path={route.path}
                                 layout={NonAuthLayout}
                                 component={route.component}
                                 key={index}
                                 isAuthProtected={false}
                                 authenticationState={this.props.authenticationState}
                              />
                           ))}

                           {authProtectedRoutes.map((route, index) => (
                              <AppRoute
                                 path={route.path}
                                 layout={VerticalLayout}
                                 component={route.component}
                                 key={index}
                                 isAuthProtected={true}
                                 authenticationState={this.props.authenticationState}
                              />
                           ))}
                        </Switch>

                     </Router>
                     
                  </SplashScreen>
               </ConfigProvider>
            </ErrorBoundary>
      );
   }
}

App.contextTypes = {
   router: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
   return {
      authenticationState: state.authenticationState,
      commonState: state.commonState
   };
};

const mapDispatchToProps = dispatch => {
   return {
      // commonAction: bindActionCreators(commonAction, dispatch),
      // authenticationAction: bindActionCreators(authenticationAction, dispatch),
      loadAllStore: () => dispatch(commonAction.loadAllStore()),
      authCheckState: () => dispatch(authenticationAction.authCheckState())
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
