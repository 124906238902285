import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';
import { PurchaseOrderTypeEnum } from '../shared/enums';

class PurchaseOrdersApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList(year) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getList, {
            year: year
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(params, filterValues) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getListExtended, { ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   static getListForProgressives(progressiveType, fullList, selectedIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getListForProgressives, {
            progressive_type: progressiveType,
            full_list: fullList,
            purchase_orders_ids: selectedIds
         })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId, params, filterValues) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleValue(itemData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.toggleValue, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getPurchaseOrdersComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getYearList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getActiveList),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((yearsList, marketplacesList) => {
               const response = {
                  yearsList: yearsList,
                  marketplacesList: marketplacesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getOrderComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.order_statuses.getList),
         ])
            .then(Axios.spread((statusesList) => {
               const response = {
                  statusesList: statusesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importPOList(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.importFromCsv, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importEdiFromAmazon() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.importEdiFromAmazon)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static importNewFromApi() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.importNewFromApi)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importPOLines(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_order_lines.importFromCsv, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getExpiringList(days) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getExpiringList, {
            days: days
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getExpiringOrdersComplements() {
      // return new Promise((resolve, reject) => {
      //    Axios.all([
      //       Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getYearList),
      //    ])
      //       // .then(responseArr => {
      //       //    console.log(responseArr);
      //       //    resolve(responseArr);
      //       // })
      //       .then(Axios.spread((yearsList) => {
      //          const response = {
      //             yearsList: yearsList,
      //          }
      //          resolve(response);
      //       }))
      //       .catch(error => {
      //          reject(error);
      //       });
      // });
      return {};
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getExpiringItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.getExpiringItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportOrders(filename, purchaseOrderIds) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.exportList, { filename: filename, order_ids: purchaseOrderIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static exportForRIA(filename, orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.exportOrdersForRIA, {
            filename: filename,
            order_ids: orderIds
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            });
      });
   }
   static exportForPOPivot(filename, orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.exportForPOPivot, {
            filename: filename,
            order_ids: orderIds
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            });
      });
   }
   static exportForRIAWeeklyPivot(filename, purchaseOrderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.exportForRIAWeeklyPivot, { filename: filename, order_ids: purchaseOrderIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createProgressives(progressiveType, progressiveCountry, progressiveGroup, vendorcodeId, fcCodeId, selectedIds, filename) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.createProgressives, {
            progressive_type: progressiveType,
            progressive_country: progressiveCountry,
            progressive_group: progressiveGroup,
            vendor_code_id: vendorcodeId,
            amazon_warehouse_id: fcCodeId,
            purchase_orders_ids: selectedIds,
            filename: filename,
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            })
      })
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static checkWaitingStatus(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.checkWaitingStatus, {
            order_ids: orderIds,
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static updateOrderStatus(orderIds, newStatus) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.updateOrderStatus, {
            order_ids: orderIds,
            new_status: newStatus,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static enqueueDownloadOrders(orderIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchase_orders.enqueueDownloadOrders, {
            order_ids: orderIds,
            debug: true,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }



}

export default PurchaseOrdersApi;