import fetchFunctions from '../services/fetchFunctions'
import API_BASE from './apiBase'
import API_CONFIG from './apiConfig'
import Axios from 'axios'
import _ from 'lodash'
import { isConstructorDeclaration } from 'typescript'

class commonApi {


    static loadLanguage(language) {
        return fetchFunctions.get('/languages/' + language + '-lang.json');
    }

    static loadProductLanguage(pcode, language) {
        return fetchFunctions.get('./products/' + pcode + '/languages/' + language + '-lang.json');
    }

    static loadProductConfiguration(pcode) {
        return fetchFunctions.get('./products/' + pcode + '/config.json');
    }

    static getCountries() {
        const data = [
            { name: 'Italia', iso: '086' }
        ];

        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(data), 500);
        });
    }


    static getProductStatus() {

        const data = [
            { label: 'Nuovo', value: '1' },
            { label: 'Usato', value: '2' }
        ];

        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(data), 500);
        });
    }

    static importExcel(data) {
        return new Promise((resolve, reject) => {
            let formData = new FormData;
            formData.append('file', data.file)
            formData.append('file_type', _.isNil(data.data) ? null : data.data.filetype)
            console.log(formData)
            Axios.post(API_BASE.url + API_CONFIG.appUrls.files.importExcel, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log(response)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
        });
    }
    static downloadFromS3(filePath) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.files.downloadFromS3, { file_path: filePath },
                {
                    responseType: 'blob'
                }
            )
                .then(response => {
                    console.log(response)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}

export default commonApi;