import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class SearchProductsApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static searchProductInCatalogue(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.searchProductInCatalogue, { ...formData })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static searchProductInCatalogueByMpn(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.searchProductInCatalogueByMpn, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static searchProductInCatalogueByTitle(title) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.searchProductInCatalogueByTitle, {
            title: title
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static lookupAmazonDataByMpnOrEanOrTitle(mpn, ean_upc, title) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.lookupAmazonDataByMpnOrEanOrTitle, {
            mpn: mpn,
            ean_upc: ean_upc,
            title: title
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static lookupAmazonDataByMpnOrAsinOrEanOrTitle(mpn, asin, ean_upc, title) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.lookupAmazonDataByMpnOrAsinOrEanOrTitle, {
            mpn: mpn,
            asin: asin,
            ean_upc: ean_upc,
            title: title
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getLookupResults(type, product_ids) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.getLookupResults, { type: type, product_ids: product_ids })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static searchFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.searchProducts.searchFromFile, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default SearchProductsApi;