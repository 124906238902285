import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

import Moment from 'moment';

import { Table, Select, Input, Button, Space, notification, Modal, Card, Collapse, Row, Col, Typography } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { DoubleLeftOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/closing_orders';

import TableFilters from '../../common/components/TableFilters';

import Loader from '../../common/PageElements/Loader'
import PageHeader from '../../common/PageElements/PageHeader'
import DropdownMenu from '../../common/components/DropdownMenu'

class ClosingOrdersList extends Component {
   state = {
      pageLoader: true,
      importBox: false,
      importLoader: true,
      loadersDelete: [],
      itemList: [],
      fullListIds: [],
      yearsList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      poPaginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [
         {
            action: 'delete_items',
            name: 'Cancella selezionati',
            needconfirmation: 'yes',
         },
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
      ],

      filters: [],
      filtersValues: {},
      appliedFilters: [],
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  poPaginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  fullListIds: response.full_list_ids,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.poPaginationParams, this.state.filtersValues)
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export_csv':
            this.setState({ multipleActionLoader: true })
            Promise.all([
               this.exportOrdersHandler()
            ]).then(() => {
               this.setState({ multipleActionLoader: false });
            });
            break
         case 'delete_items':
            this.setState({ multipleActionLoader: true })
            Promise.all([
               this.deleteItemsHandler(this.state.selectedRowKeys)
            ]).then(() => {
               this.setState({ multipleActionLoader: false });
            });
            break
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break

      }
   }

   exportOrdersHandler = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_export_orders.csv';
      console.log("Export orders", filename, this.state.selectedRowKeys);
      return storeActions.exportOrders(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ multipleExportLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemsHandler = (itemIds) => {
      this.setState({ loadersDelete: startLoading(this.state.loadersDelete, itemIds) })
      return storeActions.deleteItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })
               this.getList(this.state.poPaginationParams, this.state.filtersValues)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.deleteItemsHandler([itemId])
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
      }
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getList(this.state.poPaginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {} })
      this.getList(this.state.poPaginationParams, {});
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            title: 'Ordine',
            width: 150,
            key: 'purchase_order_number',
            fixed: 'left',
            sorter: (a, b) => a.purchase_order_number.localeCompare(b.purchase_order_number),
            render: (record) => (
               <Link
                  to={{
                     pathname: '/closing_orders/details/' + record.id,
                  }}
               >
                  {record.purchase_order_number}
               </Link>
            )
         },
         {
            title: 'Marketplace',
            width: 150,
            key: 'marketplace',
            fixed: 'left',
            render: (record) => (
               record.purchase_order.marketplace.name
            ),
            sorter: (a, b) => a.purchase_order.marketplace.name.localeCompare(b.purchase_order.marketplace.name),
         },
         {
            title: 'Ordinato il',
            width: 100,
            key: 'order_date',
            render: (record) => (
               Moment(record.purchase_order.order_date).format('L')
            ),
            sorter: (a, b) => a.purchase_order.order_date.localeCompare(b.purchase_order.order_date),
         },
         {
            title: 'Inizio finestra',
            width: 125,
            key: 'shipment_window_start',
            render: (record) => (
               Moment(record.purchase_order.shipment_window_start).format('L')
            ),
            sorter: (a, b) => a.purchase_order.shipment_window_start.localeCompare(b.purchase_order.shipment_window_start),
         },
         {
            title: 'Fine finestra',
            width: 125,
            key: 'shipment_window_end',
            render: (record) => (
               Moment(record.purchase_order.shipment_window_end).format('L')
            ),
            sorter: (a, b) => a.purchase_order.shipment_window_end.localeCompare(b.purchase_order.shipment_window_end),
         },
         {
            title: 'Prodotti da inviare',
            width: 200,
            dataIndex: 'order_lines_count',
            key: 'order_lines_count',
            sorter: (a, b) => a.order_lines_count - b.order_lines_count,
         },
         {
            title: 'Stato', width: 100, key: 'status',
            render: (record) => (
               record.order_status_name
            ),
            sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link
                     to={{
                        pathname: '/closing_orders/details/' + record.id,
                     }}
                  >
                     <Button type="primary" size='small'
                        icon={<EditOutlined />}
                        disabled={this.state.loadersDelete[record.id]}
                     />
                  </Link>
                  <Button type="danger" size='small'
                     icon={<DeleteOutlined />}
                     loading={this.state.loadersDelete[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  />
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         showSorterTooltip: false,
         size: "small",
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.poPaginationParams.per_page,
            total: this.state.poPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' ordini' : ' ordine')
         },
         onChange: this.handleTableChange
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const importResult = (
         <Card title="Card title">
            <Card type="inner" title="Importazione EDI files" extra={<a href="/#">Chiudi</a>}>
               Importazione EDI files in corso
            </Card>
         </Card>
      );

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const ordersTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         />
      )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <Row>
               <Col span={24}>{pageFilters}</Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 5 }}>
               <Col span={12}>
                  <Space>
                     <Button
                        onClick={() => this.selectAllHandler()}
                     >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                     </Button>
                     <span>
                        {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                     </span>
                     <DropdownMenu
                        type='primary'
                        title='Azioni multiple'
                        action={this.multipleActionHandler}
                        values={this.state.multipleActionList}
                        disabled={!hasSelected}
                        loading={this.state.multipleActionLoader}
                     />
                  </Space>
               </Col>
               <Col span={12}></Col>
            </Row>
            {ordersTable}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.closing_purchase_orders.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: "/test/import_file/" }} >
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                           >Importa ordini da CSV</Button>
                        </Link>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            {this.state.importBox ? importResult : ''}
            <div className="card-body">
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageWrapper}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(ClosingOrdersList);