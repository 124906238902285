import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Collapse, Select, Button, Space, notification, Descriptions, Row, Col, Typography } from 'antd'
import { InfoCircleOutlined, SearchOutlined, UploadOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/supplier_confirmations'
import * as linesActions from './store/actions/supplier_confirmation_lines'

import DropdownMenu from '../../common/components/DropdownMenu';
import TableFilters from '../../common/components/TableFilters';

import Loader from '../../common/PageElements/Loader'

class SupplierConfirmationDetails extends Component {

   state = {
      pageLoader: true,
      detailsLoader: false,
      tableLoader: false,
      confirmationDetails: {},
      confirmationLines: [],
      componentLanguage: this.props.authState.defaultLanguage,

      itemsPaginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },

      filters: [],
      filtersValues: {},
      appliedFilters: [],
   };

   componentDidMount() {
      const itemId = this.props.match.params.id
      Promise.all([
         this.getItem(itemId),
         this.getLines(itemId, this.state.itemsPaginationParams, this.state.filtersValues),
      ]).then(() => {
         this.setState({
            itemId: itemId,
            pageLoader: false
         });
      });
   }

   getItem = (id) => {
      this.setState({ detailsLoader: true })
      return storeActions.getItem(id)
         .then(response => {
            console.log(response)
            try {
               this.setState({
                  confirmationDetails: response.data,
                  detailsLoader: false,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               this.setState({ detailsLoader: false })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ detailsLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getLines = (confirmationId, paginationParams, filters) => {
      this.setState({ tableLoader: true })
      return linesActions.getListExtended(confirmationId, paginationParams, filters)
         .then(response => {
            console.log(response)
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD')
                     }
                  }
                  updatedFilters.push(filter)
               }

               this.setState({
                  confirmationLines: response.data.data,
                  fullListIds: response.full_list_ids,
                  itemPaginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               this.setState({ tableLoader: false })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ tableLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getLines(this.state.itemId, this.state.itemsPaginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {} })
      this.getLines(this.state.itemId, this.state.itemsPaginationParams, {});
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      // console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getLines(this.state.itemId, paginationParams, this.state.filtersValues);
   };

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography;
      const { Option } = Select;
      const { Panel } = Collapse;
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.itemsPaginationParams.per_page,
            total: this.state.itemsPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' prodotti' : ' prodotto')
         },
         onChange: this.handleTableChange
      }
      const confirmationLinesTableColumns = [
         {
            title: 'Progressivo',
            key: 'progressive',
            width: 120,
            fixed: 'left',
            render: (record) => (
               record.progressive.progressive
            ),
            sorter: true
         },
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 120,
            fixed: 'left',
            render: (record) => (
               record.ean_upc
            ),
            sorter: true
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 120,
            fixed: 'left',
            render: (record) => (
               !_.isNil(record.product) ? record.product.asin : null
            ),
         },
         {
            title: 'MPN',
            key: 'mpn',
            render: (record) => (
               record.mpn
            ),
            sorter: true
         },
         {
            title: 'Pz confermati',
            key: 'quantity_confirmed',
            width: 100,
            render: (record) => (
               record.quantity_confirmed
            ),
            sorter: true
         },
         {
            title: 'Pz richiesti',
            key: 'quantity_ordered',
            width: 100,
            render: (record) => (
               record.quantity_ordered
            ),
            sorter: true
         },
         {
            title: 'Eccedenza',
            key: 'quantity_surplus',
            width: 100,
            render: (record) => (
               record.quantity_surplus
            ),
            sorter: true
         },
         {
            title: 'Valore confermato',
            key: 'value_confirmed',
            width: 100,
            render: (record) => (
               record.value_confirmed_human
            ),
            sorter: true
         },
         {
            title: 'Sconto percentuale',
            key: 'discount_percentage',
            width: 100,
            render: (record) => (
               record.discount_percentage_human
            ),
            sorter: true
         },
         {
            title: 'Sconto puntuale',
            key: 'discount_amount',
            width: 100,
            render: (record) => (
               record.discount_amount_human
            ),
            sorter: true
         },
         {
            title: 'Incremento percentuale',
            key: 'increase_percentage',
            width: 100,
            render: (record) => (
               record.increase_percentage_human
            ),
            sorter: true
         },
         {
            title: 'Incremento puntuale',
            key: 'increase_amount',
            width: 100,
            render: (record) => (
               record.increase_amount_human
            ),
            sorter: true
         },
      ];
      const confirmationLinesTable = <Table {...tableLayout} columns={confirmationLinesTableColumns} dataSource={this.state.confirmationLines} />;

      const confirmationData = (
         <Row>
            <Col span={24}>
               <Descriptions size='small' column={1} bordered labelStyle={{'width': '250px'}} contentStyle={{'font-weight': 'bold'}}>
                  <Descriptions.Item label="Conferma creata il">{this.state.confirmationDetails.created_at_human}</Descriptions.Item>
                  <Descriptions.Item label="Conferma creata da">{this.state.confirmationDetails.user.firstname + ' ' + this.state.confirmationDetails.user.lastname}</Descriptions.Item>
                  <Descriptions.Item label="Nome file importato">{this.state.confirmationDetails.filename_original}</Descriptions.Item>
                  <Descriptions.Item label="Fornitore">{this.state.confirmationDetails.supplier.name}</Descriptions.Item>
                  <Descriptions.Item label="Pezzi confermati">{this.state.confirmationDetails.total_confirmed}</Descriptions.Item>
                  {/* <Descriptions.Item label="Pezzi ordinati">{this.state.confirmationDetails.total_ordered}</Descriptions.Item> */}
                  <Descriptions.Item label="Eccedenze">{this.state.confirmationDetails.total_surplus}</Descriptions.Item>
                  <Descriptions.Item label="Valore confermato">{this.state.confirmationDetails.total_value_human}</Descriptions.Item>
               </Descriptions>
            </Col>
         </Row>
      );

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const mainContent = (
         <Row gutter={[4, 4]}>
            <Col></Col>
            <Col flex='auto'></Col>
            <Col align='right'>
               <Space>
                  {/* <OrdersStatusLabel
                           value={this.state.orderDetails.po_status_id}
                           title={this.state.orderDetails.order_status_name}
                        /> */}
                  {/* <DropdownMenu
                           type='primary'
                           title={languageManager.getMessage(this.props.commonState, 'common.components.order_status_button.title')}
                           action={this.orderStatusHandler}
                           values={this.state.orderStatusList}
                           loading={this.state.orderStatusLoader}
                        /> */}
               </Space>
            </Col>
            <Col span={24}>
               {confirmationLinesTable}
            </Col>
         </Row>
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col align='right'></Col>
               </Row>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  {confirmationData}
                  {pageFilters}
                  {mainContent}
               </Space>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/operations/supplier_confirmations/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SupplierConfirmationDetails);