import PurchaseOrderManualUpdateLinesApi from '../../../../../api/PurchaseOrderManualUpdateLinesApi';
import _ from 'lodash';

export function getList() {
   return PurchaseOrderManualUpdateLinesApi.getList()
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getListExtended(confirmationId, params = [], filterValues = {}) {
   return PurchaseOrderManualUpdateLinesApi.getListExtended(confirmationId, params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getModel() {
   return PurchaseOrderManualUpdateLinesApi.getModel()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function getItem(itemId, params = [], filterValues = {}) {
   return PurchaseOrderManualUpdateLinesApi.getItem(itemId, params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            // console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function importFromFile(formData) {
   return PurchaseOrderManualUpdateLinesApi.importFromFile(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else {
            throw new Error(response.data.error ? response.data.error : 'Server error');
         }
      })
      .catch(error => {
         throw error;
      });
}