import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'
import * as storeActions from '../store/actions/dashboard'
import { OrdersStatsRangeEnum, PurchaseOrderGroupEnum, PurchaseOrderTypeEnum } from '../../../shared/enums'
import OrdersStatsCard from './OrdersStatsCard'
import OrdersStatsWOWLine from './OrdersStatsWOWLine'

import { Button, Space, notification, Row, Col, Segmented, Typography, Card, Table, DatePicker } from 'antd';
import { InfoCircleOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons';

class OrdersStats extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        rangeChangeLoader: false,
        rangeLoaders: [],
        ordersChartData: [],
        ordersChartDataByDayAverage: [],
        orderValuesChartData: [],
        bestSellingItem: {},
        rangeSelection: [],
        rangeSelected: false,
        rangePickerStart: null,
        rangePickerEnd: null,
        startDate: null,
        endDate: null,
        vendorCodeGroupSelection: [],
        vendorCodeGroupSelected: null,
    }

    componentDidMount() {
        Promise.all([
            // this.getOrdersStatsData(moment().startOf('week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.props.vendorCodeType, this.props.vendorCodeGroup),
            // this.getCountOrdersBoxData(),
            this.prepareGroupSelection(),
            this.prepareRangeSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                rangeChangeLoader: false,
                rangePickerStart: moment().subtract(1,'weeks').startOf('week'),
                rangePickerEnd: moment().subtract(1,'weeks').endOf('week'),
                startDate: moment().subtract(1,'weeks').startOf('week').format('YYYY-MM-DD'),
                endDate: moment().subtract(1,'weeks').endOf('week').format('YYYY-MM-DD'),
            });
        });
    }

    prepareGroupSelection = () => {
        var groups = []
        groups.push({ value: null, label: languageManager.getMessage(this.props.commonState, 'common.vendor_code_group.ALL') })
        groups.push({ value: PurchaseOrderGroupEnum.FTW, label: languageManager.getMessage(this.props.commonState, 'common.vendor_code_group.FTW') })
        groups.push({ value: PurchaseOrderGroupEnum.PES, label: languageManager.getMessage(this.props.commonState, 'common.vendor_code_group.PES') })
        groups.push({ value: PurchaseOrderGroupEnum.LUGG, label: languageManager.getMessage(this.props.commonState, 'common.vendor_code_group.LUGG') })
        this.setState({ vendorCodeGroupSelection: groups })
        return true
    }

    vendorCodeGroupSelectedChangeHandler = (value) => {
        this.setState({ vendorCodeGroupSelected: value })
    }

    prepareRangeSelection = () => {
        var ranges = []
        // ranges.push({ value: OrdersStatsRangeEnum.DAILY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.today') })
        // ranges.push({ value: OrdersStatsRangeEnum.LAST_WEEK, label: languageManager.getMessage(this.props.commonState, 'common.ranges.last_week') })
        ranges.push({ value: OrdersStatsRangeEnum.WEEKLY, label: languageManager.getMessage(this.props.commonState, 'common.ranges.weekly') })
        ranges.push({ value: OrdersStatsRangeEnum.MONTHLY, label: languageManager.getMessage(this.props.commonState, 'common.ranges.monthly') })
        ranges.push({ value: OrdersStatsRangeEnum.QUARTERLY, label: languageManager.getMessage(this.props.commonState, 'common.ranges.quarterly') })
        ranges.push({ value: OrdersStatsRangeEnum.YEARLY, label: languageManager.getMessage(this.props.commonState, 'common.ranges.yearly') })
        ranges.push({ value: null, label: languageManager.getMessage(this.props.commonState, 'common.ranges.custom') })
        this.setState({ rangeSelection: ranges, rangeSelected: OrdersStatsRangeEnum.WEEKLY })
        return true
    }

    onWeekChange = (date, dateString) => {
        const year = moment(date).year()
        const week = moment(date).week()
        const startDate = moment().year(year).week(week).startOf('week')
        const endDate = moment().year(year).week(week).endOf('week')
        // console.log(startDate, endDate)
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: OrdersStatsRangeEnum.WEEKLY })
        this.rangeChangeHandler(startDate, endDate)
    }
    onMonthChange = (date, dateString) => {
        const year = moment(date).year()
        const month = moment(date).month()
        const startDate = moment().year(year).month(month).startOf('month')
        const endDate = moment().year(year).month(month).endOf('month')
        // console.log(startDate, endDate)
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: OrdersStatsRangeEnum.MONTHLY })
        this.rangeChangeHandler(startDate, endDate)
    }
    onQuarterChange = (date, dateString) => {
        const year = moment(date).year()
        const quarter = moment(date).quarter()
        const startDate = moment().year(year).quarter(quarter).startOf('quarter')
        const endDate = moment().year(year).quarter(quarter).endOf('quarter')
        // console.log(startDate, endDate)
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: OrdersStatsRangeEnum.QUARTERLY })
        this.rangeChangeHandler(startDate, endDate)
    }
    onYearChange = (date, dateString) => {
        const year = moment(date).year()
        const startDate = moment().year(year).startOf('year')
        const endDate = moment().year(year).endOf('year')
        // console.log(startDate, endDate)
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: OrdersStatsRangeEnum.YEARLY })
        this.rangeChangeHandler(startDate, endDate)
    }

    rangeSelectedChangeHandler = (range) => {
        // this.setState({ rangeChangeLoader: true })
        let startDate = moment()
        let endDate = moment()
        switch (range) {
            case OrdersStatsRangeEnum.DAILY:
                break
            case OrdersStatsRangeEnum.WEEKLY:
                startDate = moment().startOf('week')
                break
            case OrdersStatsRangeEnum.MONTHLY:
                startDate = moment().startOf('month')
                break
            case OrdersStatsRangeEnum.QUARTERLY:
                startDate = moment().startOf('quarter')
                break
            case OrdersStatsRangeEnum.YEARLY:
                startDate = moment().startOf('year')
                break
        }
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: range })
        this.rangeChangeHandler(startDate, endDate)
    }
    setRangePickerDates = (dates) => {
        const startDate = dates[0]
        const endDate = dates[1]
        // const today = moment()
        // const lastWeekStart = moment().subtract(1, 'weeks').startOf('week')
        // const lastWeekEnd = moment().subtract(1, 'weeks').endOf('week')
        // const weekStart = moment().startOf('week')
        // const monthStart = moment().startOf('month')
        // const quarterStart = moment().startOf('quarter')
        // const yearStart = moment().startOf('year')
        // let rangeSelected
        // if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day')) {
        //     rangeSelected = OrdersStatsRangeEnum.DAILY
        // } else if (startDate == lastWeekStart && endDate == lastWeekEnd) {
        //     rangeSelected = OrdersStatsRangeEnum.LAST_WEEK
        // } else if (startDate == weekStart && endDate == today) {
        //     rangeSelected = OrdersStatsRangeEnum.THIS_WEEK
        // } else if (startDate == monthStart && endDate == today) {
        //     rangeSelected = OrdersStatsRangeEnum.THIS_MONTH
        // } else if (startDate == quarterStart && endDate == today) {
        //     rangeSelected = OrdersStatsRangeEnum.THIS_QUARTER
        // } else if (startDate == yearStart && endDate == today) {
        //     rangeSelected = OrdersStatsRangeEnum.THIS_YEAR
        // } else {
        //     rangeSelected = null
        // }
        this.setState({ rangePickerStart: startDate, rangePickerEnd: endDate, rangeSelected: null })
    }
    rangeChangeHandler = (startDate = null, endDate = null) => {
        const start = _.isNil(startDate) ? this.state.rangePickerStart.format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD')
        const end = _.isNil(endDate) ? this.state.rangePickerEnd.format('YYYY-MM-DD') : endDate.format('YYYY-MM-DD')
        this.setState({
            startDate: start,
            endDate: end
        })
    }

    render() {
        if (_.isNil(this.state.startDate) || _.isNil(this.state.endDate)) return null
        const { Title, Text } = Typography;
        const { RangePicker } = DatePicker;
        const tableLayout = {
            bordered: true,
            size: "small",
            pagination: false,
            loading: this.state.tableLoader
        }
        const ordersByMarketplaceColumns = [
            {
                title: 'Marketplace',
                key: 'marketplace',
                render: (record) => (
                    record.marketplace.name
                ),
            },
            {
                title: 'Ordini',
                dataIndex: 'total_orders',
                key: 'total_orders',
                sorter: (a, b) => a.total_orders - b.total_orders,
            },
            {
                title: 'Valore',
                key: 'total_value',
                render: (record) => (
                    record.total_value + ' €'
                ),
                sorter: (a, b) => a.total_value - b.total_value,
            },
        ]
        const ordersByVendorCodeColumns = [
            {
                title: 'Vendor Code',
                key: 'vendor_code',
                render: (record) => (
                    record.vendor_code.code
                ),
            },
            {
                title: 'Ordini',
                dataIndex: 'total_orders',
                key: 'total_orders',
                sorter: (a, b) => a.total_orders - b.total_orders,
            },
            {
                title: 'Valore',
                key: 'total_value',
                render: (record) => (
                    record.total_value + ' €'
                ),
                sorter: (a, b) => a.total_value - b.total_value,
            },
        ]

        return (
            <section>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Card loading={this.state.componentLoader || this.state.rangeChangeLoader}
                            title={
                                <Space direction='vertical' size='small'>
                                    <Space size='large'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_stats.title')}</Title>
                                        <Segmented size='middle' options={this.state.vendorCodeGroupSelection} onChange={this.vendorCodeGroupSelectedChangeHandler} />
                                        <Segmented size='middle' options={this.state.rangeSelection} onChange={this.rangeSelectedChangeHandler}
                                            defaultValue={OrdersStatsRangeEnum.WEEKLY}
                                            disabled={this.state.rangeChangeLoader} />
                                        {
                                            this.state.rangeSelected === OrdersStatsRangeEnum.WEEKLY ? (
                                                <Space size='small'>
                                                    <Text>Week:</Text>
                                                    <DatePicker
                                                        onChange={this.onWeekChange}
                                                        style={{ width: '175px' }}
                                                        picker='week'
                                                        format='ww-YYYY'
                                                    />
                                                </Space>
                                            ) : null
                                        }
                                        {
                                            this.state.rangeSelected === OrdersStatsRangeEnum.MONTHLY ? (
                                                <Space size='small'>
                                                    <Text>Month:</Text>
                                                    <DatePicker
                                                        onChange={this.onMonthChange}
                                                        style={{ width: '175px' }}
                                                        picker='month'
                                                        format='MM-YYYY'
                                                    />
                                                </Space>
                                            ) : null
                                        }
                                        {
                                            this.state.rangeSelected === OrdersStatsRangeEnum.QUARTERLY ? (
                                                <Space size='small'>
                                                    <Text>Quarter:</Text>
                                                    <DatePicker
                                                        onChange={this.onQuarterChange}
                                                        style={{ width: '175px' }}
                                                        picker='quarter'
                                                        format='Q-YYYY'
                                                    />
                                                </Space>
                                            ) : null
                                        }
                                        {
                                            this.state.rangeSelected === OrdersStatsRangeEnum.YEARLY ? (
                                                <Space size='small'>
                                                    <Text>Year:</Text>
                                                    <DatePicker
                                                        onChange={this.onYearChange}
                                                        style={{ width: '175px' }}
                                                        picker='year'
                                                        format='YYYY'
                                                    />
                                                </Space>
                                            ) : null
                                        }
                                        <Space size='small'>
                                            <RangePicker
                                                key='custom_rangepicker'
                                                onChange={(values) => this.setRangePickerDates(values)}
                                                value={[this.state.rangePickerStart, this.state.rangePickerEnd]}
                                                format='DD-MM-YYYY'
                                                disabled={!_.isNil(this.state.rangeSelected)}
                                            />
                                            <Button
                                                key='custom_searchbutton'
                                                type='primary'
                                                onClick={() => this.rangeChangeHandler()}
                                                loading={this.state.rangeChangeLoader}
                                                disabled={!_.isNil(this.state.rangeSelected)}
                                            >
                                                Cerca
                                            </Button>
                                        </Space>
                                    </Space>
                                </Space>
                            }
                            bordered={false}
                            className="card-dashboard-stats"
                        >
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <OrdersStatsWOWLine
                                        vendorCodeType={PurchaseOrderTypeEnum.RIA}
                                        vendorCodeGroup={this.state.vendorCodeGroupSelected}
                                    />
                                </Col>
                                <Col span={24}>
                                    <OrdersStatsCard
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        range={this.state.rangeSelected}
                                        vendorCodeType={PurchaseOrderTypeEnum.RIA}
                                        vendorCodeGroup={this.state.vendorCodeGroupSelected}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(OrdersStats)