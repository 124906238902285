import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'

import { Form, Input, InputNumber, Select, Button, Row, Col, notification, List, Space, Typography } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

const FormEditWarehouse = (props) => {
    const [layout, setLayout] = useState(props.layout)
    const [values, setValues] = useState(props.values)

    const [form] = Form.useForm()
    const { Text } = Typography
    const { Option } = Select

    useEffect(() => {
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values])

    const submitForm = (values) => {
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="FormEditWarehouse"
            onFinish={submitForm}
        >
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.code')}
                name="code"
            >
                <Input maxLength={2} />
            </Form.Item>
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.name')}
                name="name"
            >
                <Input />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form >
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(FormEditWarehouse)