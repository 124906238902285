import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { PurchaseOrderTypeEnum } from '../../../shared/enums.ts'

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CheckOutlined, ClockCircleOutlined, ImportOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/stock_suppliers'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import DrawerEditStock from './components/DrawerEditStock'
import DrawerImportStocks from './components/DrawerImportStocks'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

class StockSuppliersList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      importLoader: false,
      emptyLoader: false,
      loadersDelete: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [],
      multipleExportLoader: false,
      multipleExportList: [
         {
            action: 'export_xlsx',
            name: 'Esporta selezionati in Excel'
         },
      ],
      feedback: {},
      searchOrderInput: null,

      filters: [],
      // filtersValues: {},
      appliedFilters: [],
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  brandsList: response.brandsList,
                  suppliersList: response.suppliersList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      let initialFilters = {}
      if (this.props.match.params.supplier_id) {
         initialFilters.supplier_id = [parseInt(this.props.match.params.supplier_id)]
      }
      Promise.all([
         this.getList(this.state.paginationParams, initialFilters),
         this.getComplements()
      ]).then(() => {
         this.setState({
            filtersValues: initialFilters,
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export_xlsx':
            this.exportExcelHandler()
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, {});
   }

   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ showCreateDrawer: false })
      // this.setState({ createDrawerItem: null, })
   }
   handleCreateOrUpdateStock = (data) => {
      console.log('Stock creato/aggiornato correttamente')
      this.setState({ showCreateDrawer: false })
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         // this.getComplements()
      ]).then()
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filters)
   }

   showViewDrawer = item => {
      this.setState({ viewDrawerItem: item, showViewDrawer: true })
   }
   hideViewDrawer = () => {
      this.setState({ showViewDrawer: false })
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }

   emptyWarehousesHandler = () => {
      // asyncronous calls
      this.setState({ emptyLoader: true })
      return storeActions.emptyWarehouses()
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getList(this.state.paginationParams, this.state.filters),
                  // this.getComplements()
               ]).then(
                  this.setState({
                     emptyLoader: false,
                  })
               )
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
               })
            } catch (e) {
               this.setState({ emptyLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ emptyLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportExcelHandler = () => {
      let filename
      this.setState({ multipleExportLoader: true });
      filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_STOCKS_SUPPLIER.xlsx';
      storeActions.exportExcel(filename, this.state.selectedRowKeys)
         .then(response => {
            this.setState({ multipleExportLoader: false });
            console.log(response);
            try {
               this.setState({ loader: false });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ multipleExportLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            title: 'Fornitore',
            width: 100,
            key: 'supplier_name',
            fixed: 'left',
            render: (record) => (
               !_.isNil(record.supplier) ? record.supplier.name : null
            ),
            sorter: true,
         },
         {
            title: 'EAN/UPC',
            width: 120,
            key: 'ean_upc',
            fixed: 'left',
            render: (record) => (
               record.ean_upc
            ),
            sorter: true,
         },
         {
            title: 'Brand',
            width: 100,
            key: 'brand',
            render: (record) => (
               !_.isNil(record.brand) ? record.brand.name : null
            ),
            sorter: true,
         },
         {
            title: 'WHLS',
            width: 100,
            key: 'wsp',
            render: (record) => (
               record.wsp_human
            ),
            sorter: true,
         },
         {
            title: 'Sconto',
            width: 100,
            key: 'discount_percentage',
            render: (record) => (
               record.discount_percentage_human
            ),
            sorter: true,
         },
         {
            title: 'Cost',
            width: 100,
            key: 'cost',
            render: (record) => (
               record.cost_human
            ),
            sorter: true,
         },
         {
            title: 'Quantità',
            width: 100,
            key: 'quantity',
            render: (record) => (
               record.quantity
            ),
            sorter: true,
         },
         {
            title: 'Conferma',
            width: 90,
            align: 'center',
            key: 'need_confirmation',
            render: (record) => (
               record.need_confirmation ? <ClockCircleOutlined className='icon-warning' /> : <CheckOutlined className='icon-success' />
            ),
            sorter: true,
         },
         {
            title: 'Ultimo aggiornamento',
            width: 150,
            key: 'updated_at',
            render: (record) => (
               record.updated_at_human
            ),
            sorter: true,
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  {/* <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record)}
                  /> */}
                  {/* <Button type="danger" size='small'
                     icon={<CloseOutlined />}
                     loading={this.state.loadersDelete[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  /> */}
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' prodotti' : ' prodotto')
         },
         onChange: this.handleTableChange,
         // rowClassName: (record) => { return !_.isNil(record.status) && record.status.code === 'CANCELLED' ? 'record-cancelled' : null }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const dataTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         // rowClassName={(record, index) => {
         //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
         // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const drawerEditStock = (
         <DrawerEditStock
            key='createDrawer'
            visible={this.state.showCreateDrawer}
            item={this.state.createDrawerItem}
            complements={{ ...this.state.complements }}
            onClose={this.hideCreateDrawer}
            onSubmit={(data) => this.handleCreateOrUpdateStock(data)} />
      )
      const drawerImportStocks = (
         <DrawerImportStocks
            key='importDrawer'
            visible={this.state.showImportDrawer}
            complements={{ ...this.state.complements }}
            onSubmit={this.updateList}
            onClose={() => this.showImportDrawer(false)} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                        <DropdownMenu
                           type='primary'
                           title='Esporta'
                           action={this.multipleActionHandler}
                           values={this.state.multipleExportList}
                           disabled={!hasSelected}
                           loading={this.state.multipleExportLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={() => this.getList(this.state.paginationParams, this.state.filters)}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                        {/* <Button
                           type='warning'
                           icon={<DeleteOutlined />}
                           onClick={() =>
                              Modal.confirm({
                                 title: languageManager.getMessage(this.props.commonState, 'common.cancel_item'),
                                 icon: <QuestionCircleOutlined />,
                                 content: languageManager.getMessage(this.props.commonState, 'common.askEmptyConfirm'),
                                 okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                                 onOk: this.emptyWarehousesHandler
                              })
                           }
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.stock_suppliers.empty_warehouses')}
                        </Button> */}
                        <Button
                           type='primary'
                           icon={<ImportOutlined />}
                           onClick={() => this.showImportDrawer(true)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.stock_suppliers.import')}
                        </Button>
                        {/* <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.stock_suppliers.new')}
                        </Button> */}
                     </Space>
                  </div>
               </div>
            </div>
            {dataTable}
            {drawerEditStock}
            {drawerImportStocks}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.stock_suppliers.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/suppliers/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(StockSuppliersList);