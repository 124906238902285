import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class DashboardApi {

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getOrderData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getChannelData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getChannelData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getProductData(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getProductData, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderTotals() {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_order_totals)
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrderTotalsDetails(startDate, endDate) {

        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_order_totals_details, {
                startDate: startDate,
                endDate: endDate
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrdersChartData(year) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_orders_chart_data, {
                year: year,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getOrdersStatsData(startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_orders_stats_data, {
                start_date: startDate,
                end_date: endDate,
                range: selectedRange,
                vendor_code_type: vendorCodeType,
                vendor_code_group: vendorCodeGroup,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getOrdersBrandsStatsData(startDate, endDate, selectedRange, vendorCodeType, vendorCodeGroup, countryId) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_orders_brands_stats_data, {
                start_date: startDate,
                end_date: endDate,
                range: selectedRange,
                vendor_code_type: vendorCodeType,
                vendor_code_group: vendorCodeGroup,
                country_id: countryId,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static getOrdersStatsWOWLineData(vendorCodeType, vendorCodeGroup) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.getOrdersStatsWOWLineData, {
                vendor_code_type: vendorCodeType,
                vendor_code_group: vendorCodeGroup,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static exportStats(filename, statsFilters, stats, brandStats) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.exportStatsCSV, {
                filename: filename,
                stats_filters: statsFilters,
                stats: stats,
                brands_stats: brandStats,
            })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
    static getProductsStatsData(range) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.dashboard.get_products_stats_data, {
                range: range,
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}

export default DashboardApi;