import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Moment from 'moment';

import { ManualUpdateStatusEnum, OrderStatusActionEnum } from '../../../shared/enums.ts';
import { Table, Select, Input, Button, Space, notification, Modal, List, Collapse, Row, Col, Typography } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { DoubleLeftOutlined, EyeOutlined, DeleteOutlined, QuestionCircleOutlined, RetweetOutlined, PlusOutlined, DownloadOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading, updateObject } from '../../../shared/utility'

import * as storeActions from './store/actions/manual_updates';
import * as purchaseOrdersActions from '../purchase_orders/store/actions/purchase_orders.js';
import { downloadFromS3 } from '../../../store/actions/commonAction'

import TableFilters from '../../common/components/TableFilters';
import ImportFeedback from '../../common/components/ImportFeedback'

import Loader from '../../common/PageElements/Loader';
import DropdownMenu from '../../common/components/DropdownMenu';
import DrawerImportUpdates from './components/DrawerImportUpdates';

class ManualUpdatesList extends Component {
   state = {
      pageLoader: true,
      importBox: false,
      importLoader: true,
      loadersDelete: [],
      loadersDownload: [],
      loadersUpload: [],
      itemList: [],
      fullListIds: [],
      yearsList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [
         {
            action: 'delete_items',
            name: 'Cancella selezionati',
            needconfirmation: 'yes',
         },
         // {
         //    action: 'export_csv',
         //    name: 'Esporta selezionati in CSV'
         // },
      ],

      filters: [],
      appliedFilters: [],
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  fullListIds: response.full_list_ids,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filters)
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters)
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         // case 'export_csv':
         //    this.setState({ multipleActionLoader: true })
         //    Promise.all([
         //       this.exportOrdersHandler()
         //    ]).then(() => {
         //       this.setState({ multipleActionLoader: false });
         //    });
         //    break
         case 'delete_items':
            this.setState({ multipleActionLoader: true })
            Promise.all([
               this.deleteItemsHandler(this.state.selectedRowKeys)
            ]).then(() => {
               this.setState({ multipleActionLoader: false });
            });
            break
         default:
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            break

      }
   }

   // NOT USED
   exportOrdersHandler = () => {
      this.setState({ multipleExportLoader: true });
      let now = Moment().format('YYYY.MM.DD-HH.mm.ss');
      let filename = now + '_export_orders.csv';
      console.log("Export orders", filename, this.state.selectedRowKeys);
      return storeActions.exportOrders(filename, this.state.selectedRowKeys)
         .then(response => {
            try {
               console.log(response);
               this.setState({ multipleExportLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ multipleExportLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ multipleExportLoader: false });
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemsHandler = (itemIds) => {
      this.setState({ loadersDelete: startLoading(this.state.loadersDelete, itemIds) })
      return storeActions.deleteItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })
               this.getList(this.state.paginationParams, this.state.filtersValues)
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   deleteItemHandler = (itemId) => {
      this.deleteItemsHandler([itemId])
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
      }
      this.getList(paginationParams, this.state.filters);
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getList(updateObject(this.state.paginationParams, { current_page: 1 }), values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {} })
      this.getList(updateObject(this.state.paginationParams, { current_page: 1 }), {});
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }
   onImportComplete = (importResult, importStatus) => {
      console.log('IMPORT COMPLETE', importResult, importStatus)
      this.setState({ showImportDrawer: false, showImportResult: true, importResult: importResult, importStatus: importStatus })
      this.updateList()
   }

   downloadFileHandler = (itemId, filePath) => {
      this.setState({ loadersDownload: startLoading(this.state.loadersDownload, itemId) })
      return downloadFromS3(filePath, filePath.split('/').pop())
         .then(response => {
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }   

   uploadOrdersHandler = (itemId, orderIds) => {
      this.setState({ loadersUpload: startLoading(this.state.loadersUpload, itemId) })
      purchaseOrdersActions.updateOrderStatus(orderIds, OrderStatusActionEnum.PROCESS.toString())
         .then(response => {
            console.log(response);
            try {
               this.setState({ loadersUpload: endLoading(this.state.loadersUpload, itemId) })
               if (!_.isNil(response.human) && response.status === 'ok') {
                  storeActions.updateStatus(itemId, ManualUpdateStatusEnum.SENT_TO_AMAZON.toString())
                  this.updateList()
                  notification.success({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               } else if (!_.isNil(response.human)) {
                  notification.warning({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: (
                        <List
                           size="small"
                           split={false}
                           dataSource={response.human}
                           renderItem={item => <List.Item>{item}</List.Item>}
                        />
                     ),
                     duration: 0
                  })
               }
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            this.setState({ loadersUpload: endLoading(this.state.loadersUpload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            key: 'created_at',
            title: 'Data aggiornamento',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.created_at_human
            ),
            sorter: true,
         },
         {
            key: 'filename',
            title: 'Nome file',
            width: 300,
            fixed: 'left',
            render: (record) => (
               <Link to={{ pathname: '/purchase_orders/manual_updates/details/' + record.id }} >{record.filename_original}</Link>
            ),
            sorter: true,
         },
         {
            key: 'count_order_updates',
            title: 'Ordini aggiornati',
            width: 125,
            render: (record) => (
               record.count_order_updates
            ),
            sorter: true,
         },
         {
            key: 'quantity_confirmed',
            title: 'Pz aggiornati',
            width: 125,
            render: (record) => (
               record.quantity_confirmed
            ),
            sorter: true,
         },
         {
            key: 'count_warnings',
            title: 'Avvisi',
            width: 100,
            render: (record) => (
               record.count_warnings
            ),
            sorter: true,
         },
         {
            key: 'count_errors',
            title: 'Errori',
            width: 100,
            render: (record) => (
               record.count_errors
            ),
            sorter: true,
         },
         {
            key: 'user_id',
            title: 'Creato da',
            width: 150,
            render: (record) => (
               record.user.firstname + ' ' + record.user.lastname
            ),
            sorter: true,
         },
         {
            title: 'Stato', width: 125, key: 'status',
            render: (record) => (
               record.status
            ),
            sorter: (a, b) => a.order_status_name.localeCompare(b.order_status_name),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link
                     to={{
                        pathname: '/purchase_orders/manual_updates/details/' + record.id,
                     }}
                  >
                     <Button type="primary" size='small'
                        icon={<EyeOutlined />}
                        disabled={this.state.loadersDelete[record.id]}
                     />
                  </Link>
                  <Button type="warning" size='small'
                     icon={<UploadOutlined />}
                     loading={this.state.loadersUpload[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.send_to_amazon'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.uploadOrdersHandler(record.id, record.updated_order_ids)
                        })
                     }
                  />
                  <Button type="danger" size='small'
                     icon={<DeleteOutlined />}
                     loading={this.state.loadersDelete[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  />
                  {
                     !_.isNil(record.filename_nzt) ? (
                        <Button size='small'
                           icon={<DownloadOutlined />}
                           loading={this.state.loadersDownload[record.id]}
                           onClick={() => this.downloadFileHandler(record.id, record.filename_nzt)}
                        />
                     ) : null
                  }
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' aggiornamenti' : ' aggiornamento')
         },
         onChange: this.handleTableChange,
         // rowClassName: (record) => { return !_.isNil(record.status) && record.status.code === 'CANCELLED' ? 'record-cancelled' : null }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const dataTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
            // rowClassName={(record, index) => {
            //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
            // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );
      const drawerImportUpdates = (
         <DrawerImportUpdates
            key='importDrawer'
            visible={this.state.showImportDrawer}
            complements={{ ...this.state.complements }}
            onSubmit={(importResult, importStatus) => this.onImportComplete(importResult, importStatus)}
            onClose={() => this.showImportDrawer(false)} />
         // onClose={() => this.onImportComplete(['pippo', 'pluto'], 'warning')} />
      )
      const importFeedback = (
         <ImportFeedback
            key='importFeedback'
            importResult={this.state.importResult}
            importStatus={this.state.importStatus}
            visible={this.state.showImportResult} />
      )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <Row gutter={[4, 4]}>
            <Col span={12}>
               <Space>
                  <Button
                     onClick={() => this.selectAllHandler()}
                  >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                  </Button>
                  <span>
                     {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                  </span>
                  <DropdownMenu
                     type='primary'
                     title='Azioni multiple'
                     action={this.multipleActionHandler}
                     values={this.state.multipleActionList}
                     disabled={!hasSelected}
                     loading={this.state.multipleActionLoader}
                  />
                  {/* <DropdownMenu
                     type='primary'
                     title='Esporta'
                     action={this.multipleActionHandler}
                     values={this.state.multipleExportList}
                     disabled={!hasSelected}
                     loading={this.state.multipleExportLoader}
                  /> */}
               </Space>
            </Col>
            <Col span={12} align='right'>
               <Space>
                  <Button icon={<RetweetOutlined />} onClick={this.updateList}>
                     {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                  </Button>
                  <Button
                     type='primary'
                     icon={<PlusOutlined />}
                     onClick={() => this.showImportDrawer(true)}
                  >
                     {languageManager.getMessage(this.props.commonState, 'component.purchase_orders.manual_updates.import')}
                  </Button>
               </Space>
            </Col>
            <Col span={24}>
               {dataTable}
               {drawerImportUpdates}
            </Col>
         </Row>
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.manual_updates.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  {importFeedback}
                  {pageFilters}
                  {pageContent}
               </Space>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(ManualUpdatesList);