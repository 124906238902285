import CategoriesApi from '../../../../../api/CategoriesApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(parentId = null) {
   return CategoriesApi.getList(parentId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

export function getListExtended(page, pageSize, filters) {
   return CategoriesApi.getListExtended(page, pageSize, filters)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         } else {
            throw new Error(response.data.exception ? response.data.exception :
               response.data.error ? response.data.error : 'serverError');
         }
      })
      .catch(error => {
         throw new Error(error.data.exception ? error.data.exception :
            error.data.error ? error.data.error : 'serverError');
      });
}

export function prepareTree (tree) {   
   _.forEach(tree, (el, i) => {
      el.key = el.id;
      el.title = el.name.it;
      if(el.children.length) {
         return prepareTree(el.children);
      }
   });
   return tree;
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getTree() {
   return CategoriesApi.getTree()
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            const tree = prepareTree(response.data.data);
            response.data.data = tree;
            
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function rebuildTree(tree, return_list = false) {
   return CategoriesApi.rebuildTree(tree, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            const tree = prepareTree(response.data.data);
            response.data.data = tree;
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return CategoriesApi.getItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return CategoriesApi.createOrUpdate(itemData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleValue(itemId, value, language = 'it') {
   return CategoriesApi.toggleValue({id: itemId, value: value, language: language})
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return CategoriesApi.deleteItem(itemId, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}