import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { Table, Select, Button, Space, Divider, notification, DatePicker, Card, Collapse, Row, Col, Typography, List, Avatar } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CloseOutlined, EyeOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined, DownloadOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/unique_files_ria'
import { downloadFromS3 } from '../../../store/actions/commonAction'
import { flagPath } from '../../../shared/vars'
import { PurchaseOrderTypeEnum } from '../../../shared/enums'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import ImportFeedback from '../../common/components/ImportFeedback'
// import DrawerImportConfirmations from './components/DrawerImportConfirmations'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'
import { updateObject } from '../../../shared/utility'

class UniqueFilesRiaList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      loadersCancel: [],
      loadersDownload: [],
      loadersExport: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 10,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [],
      multipleExportLoader: false,
      multipleExportList: [],
      expandedRowKeys: [],

      filters: [],
      appliedFilters: [],

      showImportResult: false,
      importResult: null,
      importStatus: null,
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(PurchaseOrderTypeEnum.RIA, paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filters)
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  suppliersList: response.suppliersList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onWeekChange = (date, dateString) => {
      const year = Moment(date).year()
      const week = Moment(date).week()
      this.setState({ currentYear: year, currentWeek: week })
      this.getList(year, week)
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   }
   onExpandChange = (expanded, record) => {
      let expandedRows = _.clone(this.state.expandedRowKeys)
      if (expanded) {
         expandedRows.push(record.key)
      } else {
         _.pull(expandedRows, record.key)
      }
      this.setState({ expandedRowKeys: expandedRows })
   }
   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(updateObject(this.state.paginationParams, {current_page:1}), values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(updateObject(this.state.paginationParams, {current_page:1}), {});
   }

   showViewDrawer = item => {
      this.setState({ viewDrawerItem: item, showViewDrawer: true })
   }
   hideViewDrawer = () => {
      this.setState({ showViewDrawer: false })
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }
   onImportComplete = (importResult, importStatus) => {
      console.log('IMPORT COMPLETE', importResult, importStatus)
      this.setState({ showImportDrawer: false, showImportResult: true, importResult: importResult, importStatus: importStatus })
      // this.updateList()
   }

   exportRIA = (uniquefileId, filename) => {
      this.setState({ loadersExport: startLoading(this.state.loadersExport, uniquefileId) })
      return storeActions.exportFile(uniquefileId, filename)
         .then(response => {
            this.setState({ loadersExport: endLoading(this.state.loadersExport, uniquefileId) })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersExport: endLoading(this.state.loadersExport, uniquefileId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   downloadFileHandler = (itemId, filePath) => {
      this.setState({ loadersDownload: startLoading(this.state.loadersDownload, itemId) })
      return downloadFromS3(filePath, filePath.split('/').pop())
         .then(response => {
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   render() {
      if (this.state.pageLoader || _.isNil(this.props.authState.userData.languages)) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: false,
            defaultPageSize: 20,
            size: "default",
            showTotal: total => total + (total > 1 ? ' file unici' : ' file unico')
         },
         loading: this.state.componentLoader,
         // rowClassName: (record) => { return 'background-' + record.country.code.toLowerCase() }
         onChange: this.handleTableChange
      }
      const tableBrandsLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         loading: this.state.loaderBrandStats,
         // rowClassName: (record) => { return 'background-' + record.country.code.toLowerCase() }
      }
      const uniqueFilesColumns = [
         {
            title: 'Settimana',
            key: 'week',
            width: 60,
            align: 'center',
            className: 'tbold',
            render: (record) => (
               record.year + '-' + record.week
            ),
         },
         {
            title: 'Nazione',
            key: 'country',
            width: 60,
            align: 'center',
            render: (record) => {
               return (
                  <Avatar size='small' shape='square' src={flagPath + record.country.code + '_32.png'} />
               )
            },
         },
         {
            title: 'Marketplace',
            key: 'marketplace',
            width: 100,
            align: 'center',
            render: (record) => (
               record.marketplace.name
            ),
            sorter: (a, b) => a.marketplace.name.localCompare(b.marketplace.name),
         },
         {
            title: 'VendorCode',
            key: 'vendor_code',
            width: 100,
            align: 'center',
            className: 'tbold',
            render: (record) => (
               record.vendor_code.code
            ),
            sorter: (a, b) => a.vendor_code.code.localCompare(b.vendor_code.code),
         },
         {
            title: 'FC Code',
            key: 'fc_code',
            width: 100,
            align: 'center',
            className: 'tbold',
            render: (record) => (
               record.fc_code
            ),
            sorter: (a, b) => a.fc_code.localCompare(b.fc_code),
         },
         {
            title: 'Ordini',
            key: 'orders_accepted',
            width: 75,
            render: (record) => (
               record.orders_accepted
            ),
            sorter: (a, b) => a.orders_accepted - b.orders_accepted,
         },
         {
            title: 'Prodotti',
            key: 'count_products',
            width: 75,
            render: (record) => (
               record.count_products
            ),
            sorter: (a, b) => a.count_products - b.count_products,
         },
         {
            title: 'Ordinati AMZ',
            key: 'ordered_quantity',
            width: 100,
            render: (record) => (
               record.ordered_quantity
            ),
            sorter: (a, b) => a.ordered_quantity - b.ordered_quantity,
         },
         {
            title: 'Ordinati attuali AMZ',
            key: 'actual_ordered_quantity',
            width: 100,
            render: (record) => (
               record.actual_ordered_quantity
            ),
            sorter: (a, b) => a.actual_ordered_quantity - b.actual_ordered_quantity,
         },
         {
            title: 'Confermati EC',
            key: 'confirmed_quantity_user',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.confirmed_quantity_user != record.confirmed_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.confirmed_quantity_user,
               }
            },
            sorter: (a, b) => a.confirmed_quantity_user - b.confirmed_quantity_user,
         },
         {
            title: 'Confermati AMZ',
            key: 'confirmed_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.confirmed_quantity_user != record.confirmed_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.confirmed_quantity_amz,
               }
            },
            sorter: (a, b) => a.confirmed_quantity_amz - b.confirmed_quantity_amz,
         },
         {
            title: 'Cancellati AMZ',
            key: 'cancelled_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: !_.isNil(record.cancelled_quantity_amz) && record.cancelled_quantity_amz > 0 ? "bg-danger" : '',
                  },
                  children: record.cancelled_quantity_amz,
               }
            },
            sorter: (a, b) => a.cancelled_quantity_amz - b.cancelled_quantity_amz,
         },
         {
            title: 'Ricevuti EC',
            key: 'received_quantity_user',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.received_quantity_user != record.received_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.received_quantity_user,
               }
            },
            sorter: (a, b) => a.received_quantity_user - b.received_quantity_user,
         },
         {
            title: 'Ricevuti AMZ',
            key: 'received_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.received_quantity_user != record.received_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.received_quantity_amz,
               }
            },
            sorter: (a, b) => a.received_quantity_amz - b.received_quantity_amz,
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Button type="success" size='small'
                     icon={<DownloadOutlined />}
                     onClick={() => this.exportRIA(record.id, record.filename)}
                     loading={this.state.loadersExport[record.id]}
                  />
               </Space>),
         },
      ]
      const uniqueFileBrandsColumns = [
         {
            title: 'Brand',
            key: 'brand',
            width: 125,
            align: 'left',
            render: (record) => (
               // <Link
               //    to={{ pathname: '/unique_files/ria/details/' + this.state.currentYear + '/' + this.state.currentWeek + '/' + record.marketplace_id + '/' + record.brand.id }}
               // >
               record.brand
               // </Link>
            ),
         },
         {
            title: 'Prodotti',
            key: 'count_products',
            width: 75,
            render: (record) => (
               record.count_products
            ),
            sorter: (a, b) => a.count_products - b.count_products,
         },
         {
            title: 'Ordinati AMZ',
            key: 'ordered_quantity',
            width: 100,
            render: (record) => (
               record.ordered_quantity
            ),
            sorter: (a, b) => a.ordered_quantity - b.ordered_quantity,
         },
         {
            title: 'Ordinati attuali AMZ',
            key: 'actual_ordered_quantity',
            width: 100,
            render: (record) => (
               record.actual_ordered_quantity
            ),
            sorter: (a, b) => a.actual_ordered_quantity - b.actual_ordered_quantity,
         },
         {
            title: 'Confermati EC',
            key: 'confirmed_quantity_user',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.confirmed_quantity_user != record.confirmed_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.confirmed_quantity_user,
               }
            },
            sorter: (a, b) => a.confirmed_quantity_user - b.confirmed_quantity_user,
         },
         {
            title: 'Confermati AMZ',
            key: 'confirmed_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.confirmed_quantity_user != record.confirmed_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.confirmed_quantity_amz,
               }
            },
            sorter: (a, b) => a.confirmed_quantity_amz - b.confirmed_quantity_amz,
         },
         {
            title: 'Cancellati AMZ',
            key: 'cancelled_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: !_.isNil(record.cancelled_quantity_amz) && record.cancelled_quantity_amz > 0 ? "bg-danger" : '',
                  },
                  children: record.cancelled_quantity_amz,
               }
            },
            sorter: (a, b) => a.cancelled_quantity_amz - b.cancelled_quantity_amz,
         },
         {
            title: 'Ricevuti EC',
            key: 'received_quantity_user',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.received_quantity_user != record.received_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.received_quantity_user,
               }
            },
            sorter: (a, b) => a.received_quantity_user - b.received_quantity_user,
         },
         {
            title: 'Ricevuti AMZ',
            key: 'received_quantity_amz',
            width: 120,
            render: (record) => {
               return {
                  props: {
                     className: record.received_quantity_user != record.received_quantity_amz ? "bg-warning" : '',
                  },
                  children: record.received_quantity_amz,
               }
            },
            sorter: (a, b) => a.received_quantity_amz - b.received_quantity_amz,
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link to={{ pathname: '/unique_files/ria/details/' + record.unique_file_id + '/' + record.id }}>
                     <Button type="success" size='small'
                        icon={<EyeOutlined />}
                     />
                  </Link>
               </Space>),
         },
      ]

      const { selectedRowKeys } = this.state;

      const dataTable = (
         <Table {...tableLayout}
            columns={uniqueFilesColumns}
            dataSource={this.state.itemList}
            loading={this.state.tableLoader}
            expandable={{
               indentSize: 20,
               expandedRowKeys: this.state.expandedRowKeys,
               onExpand: this.onExpandChange,
               expandedRowRender: record => (
                  <Space direction='vertical' size='small'>
                     <Table {...tableBrandsLayout}
                        columns={uniqueFileBrandsColumns}
                        dataSource={record.brands}
                     />
                     <Divider />
                  </Space>
               )
            }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      )

      // const drawerEditProgressive = (
      //    <DrawerEditProgressive
      //       key='createDrawer'
      //       visible={this.state.showCreateDrawer}
      //       item={this.state.createDrawerItem}
      //       complements={{ ...this.state.complements }}
      //       onClose={this.hideCreateDrawer}
      //       onSubmit={(data) => this.handleCreateOrUpdateProgressive(data)} />
      // )
      // const drawerViewProgressive = (
      //    <DrawerViewProgressive
      //       key='viewDrawer'
      //       visible={this.state.showViewDrawer}
      //       item={this.state.viewDrawerItem}
      //       onClose={this.hideViewDrawer} />
      // )
      const importFeedback = (
         <ImportFeedback
            key='importFeedback'
            importResult={this.state.importResult}
            importStatus={this.state.importStatus}
            visible={this.state.showImportResult} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )
      const pageHeader = (
         <Row gutter={[4, 4]}>
            <Col span={24}>
               <Space size='large'>
                  <Title level={4}>Settimana {this.state.currentWeek}/{this.state.currentYear}</Title>
                  <Space size='small'>
                     <Text>Cambia settimana:</Text>
                     <DatePicker
                        onChange={this.onWeekChange}
                        style={{ width: '175px' }}
                        picker='week'
                        format='ww-YYYY'
                     />
                  </Space>
               </Space>
            </Col>
         </Row>
      )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <Row gutter={[4, 4]}>
            <Col span={12}>
               <Space>
                  <Button
                     onClick={() => this.selectAllHandler()}
                  >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                  </Button>
                  <span>
                     {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                  </span>
                  <DropdownMenu
                     type='primary'
                     title='Azioni multiple'
                     action={this.multipleActionHandler}
                     values={this.state.multipleActionList}
                     disabled={!hasSelected}
                     loading={this.state.multipleActionLoader}
                  />
                  <DropdownMenu
                     type='primary'
                     title='Esporta'
                     action={this.multipleActionHandler}
                     values={this.state.multipleExportList}
                     disabled={!hasSelected}
                     loading={this.state.multipleExportLoader}
                  />
               </Space>
            </Col>
            <Col span={12} align='right'>
               <Space>
                  <Button icon={<RetweetOutlined />} onClick={this.updateList}>
                     {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                  </Button>
                  {/* <Button
                     type='primary'
                     icon={<PlusOutlined />}
                     onClick={() => this.showImportDrawer(true)}
                  >
                     {languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.import')}
                  </Button> */}
                  {/* <Button
                     type='success'
                     icon={<PlusOutlined />}
                     onClick={() => this.setState({ showImportResult: true, importStatus: 'success', importResult: ['pippo', 'pluto'] })}
                  >TEST</Button> */}
                  {/* <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                           disabled={true}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.new')}
                        </Button> */}
               </Space>
            </Col>
            <Col span={24}>
               {dataTable}
            </Col>
         </Row>
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.unique_files.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  {/* {importFeedback} */}
                  {/* {pageHeader} */}
                  {pageFilters}
                  {pageContent}
               </Space>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(UniqueFilesRiaList);