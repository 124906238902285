import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import { Table, Tooltip, Select, Button, Popconfirm, Space, notification, Collapse, Row, Col, Typography, Image } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined, UploadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { LineChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'

import * as storeActions from './store/actions/products'

import Loader from '../../common/PageElements/Loader'
import TableFilters from '../../common/components/TableFilters'
import DropdownMenu from '../../common/components/DropdownMenu'

class ProductsListMissingParity extends Component {
   state = {
      pageInit: true,
      pageLoader: true,
      tableLoader: false,
      parentId: null,
      itemList: [],
      prodPaginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'export_csv',
            name: 'Esporta selezionati in CSV'
         },
         {
            action: 'export_asin_csv',
            name: 'Esporta ASIN selezionati in CSV'
         },
      ],
      multipleActionLoader: false,
      filters: [],
      filterValues: {},
      appliedFilters: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   getList = (paginationParams, filterValues) => {
      // asyncronous calls
      this.setState({ tableLoader: true })
      return storeActions.getListExtendedMissingParity(paginationParams, filterValues)
         .then(response => {
            console.log(response)
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  prodPaginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      let initialFilters = {}
      if (this.props.match.params.status) {
         switch (this.props.match.params.status) {
            case 'pending':
               initialFilters.status_id = [1]
               break
         }
      }
      Promise.all([
         this.getList(this.state.prodPaginationParams, initialFilters),
      ]).then(() => {
         this.setState({
            filtersValues: initialFilters,
            pageLoader: false
         });
      });
   }
   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevProps.match.params.status, this.props.match.params.status)) {
         this.setState({ pageLoader: true });
         let initialFilters = {}
         if (this.props.match.params.status) {
            switch (this.props.match.params.status) {
               case 'pending':
                  initialFilters.status_id = [1]
                  break
            }
         }
         Promise.all([
            this.getList(this.state.prodPaginationParams, initialFilters),
         ]).then(() => {
            this.setState({
               filtersValues: initialFilters,
               pageLoader: false
            });
         });
      }
   }

   getAnalysis = (itemId) => {
      this.setState({ loader: true });
      storeActions.getProductAnalysis(itemId)
         .then(response => {
            console.log(response);
            try {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   setDefaultLanguageHandler = (value) => {
      this.setState({ componentLanguage: value })
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
      }
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      let newPagination = { ...this.state.prodPaginationParams }
      newPagination.current_page = 1
      this.getList(newPagination, values)
   }
   resetFilters = () => {
      console.log("Reset filters")
      let newPagination = { ...this.state.prodPaginationParams }
      newPagination.current_page = 1
      this.getList(newPagination, [])
   }

   onSelectChange = (selectedRowKeys, selection) => {
      this.setState({ selectedRowKeys: selectedRowKeys })
   }
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'export_csv':
            this.productsExportHandler(this.state.selectedRowKeys);
            break
         case 'export_asin_csv':
            this.productsExportAsinHandler(this.state.selectedRowKeys);
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break

      }
   }

   productsExportHandler = (productIds) => {
      let filename
      this.setState({ multipleActionLoader: true });
      filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS.csv';
      storeActions.exportProductsCSV(filename, productIds)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            console.log(response);
            try {
               this.setState({ loader: false });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ multipleActionLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         })
   }
   productsExportAsinHandler = (productIds) => {
      let filename
      this.setState({ multipleActionLoader: true });
      filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_PRODUCTS_ASIN.csv';
      storeActions.exportProductsAsinCSV(filename, productIds)
         .then(response => {
            this.setState({ multipleActionLoader: false });
            console.log(response);
            try {
               this.setState({ loader: false });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            this.setState({ multipleActionLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography;
      const { Panel } = Collapse;
      const { Option } = Select;
      const table_columns = [
         {
            title: 'Immagine',
            key: 'image',
            width: 91,
            className: 'column-image',
            fixed: 'left',
            render: (record) => (
               !_.isNil(record.gallery[0]) ? <Image src={record.gallery[0].thumb_75} /> :
                  !_.isNil(record.amazon_image_url) ? <Image src={record.amazon_image_url} /> : null
               // record.name[this.state.componentLanguage]
            )
         },
         {
            title: 'Brand',
            key: 'brand',
            width: 120,
            fixed: 'left',
            render: (record) => (
               !_.isNil(record.brand) ? record.brand.name : ''
            ),
            sorter: (a, b) => a.brand.name.localeCompare(b.brand.name),
         },
         {
            title: 'MPN',
            key: 'mpn',
            width: 120,
            fixed: 'left',
            render: (record) => (
               record.mpn
            ),
            sorter: (a, b) => a.mpn.localeCompare(b.mpn),
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 120,
            fixed: 'left',
            render: (record) => (
               record.asin
            ),
            sorter: (a, b) => a.asin.localeCompare(b.asin),
         },
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 120,
            fixed: 'left',
            render: (record) => (
               record.ean_upc
            ),
            sorter: (a, b) => a.ean_upc.localeCompare(b.ean_upc),
         },
         {
            title: 'Titolo',
            key: 'title',
            fixed: 'left',
            render: (record) => (
               <Link to={{ pathname: '/products/edit/' + (record.parent_id ? record.parent_id : record.id) }}>
                  {!_.isNil(record.translation) ? record.translation.title : null}
               </Link>
            ),
            sorter: (a, b) => {
               a = a.translation.title || ''
               b = b.translation.title || ''
               return a.localeCompare(b)
            }
         },
         {
            title: 'Stagione',
            key: 'season',
            width: 120,
            render: (record) => (
               record.season_name
            ),
            sorter: (a, b) => a.season_name.localeCompare(b.season_name),
         },
         {
            title: 'Tipo prodotto', key: 'product_type', width: 100,
            render: (record) => (
               record.parent_id ? 'Variante' : 'Semplice'
            )
         },
         {
            title: 'Vendor code necessari',
            key: 'required_vendor_code',
            width: 100,
            render: (record) => (
               <Space direction='vertical' size='small'>
                  {
                     record.required_vendor_codes.map((vendorCode, index) => {
                        return <Text key={'required_vendor_code' + index}>{vendorCode}</Text>
                     })
                  }
               </Space>
            ),
         },
         {
            title: 'Vendor code attuali',
            key: 'actual_vendor_code',
            width: 100,
            render: (record) => (
               <Space direction='vertical' size='small'>
                  {
                     record.actual_vendor_codes.map((vendorCode, index) => {
                        return <Text key={'actual_vendor_code' + index}>{vendorCode}</Text>
                     })
                  }
               </Space>
            ),
         },
         {
            title: 'Vendor code mancanti',
            key: 'missing_vendor_codes',
            width: 100,
            render: (record) => (
               <Space direction='vertical' size='small'>
                  {
                     record.missing_vendor_codes.map((vendorCode, index) => {
                        return <Text key={'missing_vendor_codes' + index}>{vendorCode}</Text>
                     })
                  }
               </Space>
            ),
         },
         // {
         //    title: 'WHS', key: 'price_whs',
         //    width: 100,
         //    render: (record) => (
         //       record.translations[0].price_whs
         //    ),
         //    sorter: (a, b) => a.translations[0].price_whs - b.translations[0].price_whs,
         // },
         // {
         //    title: 'RETAIL', key: 'price_retail',
         //    width: 100,
         //    render: (record) => (
         //       record.translations[0].price_retail
         //    ),
         //    sorter: (a, b) => a.translations[0].price_retail - b.translations[0].price_retail,
         // },
         // {
         //    title: 'NET AMZ', key: 'price_net_amazon',
         //    width: 100,
         //    render: (record) => (
         //       record.translations[0].price_net_amazon
         //    ),
         //    sorter: (a, b) => a.translations[0].price_net_amazon - b.translations[0].price_net_amazon,
         // },
         // {
         //    title: 'NET RIA', key: 'price_net_amazon_ria',
         //    width: 100,
         //    render: (record) => (
         //       record.translations[0].price_net_amazon_ria
         //    ),
         //    sorter: (a, b) => a.translations[0].price_net_amazon_ria - b.translations[0].price_net_amazon_ria,
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space size='small'>
                  {/* <Button size="small"
                     icon={<LineChartOutlined />}
                     onClick={() => this.getAnalysis(record.id)}
                  /> */}
                  <Link to={{ pathname: '/products/edit/' + (record.parent_id ? record.parent_id : record.id) }}>
                     <Button type="primary" size='small'
                        icon={<EditOutlined />}
                     />
                  </Link>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size="small"
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },
      ];

      const { selectedRowKeys } = this.state;
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         showSorterTooltip: false,
         size: "small",
         loading: this.state.tableLoader,
         onChange: this.handleTableChange,
         pagination: {
            pageSize: this.state.prodPaginationParams.per_page,
            total: this.state.prodPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' prodotti' : ' prodotto')
         },
         rowSelection: {
            selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         },
      }

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} selectValues={this.state.filtersValues} />
            </Panel>
         </Collapse>
      );

      const hasSelected = selectedRowKeys.length > 0
      const singleSelected = selectedRowKeys.length > 1
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.products.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: "/products/import/" }} >
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                           >Importa prodotti da CSV</Button>
                        </Link>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
                        <div className="col-sm-6">
                           <div className="d-none d-md-block">
                              <Space>
                                 <Button
                                    onClick={this.selectAllHandler}
                                 >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                                 </Button>
                                 <span>
                                    {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                                 </span>
                                 <DropdownMenu
                                    type='primary'
                                    title='Azioni multiple'
                                    action={this.multipleActionHandler}
                                    values={this.state.multipleActionList}
                                    disabled={!hasSelected}
                                    loading={this.state.multipleActionLoader}
                                 />
                              </Space>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="float-right d-none d-md-block"></div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <Table {...tableLayout}
                        columns={table_columns}
                        dataSource={this.state.itemList}
                     />
                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(ProductsListMissingParity);