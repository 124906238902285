import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'antd';

import '../../styles/object/splashScreen.css';

import Loader from '../common/PageElements/Loader'


class SplashScreen extends React.Component {
    render() {
        if (this.props.commonState.cfgLoading > 0) {
            return <Loader />
        }
        else
            return this.props.children;
    }
}

SplashScreen.propTypes = {
    commonState: PropTypes.object.isRequired
};


export default SplashScreen;
