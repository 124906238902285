import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class SupplierConfirmationsApi {

   static getModel() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.getModel)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.getList)
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.getListExtended, { ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static cancelItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.cancelItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getTypesList),
         ])
            .then(Axios.spread((suppliersList, typesList) => {
               const response = {
                  suppliersList: suppliersList,
                  typesList: typesList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static importFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.importFromFile, { ...formData })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static exportExcel(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmations.exportExcel, { filename: filename, item_ids: itemIds },
            {
                responseType: 'blob'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default SupplierConfirmationsApi