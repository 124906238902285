import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'

import languageManager from '../../../services/languageManager'
import * as storeActions from '../store/actions/dashboard'
// import { getOrdersStatsWOWLineData } from '../../../shared/faker/getOrdersStatsWOWLineData'

import { notification, Card } from 'antd'
import { InfoCircleOutlined, } from '@ant-design/icons'
import Loader from '../../common/Spinner'

class OrdersStatsWOWLine extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        stats: [],
        weekLabels: [],
        ordersChartsData: {},
    }

    componentDidMount() {
        let weekLabels = []
        for (let week = 1; week <= 53; week++) {
            weekLabels.push('W' + week)
        }
        Promise.all([
            this.getOrdersStatsWOWLine(this.props.vendorCodeType, this.props.vendorCodeGroup),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                weekLabels: weekLabels
            });
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!_.isEqual(prevProps.vendorCodeGroup, this.props.vendorCodeGroup)) {
            this.setState({ componentLoader: true });
            Promise.all([
                this.getOrdersStatsWOWLine(this.props.vendorCodeType, this.props.vendorCodeGroup),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                });
            });
        }
    }

    getOrdersStatsWOWLine = (vendorCodeType, vendorCodeGroup) => {
        this.setState({ componentLoader: true })
        // asyncronous calls
        return storeActions.getOrdersStatsWOWLineData(vendorCodeType, vendorCodeGroup)
            .then(response => {
                console.log(response);
                try {
                    this.setState({
                        componentLoader: false,
                        stats: response.data,
                        // stats: getOrdersStatsWOWLineData,
                    })
                } catch (e) {
                    this.setState({ componentLoader: false })
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }
            }).catch((e) => {
                this.setState({ componentLoader: false })
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    render() {
        if (this.state.componentLoader) return <Loader />
        const lineChartOptions = {
            chart: {
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: 3
            },
            markers: {
                size: 5
            },
            yaxis: {
                min: 0,
                max: 40,
                labels: {
                    show: false
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return (parseFloat(val).toFixed(2)) + '%'
                    }
                }
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
            },
            theme: {
                mode: 'light',
                palette: 'palette4',
            }
        }

        return (
            <Card size='small' bordered={false} style={{ zoom: '111%' }}>
                <ReactApexChart
                    key='wow_line'
                    options={
                        {
                            ...lineChartOptions,
                            title: {
                                text: 'Prodotti accettati WoW (%)',
                            }, xaxis: {
                                categories: this.state.weekLabels,
                            }
                        }}
                    series={
                        this.state.stats.map((stat) => {
                            return ({
                                name: stat.country.name.it,
                                data: stat.stats.accepted_percentage
                            })
                        })
                    }
                    type='line' height={350}
                />
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(OrdersStatsWOWLine)