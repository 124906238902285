import React from 'react';
import { Spin } from 'antd';

/**
 * Renders the preloader
 */
const Loader = () => {
    return (
        <div align='center'>
           <Spin size="large" />
        </div>
    );
};

export default Loader;
