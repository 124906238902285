import TestApi from '../../../../../api/TestApi'
import _ from 'lodash'

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importFile(formData, filename = null) {
   return TestApi.importFile(formData, filename)
      .then(response => {

         if(_.isUndefined(response.data.success)) {
            console.log(response);
            let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename;
            link.click();
         } else {
            if (response.data.success === true) {
               return response.data;
            }
            else
               throw new Error(response.data.error ? response.data.error : 'Server error');
         }

      })
      .catch(error => {
         throw error;
      });
}