import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Row, Col, Space, Form, Input, Switch, Button, notification, Typography, Card } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/marketplaces'

import Loader from '../../common/PageElements/Loader'

class MarketplacesConfig extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      settingsBase: [],
      settingsValues: {},
      language: 'it',

      testButtonLoader: false,
   };

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getConfig(itemId)
            .then(response => {
               console.log(response);
               try {
                  this.setState({
                     settingsBase: response.data.settings_fields,
                     settingsValues: response.data.settings_values,
                     formValues: response.data,
                     pageLoader: false
                  });
               } catch (e) {
                  notification.error({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  })
               }

            }).catch((e) => {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            percentage: 0.00
         }
         this.setState({ formValues: formValues, action: 'insert' });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ pageLoader: true });
      storeActions.setConfig(this.props.match.params.id, values)
         .then(response => {
            console.log(response);
            this.setState({
               settingsBase: response.data.settings_fields,
               settingsValues: response.data.settings_values,
               pageLoader: false
            });
            if (this.state.saveAndClose) {
               this.props.history.push('/marketplaces/list');
            }
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
            })
         }).catch((e) => {
            this.setState({ pageLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };
   onAPIFinish = values => {
      this.setState({ pageLoader: true });
      storeActions.setAPIConfig(this.props.match.params.id, values)
         .then(response => {
            console.log(response);
            this.setState({
               formValues: response.data,
               pageLoader: false
            });
            if (this.state.saveAndClose) {
               this.props.history.push('/marketplaces/list');
            }
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
            })
         }).catch((e) => {
            this.setState({ pageLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.error({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
      })
   };

   handleConnectionTest = (test) => {
      this.setState({ testButtonLoader: true });
      storeActions.connectionTest(this.props.match.params.id, test)
         .then(response => {
            this.setState({ testButtonLoader: false });
            notification.succes({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.testOk'),
            })

         }).catch((e) => {
            this.setState({ testButtonLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography;
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      let ediform = (
         <Form
            {...layout}
            name="marketplace_config"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            initialValues={this.state.settingsValues}
         >

            {
               this.state.settingsBase.map((field) => {
                  // console.log(field.key, this.state.settingsValues[field.key]);
                  return (

                     <Form.Item
                        label={field.name}
                        name={field.key}
                        key={field.key}
                        valuePropName={field.type === "bool" ? "checked" : 'value'}
                     >
                        {
                           field.type === "string" ?
                              <Input />
                              : field.type === "bool" ?
                                 <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                 />
                                 : ''

                        }
                     </Form.Item>)
               })
            }
         </Form>
      )
      let testEDIConnection = (
         <Row>
            <Col span={16} offset={8}>
               <Space>
                  <Button
                     type="primary"
                     loading={this.state.testButtonLoader}
                     onClick={() => this.handleConnectionTest('receive')}>
                     Test Ricezione
                  </Button>
                  <Button
                     type="primary"
                     loading={this.state.testButtonLoader}
                     onClick={() => this.handleConnectionTest('send')}>
                     Test Invio
                  </Button>
               </Space>
            </Col>
         </Row>
      )

      const ediConfig = (
         <Card title="Configurazione EDI" size='small'
            extra={(
               <Space>
                  <Button form="marketplace_config" htmlType="reset">{languageManager.getMessage(this.props.commonState, 'common.reset')}</Button>
                  <Button form="marketplace_config" type="primary" htmlType="submit">{languageManager.getMessage(this.props.commonState, 'common.save')}</Button>
                  <Button form="marketplace_config" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>{languageManager.getMessage(this.props.commonState, 'common.saveAndClose')}</Button>
               </Space>)}
         >
            {ediform}
            {testEDIConnection}
         </Card>
      )

      const apiform = (
         <Form
            {...layout}
            name="marketplace_api_config"
            onFinish={this.onAPIFinish}
            onFinishFailed={this.onFinishFailed}
            initialValues={this.state.formValues}
         >
            <Form.Item
               label='Client ID'
               name='api_client_id'
               key='client_id'
            >
               <Input />
            </Form.Item>
            <Form.Item
               label='Client Secret'
               name='api_client_secret'
               key='client_secret'
            >
               <Input />
            </Form.Item>
            <Form.Item
               label='Refresh Token'
               name='api_refresh_token'
               key='refresh_token'
            >
               <Input />
            </Form.Item>
         </Form>
      )
      const apiConfig = (
         <Card title='Configurazione API' size='small'
            extra={(
               <Space>
                  <Button form="marketplace_api_config" htmlType="reset">{languageManager.getMessage(this.props.commonState, 'common.reset')}</Button>
                  <Button form="marketplace_api_config" type="primary" htmlType="submit">{languageManager.getMessage(this.props.commonState, 'common.save')}</Button>
                  <Button form="marketplace_api_config" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>{languageManager.getMessage(this.props.commonState, 'common.saveAndClose')}</Button>
               </Space>)}
         >
            {apiform}
         </Card>
      )

      const mainContent = (
         <Space direction='vertical' size='middle'>
            {ediConfig}
            {apiConfig}
         </Space>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
            <div className="card-body">
               {mainContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/marketplaces/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                  </Col>
               </Row>
            </div>
         </div>
      );

      // const newButton = {
      //    title: "Crea tassa",
      //    link: '/taxes/taxrates/edit/',
      //    icon: 'new',
      //    style: 'primary'
      // };
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);




      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(MarketplacesConfig);