import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { Table, Select, Button, Space, notification, Tooltip, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, CloseOutlined, EyeOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined, DownloadOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/supplier_confirmations'
import { downloadFromS3 } from '../../../store/actions/commonAction'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import ImportFeedback from '../../common/components/ImportFeedback'
import DrawerEditConfirmation from './components/DrawerEditConfirmation'
import DrawerViewConfirmation from './components/DrawerViewConfirmation'
import DrawerImportConfirmations from './components/DrawerImportConfirmations'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

class SupplierConfirmationsList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      loadersCancel: [],
      loadersDownload: [],
      loadersExport: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      multipleActionLoader: false,
      multipleActionList: [],
      multipleExportLoader: false,
      multipleExportList: [],

      filters: [],
      appliedFilters: [],

      showImportResult: false,
      importResult: null,
      importStatus: null,
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filters)
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  suppliersList: response.suppliersList,
                  typesList: response.typesList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   };

   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, {});
   }

   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ showCreateDrawer: false })
      // this.setState({ createDrawerItem: null, })
   }
   handleCreateOrUpdateConfirmation = (data) => {
      console.log('Conferma creata/aggiornata correttamente')
      this.setState({ showCreateDrawer: false })
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         // this.getComplements()
      ]).then()
   }

   showViewDrawer = item => {
      this.setState({ viewDrawerItem: item, showViewDrawer: true })
   }
   hideViewDrawer = () => {
      this.setState({ showViewDrawer: false })
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }
   onImportComplete = (importResult, importStatus) => {
      console.log('IMPORT COMPLETE', importResult, importStatus)
      this.setState({ showImportDrawer: false, showImportResult: true, importResult: importResult, importStatus: importStatus })
      this.updateList()
   }

   cancelItemsHandler = (itemIds) => {
      this.setState({ loadersCancel: startLoading(this.state.loadersCancel, itemIds) })
      return storeActions.cancelItems(itemIds)
         .then(response => {
            console.log(response);
            this.setState({ loadersCancel: endLoading(this.state.loadersCancel, itemIds) })
            try {
               const newSelectedRowKeys = this.state.selectedRowKeys.filter(x => !itemIds.includes(x))
               this.setState({ selectedRowKeys: newSelectedRowKeys })
               this.getList(this.state.paginationParams, this.state.filtersValues)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.cancelOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersCancel: endLoading(this.state.loadersCancel, itemIds) })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   cancelItemHandler = (itemId) => {
      this.cancelItemsHandler([itemId])
   }

   downloadFileHandler = (itemId, filePath) => {
      this.setState({ loadersDownload: startLoading(this.state.loadersDownload, itemId) })
      return downloadFromS3(filePath, filePath.split('/').pop())
         .then(response => {
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDownload: endLoading(this.state.loadersDownload, itemId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportExcelHandler = (itemIds) => {
      this.setState({ loadersExport: startLoading(this.state.loadersExport, itemIds) })
      const filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_SUPPLIER_CONFIRMATIONS.xlsx';
      return storeActions.exportExcel(filename, itemIds)
         .then(response => {
            this.setState({ loadersExport: endLoading(this.state.loadersExport, itemIds) })
            // try {
            //    this.setState({ loader: false });
            // } catch (e) {
            //    notification.error({
            //       message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            //       description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            //    })
            // }
         }).catch((e) => {
            this.setState({ loadersExport: endLoading(this.state.loadersExport, itemIds) })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         })
   }

   render() {
      if (this.state.pageLoader || _.isNil(this.props.authState.userData.languages)) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const table_columns = [
         {
            key: 'created_at',
            title: 'Data conferma',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.created_at_human
            ),
            sorter: true,
         },
         {
            key: 'filename',
            title: 'Nome file',
            width: 400,
            fixed: 'left',
            render: (record) => (
               <Link to={{ pathname: '/operations/supplier_confirmations/details/' + record.id }} >{record.filename_original}</Link>
            ),
            sorter: true,
         },
         {
            key: 'supplier',
            title: 'Fornitore',
            width: 150,
            render: (record) => (
               record.supplier.name
            ),
            sorter: true,
         },
         {
            key: 'total_confirmed',
            title: 'Pz confermati',
            width: 125,
            render: (record) => (
               record.total_confirmed
            ),
            sorter: true,
         },
         {
            key: 'total_surplus',
            title: 'Eccedenze',
            width: 125,
            render: (record) => (
               record.total_surplus
            ),
            sorter: true,
         },
         {
            key: 'value_confirmed',
            title: 'Valore confermato',
            width: 125,
            render: (record) => (
               record.total_value_human
            ),
            sorter: true,
         },
         {
            key: 'user_id',
            title: 'Creato da',
            width: 150,
            render: (record) => (
               record.user.firstname + ' ' + record.user.lastname
            ),
            sorter: true,
         },
         {
            key: 'actions',
            title: 'Azioni',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link to={{ pathname: '/operations/supplier_confirmations/details/' + record.id }} >
                     <Button type="success" size='small'
                        icon={<EyeOutlined />}
                     />
                  </Link>
                  {/* <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record)}
                  /> */}
                  < Button type="danger" size='small'
                     icon={< CloseOutlined />}
                     loading={this.state.loadersCancel[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.cancel_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askCancelConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.cancelItemHandler(record.id)
                        })
                     }
                  />
                  {
                     !_.isNil(record.filename_nzt) ? (
                        <Button size='small'
                           title='Scarica file originale'
                           icon={<DownloadOutlined />}
                           loading={this.state.loadersDownload[record.id]}
                           onClick={() => this.downloadFileHandler(record.id, record.filename_nzt)}
                        />
                     ) : null
                  }
                  <Button type='success' size='small'
                     title='Scarica dati importati'
                     icon={<DownloadOutlined />}
                     loading={this.state.loadersExport[record.id]}
                     onClick={() => this.exportExcelHandler([record.id])}
                  />
               </Space >),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' conferme' : ' conferma')
         },
         onChange: this.handleTableChange,
         rowClassName: (record) => { return !_.isNil(record.status) && record.status.code === 'CANCELLED' ? 'record-cancelled' : null }
      }

      const { selectedRowKeys } = this.state;
      const rowSelection = {
         selectedRowKeys,
         onChange: this.onSelectChange,
         preserveSelectedRowKeys: true,
      };

      const confirmationsTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowSelection={rowSelection}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         // rowClassName={(record, index) => {
         //    record.order_day==='Monday' % 2 === 0 ? 'table-row-light' :  'table-row-dark'
         // }}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      // const drawerEditProgressive = (
      //    <DrawerEditProgressive
      //       key='createDrawer'
      //       visible={this.state.showCreateDrawer}
      //       item={this.state.createDrawerItem}
      //       complements={{ ...this.state.complements }}
      //       onClose={this.hideCreateDrawer}
      //       onSubmit={(data) => this.handleCreateOrUpdateProgressive(data)} />
      // )
      // const drawerViewProgressive = (
      //    <DrawerViewProgressive
      //       key='viewDrawer'
      //       visible={this.state.showViewDrawer}
      //       item={this.state.viewDrawerItem}
      //       onClose={this.hideViewDrawer} />
      // )
      const drawerImportConfirmations = (
         <DrawerImportConfirmations
            key='importDrawer'
            visible={this.state.showImportDrawer}
            complements={{ ...this.state.complements }}
            onSubmit={(importResult, importStatus) => this.onImportComplete(importResult, importStatus)}
            onClose={() => this.showImportDrawer(false)} />
         // onClose={() => this.onImportComplete(['pippo', 'pluto'], 'warning')} />
      )
      const importFeedback = (
         <ImportFeedback
            key='importFeedback'
            importResult={this.state.importResult}
            importStatus={this.state.importStatus}
            visible={this.state.showImportResult} />
      )

      // const apiFeedback = (
      //       <Alert
      //          closable
      //          message="Dettagli importazione"
      //          type="info"
      //          description={<List
      //             size="small"
      //             dataSource={this.state.response.details}
      //             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
      //          />}
      //          showIcon
      //       />
      // )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <Row gutter={[4, 4]}>
            <Col span={12}>
               <Space>
                  <Button
                     onClick={() => this.selectAllHandler()}
                  >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                  </Button>
                  <span>
                     {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                  </span>
                  <DropdownMenu
                     type='primary'
                     title='Azioni multiple'
                     action={this.multipleActionHandler}
                     values={this.state.multipleActionList}
                     disabled={!hasSelected}
                     loading={this.state.multipleActionLoader}
                  />
                  <DropdownMenu
                     type='primary'
                     title='Esporta'
                     action={this.multipleActionHandler}
                     values={this.state.multipleExportList}
                     disabled={!hasSelected}
                     loading={this.state.multipleExportLoader}
                  />
               </Space>
            </Col>
            <Col span={12} align='right'>
               <Space>
                  <Button icon={<RetweetOutlined />} onClick={this.updateList}>
                     {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                  </Button>
                  <Button
                     type='primary'
                     icon={<PlusOutlined />}
                     onClick={() => this.showImportDrawer(true)}
                  >
                     {languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.import')}
                  </Button>
                  {/* <Button
                     type='success'
                     icon={<PlusOutlined />}
                     onClick={() => this.setState({ showImportResult: true, importStatus: 'success', importResult: ['pippo', 'pluto'] })}
                  >TEST</Button> */}
                  {/* <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                           disabled={true}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.new')}
                        </Button> */}
               </Space>
            </Col>
            <Col span={24}>
               {confirmationsTable}
               {drawerImportConfirmations}
            </Col>
         </Row>
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.supplier_confirmations.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Space direction='vertical' size='middle'>
                  {importFeedback}
                  {pageFilters}
                  {pageContent}
               </Space>
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SupplierConfirmationsList);