import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Button, Space, notification, List } from 'antd'

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/suppliers';

import FormEditSupplier from './FormEditSupplier'
import Loader from '../../../common/PageElements/Loader'

class DrawerEditSupplier extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getPurchaseOrdersListForProgressives()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps.visible, this.props.visible)
      console.log('Component did update', nextProps.item)
      if (_.isEqual(nextProps.visible, this.props.visible)) {
         return false
      }
      return true
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.return_list = false;
      storeActions.createOrUpdate(values)
         .then(response => {
            try {
               this.state.onSubmit(response.data)
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               console.log(e)
            } finally {
               this.setState({ formLoader: false })
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ formLoader: false })
         });
   };

   onClose = () => {
      this.props.onClose()
   }

   render() {
      console.log('Render supplier drawer', this.props)

      const formLayout = {
         layout: 'horizontal',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 }
      };

      return (
         <Drawer
            title={(!_.isNil(this.props.item) && !_.isNil(this.props.item.id) ? languageManager.getMessage(this.props.commonState, 'common.edit') : languageManager.getMessage(this.props.commonState, 'common.add')) + ' ' + languageManager.getMessage(this.props.commonState, 'component.suppliers.name')}
            width={700}
            onClose={this.props.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.props.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="FormEditSupplier" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            {
               this.state.componentLoader ? <Loader /> : (
                  <FormEditSupplier
                     layout={formLayout}
                     submitForm={this.onFinish}
                     values={this.props.item}
                     complements={this.props.complements}
                  />
               )
            }
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(DrawerEditSupplier)