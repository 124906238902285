import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class ProductsApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList(parentId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getList, {
            params: {
               parent_id: parentId
            }
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListExtended(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtended, {...params, filters_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getListExtendedNZTAsin(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtendedNZTAsin, {...params, filters_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getListExtendedNOAsin(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtendedNOAsin, {...params, filters_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getListExtendedMissingParity(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtendedMissingParity, {...params, filters_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getListExtendedNOVendorCodes(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getListExtendedNOVendorCodes, {...params, filters_values: JSON.stringify(filterValues)})
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData, ) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleValue(itemData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.toggleValue, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getProductComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.catalogues.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.specifics.getList),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((categoriesTree, brandsList, cataloguesList, taxRatesList, marketplacesList, specificsList) => {
               const response = {
                  categoriesTree: categoriesTree, 
                  brandsList: brandsList,
                  cataloguesList: cataloguesList,
                  taxRatesList: taxRatesList,
                  marketplacesList: marketplacesList,
                  specificsList: specificsList,
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static uploadFile() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList)
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((categoriesTree, taxRatesList, marketplacesList) => {
               const response = {
                  categoriesTree: categoriesTree,
                  taxRatesList: taxRatesList,
                  marketplacesList: marketplacesList
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importCSVProducts(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.importCSVProducts, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportProductsCSV(filename, productIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportCSV, { filename: filename, product_ids: productIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportProductsExcel(filename, productIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportExcel, { filename: filename, product_ids: productIds },
            {
                responseType: 'blob'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportProductsAsinExcel(filename, productIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportAsinExcel, { filename: filename, product_ids: productIds },
            {
                responseType: 'blob'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static exportProductsAsinCSV(filename, productIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.exportAsinCSV, { filename: filename, product_ids: productIds })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getProductAnalysis(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.getAmazonAnalysis, { product_ids: [itemId] })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static getProductAmazonData(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productsAmazonData.getItem, { id: itemId })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   //CUSTOM PRICE
   static saveCustomPrice(itemId, marketplaceId, variationOperation, variationAmount, basePrice, salePrice) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productPrices.update, {
            product_id: itemId,
            marketplace_id: marketplaceId,
            variation_operator: variationOperation,
            variation_amount: variationAmount,
            base_price: basePrice,
            sell_price: salePrice,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   //VARIATIONS
   static createOrUpdateVariation(itemData, return_list = false) {
      console.log(itemData, return_list)
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productVariations.createOrUpdate, {
            ...itemData,
            return_list: return_list
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               console.log(error);
               reject(error);
            });
      });
   }
   static deleteProductVariation(itemId, return_list = false) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.productVariations.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static saveVariationImage(itemId, imageId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.saveVariationImage, {
            id: itemId,
            image_id: imageId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteVariationImage(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.products.deleteVariationImage, {
            id: itemId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }



}

export default ProductsApi;