import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class SupplierConfirmationLinesApi {

   static getModel() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrderConfirmationLines.getModel)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmationLines.getList)
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(confirmationId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmationLines.getListExtended, {
            supplier_confirmation_id: confirmationId,
            ...params,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.supplierConfirmationLines.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static importFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.purchaseOrderConfirmationLines.importFromFile, { ...formData })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

}

export default SupplierConfirmationLinesApi